import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch} from "antd";
import Spinner from "../../../shared/spinner";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {
  getNumeroExportacion,
  saveExportation,
  updateExportation
} from "../../../Servicios/ExportationServices";
import useCurrentUser from "../../../Hooks/UserHook";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import dayjs from "dayjs";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {useExportationContext} from "../../../contexts/ExportationContext";

const ExportationBookingForm = ({editable, exportation, onSubmit}) => {
  const currentUser = useCurrentUser();
  const [form] = Form.useForm();
  const [switchGeneralCargo, setSwitchGeneralCargo] = useState(false);

  const [loading, setLoading] = useState(false);

  const { loadingExportation, aduanas, carriers } = useExportationContext();

  useEffect(() => {
    if (!currentUser) return;

    setLoading(true);
    getNumberExp()
      .then(() => {
        if (exportation) {
          form.setFieldsValue({
            shipmentName: exportation.shipmentName,
            bookingNum: exportation.bookingNum,
            departureDate: exportation.departureDate
              ? dayjs(exportation.departureDate)
              : null,
            idAduana: exportation.idAduana,
            customerReference: exportation.customerReference,
            billLadingNum: exportation.billLadingNum,
            arrivalDate: exportation.arrivalDate
              ? dayjs(exportation.arrivalDate)
              : null,
            idCarrier: exportation.idCarrier,
            countryName: exportation.countryName,
            countryId: exportation.countryId,
            generalCargo: exportation.generalCargo,
            vesselNameFlag: exportation.vesselNameFlag,
            voyageIdentification: exportation.voyageIdentification,
          });
          setSwitchGeneralCargo(exportation.generalCargo);
        } else {
          form.resetFields();
          getNumberExp().then();
        }
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, exportation]);

  const getNumberExp = async () => {
    if (currentUser && currentUser.idubc !== undefined) {
      const numExportation = await getNumeroExportacion(
        currentUser?.idubc === "1" ? "FLORIDAEXPO" : "EXPORTATION"
      );
      // Validamos de que empresa viene el exp
      form.setFieldsValue({
        quantity: 1,
        shipmentName: exportation
          ? exportation.shipmentName
          : (currentUser?.idubc === "1" ? "EXPFL" : "EXP") + (numExportation + 1),
      });
    }
  };

  const onFinish = async (values) => {
    console.log(values);

    try {
      values.departureDate = values.departureDate
        ? moment(values.departureDate.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;
      values.arrivalDate = values.arrivalDate
        ? moment(values.arrivalDate.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.state = true;
      values.isActive = true;

      values.userId = +currentUser.idusr;
      values.idAduana = +values.idAduana;
      values.countryId = +values.countryId;
      values.idCarrier = +values.idCarrier;
      values.placeExId = +currentUser?.idubc;

      let data;
      setLoading(true);
      if (exportation) {
        const input = {
          ...exportation,
          ...values,
          generalCargo: switchGeneralCargo,
          exportationId: exportation.exportationId
        };

        data = await updateExportation(input)
      } else {
        console.log(values);

        values.generalCargo = switchGeneralCargo;
        values.descriptionGoods = "RELEASE OBL UNRATED AT DESTINATION";

        data = await saveExportation(values);
        successMessage('Exportación creada correctamente');
      }
      setLoading(false);
      if (onSubmit) onSubmit(data);
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  };

  const handleSelectAduana = aduanaId => {
    const aduana = aduanas.find(e => e.id === aduanaId);
    if (aduana) {
      form.setFieldsValue({
        countryId: aduana.countryId,
        countryName: aduana.countryName
      });
    }
  }

  return(
    <>
      <Spinner loading={loading || loadingExportation}>
        <Form layout={"vertical"}
              form={form}
              onFinish={onFinish}
              autoComplete="off">
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Country" name="countryId" hidden>
                <Input placeholder="Country id" />
              </Form.Item>

              <Form.Item label="Shipment Name:" name="shipmentName">
                <Input disabled style={{color: "#001529", textAlign: "center", fontWeight: "bold"}} />
              </Form.Item>
              <Form.Item
                label="Booking Number:"
                name="bookingNum">
                <Input placeholder="Booking Number" disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Departure Date/Time:"
                name="departureDate">
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  disabled={!editable}
                />
              </Form.Item>
              <Form.Item label={'Seleccionar Aduana'}
                         rules={[{required: true, message: "La Aduana es requerido"}]}
                         name={'idAduana'}>
                <Select showSearch
                        disabled={!editable}
                        onChange={handleSelectAduana}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={aduanas.map(e => ({
                          label: `${e.nameAduana} ${e.countryName}`, value: e.id,
                        }))}/>
              </Form.Item>

              <Form.Item label="Vessel Name and Flag:" name="vesselNameFlag">
                <Input disabled={!editable} placeholder="Vessel Name and Flag"/>
              </Form.Item>

             <Form.Item name={'generalCargo'} label={'Marcar como Carga General'}>
               <Switch checked={switchGeneralCargo} onChange={value => setSwitchGeneralCargo(value)}/>
             </Form.Item>

              <ShowWhen show={!exportation}>
                <Form.Item className="w-100" label="Cantidad de Shipments" name="quantity"
                           extra={<div>
                             <p className="mb-0">Generar varios shipments al mismo tiempo</p>
                             <p className="mb-0">Especifique la cantidad para generar 1 o mas shipments con los mismo datos</p>
                           </div>}>
                  <InputNumber className="w-100" placeholder={'Cantidad de Shipments a generar'} />
                </Form.Item>
              </ShowWhen>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Customer Reference No.:"
                name="customerReference"
              >
                <Input disabled={!editable} placeholder="Customer Reference" />
              </Form.Item>

              <Form.Item
                label="Bill of Lading Number:"
                name="billLadingNum">
                <Input disabled={!editable} placeholder="Bill of Lading Number" />
              </Form.Item>

              <Form.Item label="Arrival Date/Time:" name="arrivalDate">
                <DatePicker
                  disabled={!editable}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item label={'Seleccionar Carrier'}
                         rules={[{required: true, message: "El carrier es requerido"}]}
                         name={'idCarrier'}>
                <Select showSearch
                        disabled={!editable}
                        filterOption={(input, option) => {
                          return (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }}
                        options={carriers.map(e => ({label: e.oceanCarrierName, value: e.carrierId}))}/>
              </Form.Item>

              <Form.Item
                label="Voyage Identification:"
                name="voyageIdentification"
              >
                <Input disabled={!editable} placeholder="Voyage Identification"/>
              </Form.Item>

              <Form.Item label="Country:" name="countryName">
                <Input placeholder="Country" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button type="primary" htmlType="submit" block>
                { exportation ? 'Continuar' : 'Generar Shipment' }
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spinner>
    </>
  );
}

export default ExportationBookingForm;
