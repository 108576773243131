import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {viewWR} from '../Servicios/WerehouseService';
import {
  editWerehouse,
} from '../Servicios/WerehouseService';
import Spinner from "../shared/spinner";
import WarehouseForm from "./WarehouseForm";
import {WarehouseProvider} from "../contexts/WarehouseContext";
import {successMessage} from "../utils/toast-message";

const WarehouseEditPage = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [editWR, setEditWR] = useState([]);

  const editarWR = async (values) => {
    try {
      const input = {
        ...editWR,
        ...values,
        subastaId: +values.subastaId,
        importadoraId: +values.importadoraId,
        quantity: +values.quantity,
        schedulebId: +values.schedulebId,
        stateId: +values.stateId,
        typeMerchId: +values.typeMerchId,
        originId: +values.originId,
        licenceTypeId: +values.licenceTypeId,
        expoCodeId: +values.expoCodeId,
        aduanaId: +values.aduanaId,
      }

      setIsLoading(true);
      const data = await editWerehouse(id, input);

      if (data) {
        successMessage("Se actualizo informacion correctamente");
        navigate(`/viewWereHouse/${id}`);
      }

      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  }

  const buscarWerehouse = () => {
    setIsLoading(true);
    viewWR(id).then((whr) => {
      setEditWR(whr);
    }).finally(() => {
      setIsLoading(false)
    });
  };

  useEffect(() => {
    buscarWerehouse();
  }, [id]);

  return (
    <WarehouseProvider>
      <Spinner loading={isLoading}>
        <WarehouseForm hasVehicle={editWR?.hasVehicle}
                       werehouse={editWR}
                       onUpdate={buscarWerehouse}
                       onSubmit={editarWR} />
      </Spinner>
    </WarehouseProvider>
  );
}

export default WarehouseEditPage;
