import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {showVehiclesoli} from "../Servicios/CombosService";
import { ToastContainer, toast } from "react-toastify";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import Card from "react-bootstrap/Card";
import car from "../image/card.png";
import Spinner from "../shared/spinner";
import SpinnerTable from "../Componentes/SpinnerTable";
import DataTable from "react-data-table-component";
import VehiculoForm from "../Componentes/VehiculoForm/VehiculoForm";
import { errorMessage } from "../utils/toast-message";
import {
  Steps,
  theme,
  Alert,
} from "antd";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import SolicitudForm from "./SolicitudForm";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Vehiculo por Lote"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Solicitud = () => {
  const [listaVehi, setListaVehi] = useState([]); // eslint-disable-line no-unused-vars
  const [infovehi, setInfovehi] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const fechaactual = `${day}/${month}/${year}`;

  const loadvehi = async () => {
    setIsLoading(true);
    const data = await showVehiclesoli("NO_SOLICITUD").finally(() => {
      setIsLoading(false);
    });
    setListaVehi(data);
  };

  const insertByTable = (row) => {
    setInfovehi(row);
    next();
  };

  useEffect(() => {
    loadvehi().then();
  }, []);

  const handleVehicleSaved = (vehicle) => {
    setInfovehi(vehicle);
    notifyV();
    nextSaveVehicle();
  };

  const handleVehicleError = (error) => {
    errorMessage(error);
  };

  const notifyV = () =>
    toast.success(
      "Vehiculo registrado correctamente, complete datos de solicitud.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const columns = [
    {
      name: "Seleccionar",
      width: "100px",
      cell: (row) => (
        <Button
          type="button"
          onClick={() => insertByTable(row)}
          variant="primary"
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      ),
    },
    {
      name: "Lote",
      selector: (row) => row.lote,
      width: "120px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row.vehiculoName,
    },
    {
      name: "VIN",
      selector: (row) => row.vin,
      width: "170px",
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = listaVehi.filter((item) => {
    return (
      (item?.lote &&
        item?.lote
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.vehiculoName &&
        item?.vehiculoName
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase()))
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Container>
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col xs={12} md={12}>
            <Form.Label className="input-buscar">Filtrar vehiculo</Form.Label>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        </Row>
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  const steps = [
    {
      title: "Registro de Vehículo",
      content: (
        <Card>
          <Card.Body>
            <VehiculoForm
              onVehicleSaved={handleVehicleSaved}
              onVehicleError={handleVehicleError}
            />
          </Card.Body>
        </Card>
      ),
    },
    {
      title: "Seleccione vehiculo para crear solicitud",
      content: (
        <Card>
          <Card.Body>
            <Container>
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle}
                paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                progressPending={isLoading}
                progressComponent={<SpinnerTable />}
                persistTableHead
                pagination
              />
            </Container>
          </Card.Body>
        </Card>
      ),
    },
    {
      title: "Datos de solicitud para recolección",
      content: (
        <Card>
          <Card.Body>
            <Card.Text>
              <strong>
                <Form.Label>Fecha Ingreso: {fechaactual}</Form.Label>
              </strong>
              <Row className="mt-3">
                <Col xs={12} lg="3">
                  <img // eslint-disable-line jsx-a11y/alt-text
                    src={car}
                    style={{ width: "100px", height: "100px" }}
                  />
                </Col>
                <Col xs={12} lg={9}>
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <p className="fw-bold mb-0"># Lote:</p>
                      <p className="fw-bold mb-0">Vehículo:</p>
                      <p className="fw-bold mb-0"># VIN:</p>
                      <ShowWhen show={infovehi?.title}>
                        <p className="fw-bold mb-0">Tipo de Título:</p>
                      </ShowWhen>
                    </div>
                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-10">
                      <p className="mb-0">{infovehi?.lote}</p>
                      <p className="mb-0">{infovehi?.vehiculoName}</p>
                      <p className="mb-0">{infovehi?.vin}</p>
                      <ShowWhen show={infovehi?.title}>
                        <p className="mb-0">{infovehi?.title?.titleName}</p>
                      </ShowWhen>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Text>
            <SolicitudForm vehiculo={infovehi} />
          </Card.Body>
        </Card>
      ),
    },
  ];

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
    loadvehi().then();
  };

  const nextSaveVehicle = () => {
    setCurrent(current + 2);
  };

  const prev = () => {
    setCurrent(current - 1);
    loadvehi().then();
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    // color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };

  return (
    <Spinner loading={isLoading}>
      <Container>
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current === 0 && (
            <Row className="mt-3">
              <Col className="text-center">
                <Form.Label className="text-aling-left">
                  <Alert
                    message="Nota"
                    description="Si registró anteriormente un vehículo y no completo la
                    solicitud dar click en la siguiente opción:"
                    type="info"
                  />

                  <br />
                  <Button variant="secondary" onClick={() => next()}>
                    Click Aqui
                  </Button>
                </Form.Label>
                <ToastContainer />
              </Col>
            </Row>
          )}

          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Atras
            </Button>
          )}
        </div>
      </Container>
    </Spinner>
  );
};

export default Solicitud;
