import {useEffect, useState} from "react";
import {asignarRolPermiso, getPermissions, getRoleById, removerRolPermiso} from "../../Servicios/AdminService";
import {Switch} from "antd";
import Spinner from "../../shared/spinner";

const RolPermisoForm = ({ rol }) => {
  const [permisos, setPermisos] = useState([]);
  const [permisosPorRol, setPermisosPorRol] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rol) {
      init().then();
    } else {
      setPermisos([]);
      setPermisosPorRol([]);
    }
  }, [rol]);

  const init = async () => {
    if (permisos.length === 0) {
      const data = await getPermissions();
      setPermisos(data);
    }

    const rolPermisos = await getRoleById(rol.id);
    setPermisosPorRol(rolPermisos.permissions);
  }

  const selected = permiso => {
    if (permisosPorRol.length === 0) return false;
    return permisosPorRol.some(e => e.id === permiso.id);
  }

  const handleChange = async permiso => {
    setLoading(true);
    if (selected(permiso)) {
      await removerRolPermiso({ rolId: rol.id, permisoId: permiso.id });
    } else {
      await asignarRolPermiso({ rolId: rol.id, permisoId: permiso.id });
    }
    setLoading(false);
    init().then();
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="container-fluid">
          <div className="row">
            {
              permisos.map(permiso => {
                return (
                  <div key={`rol-permiso-${permiso.id}`} className="col-6 pb-3 mt-3 border-bottom">
                    <div className="d-flex align-items-start justify-content-between gap-1">
                      <p className="m-0 fw-bolder">{permiso.name}</p>
                      <Switch checked={selected(permiso)}
                              onChange={() => handleChange(permiso)}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </Spinner>
    </>
  );
}

export default RolPermisoForm;
