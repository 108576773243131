import {createContext, useContext, useEffect, useState} from "react";
import {showAduana, showCountries, showOrigen} from "../Servicios/CombosService";

const MainContext = createContext();

export const useMainContext = () => useContext(MainContext);

export const MainProvider = ({ children }) => {
  const [errorOrigen, setErrorOrigen] = useState(null);
  const [loadingMain, setLoadingMain] = useState(true);

  const [aduanas, setAduanas] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingMain(true);

        const aduanas = await showAduana();
        const origenes = await showOrigen();
        const countries = await showCountries();

        setLoadingMain(false);

        setAduanas(aduanas);
        setOrigenes(origenes);
        setCountries(countries);
      } catch (error) {
        setErrorOrigen(error.toString());
        setLoadingMain(false);
      }
    }

    fetchData().then();
  }, []);

  return(
    <MainContext.Provider value={{ errorOrigen, loadingMain, aduanas, origenes, countries }}>
      { children }
    </MainContext.Provider>
  );
}
