import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { showCoord, showPendingTitle, pendingTCountry } from '../Servicios/CoordService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPenToSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import Modal from 'react-bootstrap/Modal';
import useCurrentUser from '../Hooks/UserHook';
import { showComments, addComent } from "../Servicios/ComponedoresService";
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment';

const FilterComponent = ({ filterText, onFilter }) => (
   <>
     <Form.Control
        className="input-buscar"
        id="search"
        type="text"
        placeholder="Buscar Coordinacion por Lote"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
     />
   </>
);

const PendinTitles = (props) => {

const [showA, setShowA] = useState(false);

const handleCloseA = () => setShowA(false);
const handleShowA = () => setShowA(true);
        
const [ idCor, setIdCor ] = useState([]);
const ITEMS_PER_PAGE = 5;
const [notes, setNotes] = useState([]);
const [allNotes, setAllNotes] = useState([]);
        
const [currentPage, setCurrentPage] = useState(1);
const [totalElements, setTotalElements] = useState(0);

const [filtroEstado, setFiltroEstado] = useState([""]);

const [ orifl, setOrifl ] = useState([]);
const [ orica, setOrica ] = useState([]);
const [ flsv, setFlsv ] = useState([]);
const [ flgt, setFlgt ] = useState([]);
const [ flcr, setFlcr ] = useState([]);
const [ flni, setFlni ] = useState([]);
const [ flhn, setFlhn ] = useState([]);
const [ casv, setCasv ] = useState([]);
const [ cagt, setCagt ] = useState([]);
const [ cacr, setCacr ] = useState([]);
const [ cani, setCani ] = useState([]);
const [ cahn, setCahn ] = useState([]);
const [ flsvra, setFlsvra ] = useState([]);
const [ flsvtr, setFlsvtr ] = useState([]);
const [ flsvne, setFlsvne ] = useState([]);
const [ flsvsb, setFlsvsb ] = useState([]);
const [ flgtst, setFlgtst ] = useState([]);
const [ flgtce, setFlgtce ] = useState([]);
const [ flgtco, setFlgtco ] = useState([]);
const [ flgtba, setFlgtba ] = useState([]);
const [ flcrec, setFlcrec ] = useState([]);
const [ flcrtd, setFlcrtd ] = useState([]);
const [ flcrcf, setFlcrcf ] = useState([]);
const [ flcreb, setFlcreb ] = useState([]);
const [ casvra, setCasvra ] = useState([]);
const [ casvtr, setCasvtr ] = useState([]);
const [ casvne, setCasvne ] = useState([]);
const [ casvsb, setCasvsb ] = useState([]);
const [ cagtst, setCagtst ] = useState([]);
const [ cagtce, setCagtce ] = useState([]);
const [ cagtco, setCagtco ] = useState([]);
const [ cagtba, setCagtba ] = useState([]);
const [ cacrec, setCacrec ] = useState([]);
const [ cacrtd, setCacrtd ] = useState([]);
const [ cacrcf, setCacrcf ] = useState([]);
const [ cacreb, setCacreb ] = useState([]);


const estado = "Vehiculo pendiente de titulo";
const origenFl = 1;
const origenCa = 2;
const sv = 1;
const gt = 2;
const cr = 3;
const ni = 4;
const hn = 5;
const ransa = 4;
const nejapa = 1;
const snbart = 3
const transa = 2;
const coalsa = 5;
const centralsa = 6;
const psntom = 7;
const coco = 8;
const tecdep = 9;
const ptbar = 17;
const casfall = 19;
const ebba = 20;

const filtrosOrigen = async () => {
    const datafl = await showCoord(origenFl, estado);
    setOrifl(datafl); 
    const dataca = await showCoord(origenCa, estado);
    setOrica(dataca); 
}
const filtrosCountry = async () => {
    const datasv = await pendingTCountry(origenFl, estado, sv);
    setFlsv(datasv); 
    const datagt = await pendingTCountry(origenFl, estado, gt);
    setFlgt(datagt);
    const datacr = await pendingTCountry(origenFl, estado, cr);
    setFlcr(datacr); 
    const datani = await pendingTCountry(origenFl, estado, ni);
    setFlni(datani);
    const datahn = await pendingTCountry(origenFl, estado, hn);
    setFlhn(datahn); 
}
const filtroCoutryCali = async () => {
    const datasvca = await pendingTCountry(origenCa, estado, sv);
    setCasv(datasvca);
    const datagtca = await pendingTCountry(origenCa, estado, gt);
    setCagt(datagtca);
    const datacrca = await pendingTCountry(origenCa, estado, cr);
    setCacr(datacrca);
    const datanica = await pendingTCountry(origenCa, estado, ni);
    setCani(datanica);
    const datahnca = await pendingTCountry(origenCa, estado, hn);
    setCahn(datahnca);
}
const filAduCountryFl = async () => {
    const datara = await showPendingTitle(origenFl, estado, ransa);
    setFlsvra(datara);
    const datatr = await showPendingTitle(origenFl, estado, transa);
    setFlsvtr(datatr);
    const datane = await showPendingTitle(origenFl, estado, nejapa);
    setFlsvne(datane);
    const datasb = await showPendingTitle(origenFl, estado, snbart);
    setFlsvsb(datasb);
    const datast = await showPendingTitle(origenFl, estado, psntom);
    setFlgtst(datast);
    const datace = await showPendingTitle(origenFl, estado, centralsa);
    setFlgtce(datace);
    const dataco = await showPendingTitle(origenFl, estado, coalsa);
    setFlgtco(dataco);
    const databa = await showPendingTitle(origenFl, estado, ptbar);
    setFlgtba(databa);
    const dataec = await showPendingTitle(origenFl, estado, coco);
    setFlcrec(dataec);
    const datatd = await showPendingTitle(origenFl, estado, tecdep);
    setFlcrtd(datatd);
    const datacf = await showPendingTitle(origenFl, estado, casfall);
    setFlcrcf(datacf);
    const dataeb = await showPendingTitle(origenFl, estado, ebba);
    setFlcreb(dataeb); 
}
const filAduCountryCa = async () => {
    const datara = await showPendingTitle(origenCa, estado, ransa);
    setCasvra(datara);
    const datatr = await showPendingTitle(origenCa, estado, transa);
    setCasvtr(datatr);
    const datane = await showPendingTitle(origenCa, estado, nejapa);
    setCasvne(datane);
    const datasb = await showPendingTitle(origenCa, estado, snbart);
    setCasvsb(datasb);
    const datast = await showPendingTitle(origenCa, estado, psntom);
    setCagtst(datast);
    const datace = await showPendingTitle(origenCa, estado, centralsa);
    setCagtce(datace);
    const dataco = await showPendingTitle(origenCa, estado, coalsa);
    setCagtco(dataco);
    const databa = await showPendingTitle(origenCa, estado, ptbar);
    setCagtba(databa);
    const dataec = await showPendingTitle(origenCa, estado, coco);
    setCacrec(dataec);
    const datatd = await showPendingTitle(origenCa, estado, tecdep);
    setCacrtd(datatd);
    const datacf = await showPendingTitle(origenCa, estado, casfall);
    setCacrcf(datacf);
    const dataeb = await showPendingTitle(origenCa, estado, ebba);
    setCacreb(dataeb); 
}

var today = new Date();
var day = today.getDate();
var month = today.getMonth() + 1;
var year = today.getFullYear();

const fechaactual = (`${day}/${month}/${year}`);

const tiempoTranscurrido = Date.now();
const hoy = new Date(tiempoTranscurrido);
const date = hoy.toISOString();

const currentUser = useCurrentUser();
                             
    const [coment, setComent] = useState({
    id:"",
    fecha: date,
    nota:"",
    autor: "",
    coordId:"",
    });

    useEffect ( () => {
        mosTransp();
            }, [idCor]) 
        
        useEffect(() => {
            if (currentUser)
            setComent({...coment, autor: currentUser?.fullName }) 
          }, [currentUser]);
    
    const mosTransp = (idCor) => {
        if (idCor != undefined && idCor != 0)
        showComments(idCor).then((comentarios) => {
            setNotes(comentarios.slice(0, ITEMS_PER_PAGE));
            setAllNotes(comentarios);
            setTotalElements(comentarios.length);
            setComent({...coment, coordId: idCor})
            cerrarModal();
            handleShowA();
        }) 
    }

useEffect(() => {
    mosTransp();
    filtrosOrigen(); 
    filtrosCountry();
    filtroCoutryCali();
    filAduCountryFl();
    filAduCountryCa();
}, [idCor]);

const handleChange=e=>{
  const {name, value}=e.target;
     setComent({
      ...coment,
      [name]:value
  });
}

const insertarComent = async () => {
        try {
            const data = await addComent({
                ...coment,                
            });
            if (data) {  
                mosTransp(); 
                setComent({
                  ...coment,
                  nota:"",
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    const cerrarModal = () => {
        handleCloseA();
    }

      const nextHandler = () => {
        const nextPage = currentPage + 1;
        const firstIndex = currentPage * ITEMS_PER_PAGE;
        
        if (firstIndex === totalElements) return;
      
        setNotes([...allNotes.slice(firstIndex, firstIndex + ITEMS_PER_PAGE)])
        setCurrentPage(nextPage);
      }
      
      const prevHandler = () => {
        const prevPage = currentPage -1; // 2
        if (prevPage < 1) return;
      
        const firstIndex = prevPage * ITEMS_PER_PAGE;
      
        setNotes([...allNotes.slice(firstIndex - ITEMS_PER_PAGE, firstIndex)])
        setCurrentPage(prevPage);
      }


const mostfl = () => {
    setFiltroEstado(orifl);
}
const mostca = () => {
    setFiltroEstado(orica);
}
const mostflsv = () => {
    setFiltroEstado(flsv);
}
const mostflgt = () => {
    setFiltroEstado(flgt);
}
const mostflcr = () => {
    setFiltroEstado(flcr);
}
const mostflni = () => {
    setFiltroEstado(flni);
}
const mostflhn = () => {
    setFiltroEstado(flhn);
}
const mostflsvra = () => {
    setFiltroEstado(flsvra);
}
const mostflsvtr = () => {
    setFiltroEstado(flsvtr);
}
const mostflsvne = () => {
    setFiltroEstado(flsvne);
}
const mostflsvsb = () => {
    setFiltroEstado(flsvsb);
}
const mostflgtst = () => {
    setFiltroEstado(flgtst);
}
const mostflgtce = () => {
    setFiltroEstado(flgtce);
}
const mostflgtco = () => {
    setFiltroEstado(flgtco);
}
const mostflgtba = () => {
    setFiltroEstado(flgtba);
}
const mostflcrec = () => {
      setFiltroEstado(flcrec);
}
const mostflcrtd = () => {
      setFiltroEstado(flcrtd);
}
const mostflcrcf = () => {
      setFiltroEstado(flcrcf);
}
const mostflcreb = () => {
      setFiltroEstado(flcreb);
}

const mostcasv = () => {
        setFiltroEstado(casv);
    }
    const mostcagt = () => {
        setFiltroEstado(cagt);
    }
    const mostcacr = () => {
        setFiltroEstado(cacr);
    }
    const mostcani = () => {
        setFiltroEstado(cani);
    }
    const mostcahn = () => {
        setFiltroEstado(cahn);
    }
    const mostcasvra = () => {
        setFiltroEstado(casvra);
    }
    const mostcasvtr = () => {
        setFiltroEstado(casvtr);
    }
    const mostcasvne = () => {
        setFiltroEstado(casvne);
    }
    const mostcasvsb = () => {
        setFiltroEstado(casvsb);
    }
    const mostcagtst = () => {
        setFiltroEstado(cagtst);
    }
    const mostcagtce = () => {
        setFiltroEstado(cagtce);
    }
    const mostcagtco = () => {
        setFiltroEstado(cagtco);
    }
    const mostcagtba = () => {
        setFiltroEstado(cagtba);
    }
    const mostcacrec = () => {
          setFiltroEstado(cacrec);
    }
    const mostcacrtd = () => {
          setFiltroEstado(cacrtd);
    }
    const mostcacrcf = () => {
          setFiltroEstado(cacrcf);
    }
    const mostcacreb = () => {
          setFiltroEstado(cacreb);
    }



      const columns =[
        {
            name: 'Editar',
            width: "70px",
            cell: (row)=><Button  as={Link} to={`/editCordinations/${row?.id}`} variant="primary">
            <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
        },
        {
            name: 'Fecha',
            selector: row => row?.fechacor,
            format: (row) => moment(row?.fechacor).format('l'),
            width: "115px",
        },
        {
            name: 'Lote',
            selector: row => row?.quote.lote,
            width: "100px",
        },
        {
            name: 'Vehiculo',
            selector: row => row?.quote.vehiculoName,
            width: "170px",
        },
        {
            name: 'Ubic./Subasta',
            selector: row => row?.quote.subName,
            width: "200px",
        },
        {
            name: 'Buyer',
            selector: row => row?.numBuy,
            width: "90px",
        },
        {
            name: 'Cliente',
            selector: row => row?.quote.importadoraName,
            width: "250px",
        },
        {
            name: 'Comentarios',
            width: "105px",
            cell: (row)=><Button  onClick={() => mosTransp(row?.id)} variant="warning">
            <FontAwesomeIcon icon={faComment} />
        </Button>
        },
    ]
    
    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = filtroEstado.filter(
                    item => {
            
            return (item?.lote && item?.lote.toString().toLowerCase().includes(filterText.toLowerCase())) ||
                   (item?.fechacor && item?.fechacor.toString().toLowerCase().includes(filterText.toLowerCase()));
        });
    
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (

        
                <Container>
                    <Row>
                        <Col xs={12} md={5}>
                            <Form.Label className='input-buscar'>Seleccione Coordinacion para "Ver" su información o "Editar"</Form.Label>
                            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                        </Col>
                    </Row>
                    <br/>
                </Container>
                  
            
            );
        }, [filterText, resetPaginationToggle]);

const fl = orifl?.length;
const ca = orica?.length;
const flsal = flsv?.length;
const flgtl = flgt?.length;
const flcrc = flcr?.length;
const flnic = flni?.length;
const flhnd = flhn?.length;
const calisv = casv?.length;
const caligt = cagt?.length;
const calicr = cacr?.length;
const calini = cani?.length;
const calihn = cahn?.length;
const flra = flsvra?.length;
const fltr = flsvtr?.length;
const flne = flsvne?.length;
const flsb = flsvsb?.length;
const flst = flgtst?.length;
const flce = flgtce?.length;
const flco = flgtco?.length;
const flba = flgtba?.length;
const flec = flcrec?.length;
const fltd = flcrtd?.length;
const flcf = flcrcf?.length;
const fleb = flcreb?.length;
const cara = casvra?.length;
const catr = casvtr?.length;
const cane = casvne?.length;
const casb = casvsb?.length;
const cast = cagtst?.length;
const cace = cagtce?.length;
const caco = cagtco?.length;
const caba = cagtba?.length;
const caec = cacrec?.length;
const catd = cacrtd?.length;
const cacf = cacrcf?.length;
const caeb = cacreb?.length;

  return (
    <Container className='pendingtitles'>
      <h2>Titulos pendientes</h2>
      <hr/>
      <h2 onClick={mostfl}>FLORIDA - {fl}</h2>
      <br/>
      <Row style={{justifyContent: 'space-evenly'}}>
            <Col xs={12} md={2}>
                    <h5 onClick={mostflsv} style={{textAlign: 'center'}} >EL SALVADOR</h5>
                    <h1 onClick={mostflsv} style={{textAlign: 'center'}}>{flsal}</h1>
                    <h5 onClick={mostflsvra} style={{textAlign: 'lefth'}}>RANSA: {flra}</h5>
                    <h5 onClick={mostflsvtr} style={{textAlign: 'lefth'}}>TRANSAUTO: {fltr}</h5>
                    <h5 onClick={mostflsvne} style={{textAlign: 'lefth'}}>NEJAPA: {flne}</h5>
                    <h5 onClick={mostflsvsb} style={{textAlign: 'lefth'}}>SAN BARTOLO: {flsb}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostflgt} style={{textAlign: 'center'}}>GUATEMALA</h5>
                    <h1 onClick={mostflgt} style={{textAlign: 'center'}}>{flgtl}</h1>
                    <h5 onClick={mostflgtst} style={{textAlign: 'lefth'}}>SANTO TOMAS: {flst}</h5>
                    <h5 onClick={mostflgtce} style={{textAlign: 'lefth'}}>CENTRALSA: {flce}</h5>
                    <h5 onClick={mostflgtco} style={{textAlign: 'lefth'}}>COALSA: {flco}</h5>
                    <h5 onClick={mostflgtba} style={{textAlign: 'lefth'}}>BARRIOS: {flba}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostflcr} style={{textAlign: 'center'}} >COSTA RICA</h5>
                    <h1 onClick={mostflcr} style={{textAlign: 'center'}}>{flcrc}</h1>
                    <h5 onClick={mostflcrec} style={{textAlign: 'lefth'}}>EL COCO: {flec}</h5>
                    <h5 onClick={mostflcrtd} style={{textAlign: 'lefth'}}>TECNODEPOSITO: {fltd}</h5>
                    <h5 onClick={mostflcrcf} style={{textAlign: 'lefth'}}>CASTO FALLAS: {flcf}</h5>
                    <h5 onClick={mostflcreb} style={{textAlign: 'lefth'}}>EBBA: {fleb}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostflni} style={{textAlign: 'center'}}>NICARAGUA</h5>
                    <h1 onClick={mostflni} style={{textAlign: 'center'}}>{flnic}</h1>
                    <h5 style={{textAlign: 'lefth'}}>MANAGUA: X</h5>
            </Col>
            <Col xs={12} md={2}>    
                    <h5 onClick={mostflhn} style={{textAlign: 'center'}}>HONDURAS</h5>
                    <h1 onClick={mostflhn} style={{textAlign: 'center'}}>{flhnd}</h1>
                    <h5 style={{textAlign: 'lefth'}}>PUERTO CORTES: X</h5>
            </Col>
      </Row>
      <hr/>
      <h2 onClick={mostca} >CALIFORNIA - {ca}</h2>
      <br/>
      <Row style={{justifyContent: 'space-evenly'}}>
            <Col xs={12} md={2}>
                    <h5 onClick={mostcasv} style={{textAlign: 'center'}} >EL SALVADOR</h5>
                    <h1 onClick={mostcasv} style={{textAlign: 'center'}}>{calisv}</h1>
                    <h5 onClick={mostcasvra} style={{textAlign: 'lefth'}}>RANSA: {cara}</h5>
                    <h5 onClick={mostcasvtr} style={{textAlign: 'lefth'}}>TRANSAUTO: {catr}</h5>
                    <h5 onClick={mostcasvne} style={{textAlign: 'lefth'}}>NEJAPA: {cane}</h5>
                    <h5 onClick={mostcasvsb} style={{textAlign: 'lefth'}}>SAN BARTOLO: {casb}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostcagt} style={{textAlign: 'center'}}>GUATEMALA</h5>
                    <h1 onClick={mostcagt} style={{textAlign: 'center'}}>{caligt}</h1> 
                    <h5 onClick={mostcagtst} style={{textAlign: 'lefth'}}>SANTO TOMAS: {cast}</h5>
                    <h5 onClick={mostcagtce} style={{textAlign: 'lefth'}}>CENTRALSA: {cace}</h5>
                    <h5 onClick={mostcagtco} style={{textAlign: 'lefth'}}>COALSA: {caco}</h5>
                    <h5 onClick={mostcagtba} style={{textAlign: 'lefth'}}>BARRIOS: {caba}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostcacr} style={{textAlign: 'center'}} >COSTA RICA</h5>
                    <h1 onClick={mostcacr} style={{textAlign: 'center'}}>{calicr}</h1>
                    <h5 onClick={mostcacrec} style={{textAlign: 'lefth'}}>EL COCO: {caec}</h5>
                    <h5 onClick={mostcacrtd} style={{textAlign: 'lefth'}}>TECNODEPOSITO: {catd}</h5>
                    <h5 onClick={mostcacrcf} style={{textAlign: 'lefth'}}>CASTO FALLAS: {cacf}</h5>
                    <h5 onClick={mostcacreb} style={{textAlign: 'lefth'}}>EBBA: {caeb}</h5>
            </Col>
            <Col xs={12} md={2}>
                    <h5 onClick={mostcani} style={{textAlign: 'center'}}>NICARAGUA</h5>
                    <h1 onClick={mostcani} style={{textAlign: 'center'}}>{calini}</h1>
                    <h5 style={{textAlign: 'lefth'}}>MANAGUA: X</h5>
            </Col>
            <Col xs={12} md={2}>    
                    <h5 onClick={mostcahn} style={{textAlign: 'center'}}>HONDURAS</h5>
                    <h1 onClick={mostcahn} style={{textAlign: 'center'}}>{calihn}</h1>
                    <h5 style={{textAlign: 'lefth'}}>PUERTO CORTES: X</h5>
            </Col>
      </Row>
      <hr/>

      <Row className="justify-content-md-center">
        <Col className='tablepending' xs={12} >
        <br/>
        <DataTable  
        columns={columns}
        data={filteredItems}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
		persistTableHead
        pagination
        />
        </Col>
    </Row>
    <>
      <Modal
        show={showA}
        onHide={handleCloseA}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                &nbsp;&nbsp;&nbsp; <Form.Control readOnly disabled style={{width: '200px'}} value={fechaactual} name='fecha' type="text" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                </Row>
                <br/>
                <Row>
                  <FloatingLabel controlId="floatingTextarea2" label="Escriba su comentario aqui">
                    <Form.Control
                      name='nota'
                      value={coment?.nota}
                      onChange={handleChange}
                      maxLength={1000}
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: '150px' }}
                    />
                  </FloatingLabel>
                  </Row>
                  <br/>
                  <Row className='conte-padre'>
                  <Button style={{width: '200px'}} onClick={()=>insertarComent()} variant="primary">
                      <FontAwesomeIcon icon={faPaperPlane} />
                      &nbsp;Guardar Comentario  
                      </Button>
                      {' '}
                  </Row>
                  <br/>
             <Row>               
                {notes.map((not) => ( 
                <Row className='conte-padre'>
                    <Alert variant="secondary">
                    <p>{not.fecha} by {not.autor}</p>
                    <hr />
                    <p className="mb-0">
                    {not.nota}
                    </p>
                    </Alert>
                </Row>
                ))}
                  <Pagination>
                    <h5>Pagina: {currentPage}/{Math.ceil(totalElements / ITEMS_PER_PAGE)}</h5> &nbsp;&nbsp;
                    <Pagination.Prev onClick={prevHandler}/>
                    <Pagination.Next onClick={nextHandler}/>
                  </Pagination>
              </Row>   
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => cerrarModal()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
        </>

    </Container>
  )
}

export default PendinTitles;
