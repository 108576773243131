import { server } from "../environments/environment";
import {ApiService} from "./ApiService";

export const addImport = async (client) => {
    const URL = `${server}Importadora`
    const response = await ApiService.post(URL, client);
    return response.json();
}

export const addAduana = async (client) => {
    const URL = `${server}Aduana`
    try {
        const response = await ApiService.post(URL, client);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const addSeg = async (client) => {
    const URL = `${server}Segmento`
    try {
        const response = await ApiService.post(URL, client);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const showAduanas = async () => {
    const URL = `${server}Aduana`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const showImports = async () => {
    const URL = `${server}Importadora`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const getSegmentos = async () => {
    const URL = `${server}Segmento`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const viewCountries = async (id) => {
    try {
        const URL = `${server}Country`
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const client = data.data;
 
        return client;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const showNotas = async (clientesId = 0) => {
    const URL = `${server}Notas`
    const response = await ApiService.get(`${URL}?clientesId=${clientesId}`);
    
    const data = await response.json()
    const list = data.list;

    list.forEach(element => {
        if (element.fecha && element.fecha.indexOf("T00:00:00") !== -1) {
            element.fecha = element.fecha.substring(0, element.fecha.indexOf("T00:00:00"));
        }
    });

    return list;
}

export const showComments = async (coordiId = 0) => {
    const response = await ApiService.get(`${server}ComentCor?coordId=${coordiId}`);
    
    const data = await response.json()
    const list = data.list;

    list.forEach(element => {
        if (element.fecha && element.fecha.indexOf("T00:00:00") !== -1) {
            const fechaObj = new Date(element.fecha);

            // Obtener los componentes de la fecha
            const mes = fechaObj.getMonth() + 1; // Nota: Los meses en JavaScript son 0-indexados
            const dia = fechaObj.getDate();
            const year = fechaObj.getFullYear();

            // Formatear la fecha como "mm/dd/yyyy"
            const fechaFormateada = `${dia}/${mes}/${year}`;

            // Asignar la fecha formateada de vuelta al objeto
            element.fecha = fechaFormateada;
        }
    });

    return list;
}

export const addNotes = async (notas) => {
    const URL = `${server}Notas`
    try {
        const response = await ApiService.post(URL, notas);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const addComent = async (com) => {
    const URL = `${server}ComentCor`
    try {
        const response = await ApiService.post(URL, com);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editComent = async (id, com) => {
    const URL = `${server}ComentCor/${id}`
    try {
        const response = await ApiService.put(URL, com);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const viewOrigen = async (id) => {
    try {
        const URL = `${server}Origen`
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const client = data.data;
 
        return client;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const viewTransp = async (id) => {
    try {
        const URL = `${server}Transporte`
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()

        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const showTransport = async () => {
    const URL = `${server}Transporte`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const addTransporte = async (trans) => {
    const URL = `${server}Transporte`
    try {
        const response = await ApiService.post(URL, trans);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editTransp = async (id, tras) => {
    const URL = `${server}Transporte`
    try {
        const response = await ApiService.put(URL+"/"+id, tras);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editImport = async (id, impo) => {
    const URL = `${server}Importadora`
    const response = await ApiService.put(URL+"/"+id, impo);
    return response.json();
}

export const viewImport = async (id) => {
    const URL = `${server}Importadora`
    try {
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const trans = data.data;
 
        return trans;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editAduana = async (id, adu) => {
    const URL = `${server}Aduana`
    try {
        const response = await ApiService.put(URL+"/"+id, adu);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const viewAduana = async (id) => {
    const URL = `${server}Aduana`
    try {
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const adua = data.data;
 
        return adua;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editSeg = async (id, seg) => {
    const URL = `${server}Segmento`
    try {
        const response = await ApiService.put(URL+"/"+id, seg);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const viewSeg = async (id) => {
    const URL = `${server}Segmento`
    try {
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const seg = data.data;
 
        return seg;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editSub = async (id, sub) => {
    const URL = `${server}Subasta`
    const response = await ApiService.put(URL+"/"+id, sub);
    const data = await response.json();

    if (data.status) {
        return data.data;
    }

    throw new Error(data.message);
}

export const viewSub = async (id) => {
    const URL = `${server}Subasta`
    try {
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const sub = data.data;
 
        return sub;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const showSubastas = async () => {
    const URL = `${server}Subasta`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const addSubasta = async (subs) => {
    const URL = `${server}Subasta`
    const response = await ApiService.post(URL, subs);
    const data = await response.json();

    if (data.status) {
        return data.data;
    }

    throw new Error(data.message);
}
