import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getTransportistas = async () => {
  const response = await ApiService.get(`${server}transportista`);
  const data = await response.json();

  if (data && data.status) {
    return data.list;
  }

  throw new Error(data.message);
}
