export const StringFormats = {
  currency: (value) => {
    return new Intl
      .NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .format((value ? +value : 0))
  },
  dateDB: (date: Date) => { // return in format yyyy-MM-ddTHH:mm:ss
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    if (month.length === 1) month = `0${month}`;
    if (day.length === 1) day = `0${day}`;

    return `${year}-${month}-${day}T00:00:00`;
  },
  dateTimeDB: (date: Date) => { // return in format yyyy-MM-ddTHH:mm:ss
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();
    let second = date.getSeconds().toString();

    if (month.length === 1) month = `0${month}`;
    if (day.length === 1) day = `0${day}`;

    if (hour.length === 1) hour = `0${hour}`;
    if (minute.length === 1) minute = `0${minute}`;
    if (second.length === 1) second = `0${second}`;

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  },
  date: (date: Date) => { // return in format MM/dd/yyyy
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    if (month.length === 1) month = `0${month}`;
    if (day.length === 1) day = `0${day}`;

    return `${month}/${day}/${year}`;
  },
  dateTime: (date: Date) => { // return in format MM/dd/yyyy HH:mm:ss
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();
    let second = date.getSeconds().toString();

    if (month.length === 1) month = `0${month}`;
    if (day.length === 1) day = `0${day}`;

    if (hour.length === 1) hour = `0${hour}`;
    if (minute.length === 1) minute = `0${minute}`;
    if (second.length === 1) second = `0${second}`;

    return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
  },
  strDate: (date: string) => { // return in format MM/dd/yyyy

    if (!date) return null;
    const [onlyDate] = date.split('T');

    const [year, month, day] = onlyDate.split('-');
    return `${month}/${day}/${year}`;
  }
}
