import {getVehicleInStock} from "../Servicios/VehicleService";
import React, {useEffect, useState} from "react";
import Spinner from "../shared/spinner";
import {Col, Row, Select, Table, Tag} from "antd";
import localStorageFilter from "../shared/utils/local-storage-filter";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import useCurrentUser from "../Hooks/UserHook";
import {hasRol} from "../Servicios/AuthService";
import roles from "../utils/roles";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import moment from "moment";
import {useMainContext} from "../contexts/MainContext";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrigenTab from "../Componentes/OrigenTab/OrigenTab";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
  origenId: number;
  aduanaId: number;
  countryId: number;
}

const Inventario = () => {
  const currentUser = useCurrentUser();
  const showTab = hasRol(roles.ADMIN, roles.SUPERVISOR);

  const [loading, setLoading] = useState(false);

  const [vehiculos, setVehiculos] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const { aduanas, countries, loadingMain } = useMainContext();

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('inventario:filter', 'pageCount') ?? 100,
    currentPage: localStorageFilter('inventario:filter', 'currentPage') ?? 1,
    search: localStorageFilter('inventario:filter', 'search') ?? "",
    origenId: localStorageFilter('inventario:filter', 'origenId') ?? currentUser?.idubc,
    aduanaId: localStorageFilter('inventario:filter', 'aduanaId') ?? 0,
    countryId: localStorageFilter('inventario:filter', 'countryId') ?? 0,
  });

  useEffect(() => {
    if (currentUser) {
      if (filters.origenId) {
        init(filters).then();
      } else {
        setFilters({
          ...filters,
          origenId: +currentUser.idubc
        });
      }
    }

    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('inventario:filter', JSON.stringify(filters));
    }
  }, [filters, currentUser]);

  const init = async (filters: Filters) => {
    setLoading(true);
    const data = await getVehicleInStock(filters.currentPage, filters.pageCount, filters.search, filters.origenId, filters.aduanaId, filters.countryId);
    setLoading(false);

    setVehiculos(data.list);
    setTotalElements(data.totalElements);
  }

  const columns = [
    {
      key: 'fecha',
      dataIndex: 'fecha',
      title: 'Recibido',
      render: (value) => value ? moment(value).format('MMM DD, yyyy').toUpperCase() : '--//--'
    },
    {
      key: 'lote',
      dataIndex: 'lote',
      title: 'Lote'
    },
    {
      key: 'vin',
      dataIndex: 'vin',
      title: 'VIN'
    },
    {
      key: 'vehiculoName',
      dataIndex: 'vehiculoName',
      title: 'Descripción'
    },
    {
      key: 'color',
      dataIndex: 'color',
      title: 'Color'
    },
    {
      key: 'country',
      dataIndex: 'country',
      title: 'País'
    },
    {
      key: 'aduana',
      dataIndex: 'aduana',
      title: 'Destino'
    },
    {
      key: 'titulo',
      dataIndex: 'titulo',
      title: 'Título',
      render: (value) => value
        ? <Tag color={'success'}>SI</Tag>
        : <Tag color={'error'}>NO</Tag>
    }
  ];

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  }

  const handleChangeSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    })
  }

  const handleChangeTab = (tab) => {
    setFilters({
      ...filters,
      origenId: tab,
      currentPage: 1,
    });
  }

  const handleChangeFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  return(
    <Spinner loading={loading || loadingMain}>
      <h5 className="mb-0">Inventario de Vehículos</h5>
      <hr/>
      <div className="mb-3">
        <Row gutter={16}>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por País</p>
              <ShowWhen show={filters.countryId !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'countryId')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <Select className="w-100"
                    value={filters?.countryId === 0 ? '' : filters?.countryId}
                    placeholder={"Filtrar por País"}
                    onChange={value => handleChangeFilter(value, 'countryId')}
                    options={countries.map(e => {
                      return { value: e.id, label: e.nameCountry }
                    })} />
          </Col>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por Destino</p>
              <ShowWhen show={filters.aduanaId !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'aduanaId')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <Select className="w-100"
                    value={filters?.aduanaId === 0 ? '' : filters?.aduanaId}
                    placeholder={"Filtrar por Destino"}
                    onChange={value => handleChangeFilter(value, 'aduanaId')}
                    options={aduanas
                      .filter(e => !e.isProvisional)
                      .filter(e => filters?.countryId === 0 || e.countryId === filters?.countryId)
                      .map(e => {
                        return {value: e.id, label: e.nameAduana}
                      })}/>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <ShowWhen show={showTab}>
          <OrigenTab selectedOrigen={filters.origenId} onChange={handleChangeTab} />
        </ShowWhen>
        <InputSearch placeholder={'buscar por vin o por lote'} doChange={handleChangeSearch} />
      </div>
      <Table size={'small'}
             columns={columns}
             dataSource={vehiculos}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: filters.currentPage,
               pageSize: filters.pageCount,
               pageSizeOptions: [20, 50, 100, 150, 200, 500],
               onChange: handlePageChange
             }} />
    </Spinner>
  );
}

export default Inventario;
