import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getTitles = async () => {
  const response = await ApiService.get(`${server}title`);
  const { list } = await response.json();
  return list;
}

export const addTitle = async (params) => {
  const response = await ApiService.post(`${server}title`, params);
  const { data } = await response.json();

  return data;
}

export const updateTitle = async (id, params) => {
  const response = await ApiService.put(`${server}title/${id}`, params);
  const { data } = await response.json();

  return data;
}

export const deleteTitle = async (id) => {
  const response = await ApiService.delete(`${server}title/${id}`);
  const { data } = await response.json();

  return data;
}
