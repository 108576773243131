import { createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk';
import { persistStore, persistReducer} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import rootReducer from "./store";
// persist configuration object
const persistConfig = {
    key: 'root',
    storage
  };

 // create a persisted reducer using the rootReducer and persistConfig
const persistedReducer = persistReducer(persistConfig, rootReducer);

// create the Redux store
const store = createStore(persistedReducer, compose(applyMiddleware(thunk),
    typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
));
// create a persistor object to persist the store
const persistor = persistStore(store);

export { store, persistor };