import { Button, Divider, Form, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { faFloppyDisk, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {addCar, editCar, listColor, listMake} from "../../Servicios/VehicleService";
import { getTitles } from "../../Servicios/TitleService";
import Spinner from "../../shared/spinner";
import { errorMessage } from "../../utils/toast-message";
import {VehiculoEstadoEnum} from "../../shared/enums/vehiculo-estado.enum";
import {VehiculoEstadoInspeccionEnum} from "../../shared/enums/vehiculo-estado-inspeccion.enum";

const VehiculoForm = ({ vehicle, onVehicleSaved, onVehicleError }) => {
  const [form] = Form.useForm();

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [colors, setColors] = useState([]);
  const [titles, setTitles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [canSaveVehicle, setCanSaveVehicle] = useState(true);

  const [inputMake, setInputMake] = useState("");
  const [inputModel, setInputModel] = useState("");
  const [inputColor, setInputColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (form && vehicle) {
      form.setFieldsValue({
        vin: vehicle.vin,
        make: vehicle.make,
        model: vehicle.model,
        color: vehicle.color,
        year: vehicle.year,
        lote: vehicle.lote,
        idTitle: vehicle.idTitle,
      });
    }
  }, [form, vehicle]);

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    const makes = await listMake();
    const colors = await listColor();
    const titles = await getTitles();

    setMakes(makes);
    setColors(colors);
    setTitles(titles);
  };

  const onChange = (value, key) => {
    form.setFieldValue(key, value);

    if (key === "make") {
      setModels(makes.find((e) => e.make === value).models);
    }
  };

  const onSearch = (value, key) => {
    form.setFieldValue(key, value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onInputMakeChange = (event) => {
    setInputMake(event.target.value);
  };

  const onInputModelChange = (event) => {
    setInputModel(event.target.value);
  };
  const onInputColorChange = (event) => {
    setInputColor(event.target.value);
  };

  const addNewMake = (e) => {
    e.preventDefault();

    if (inputMake !== "" && !makes.some((e) => e.make === inputMake)) {
      setMakes([...makes, { make: inputMake, models: [] }]);

      form.setFieldValue("make", inputMake);
    }

    setInputMake("");
  };

  const addNewModel = (e) => {
    e.preventDefault();

    if (inputModel !== "" && !models.some((e) => e === inputModel)) {
      setModels([...models, inputModel]);

      form.setFieldValue("model", inputModel);
    }

    setInputModel("");
  };

  const addNewColor = (e) => {
    e.preventDefault();

    if (inputColor !== "" && !colors.some((e) => e.color === inputColor)) {
      setColors([...colors, { color: inputColor }]);

      form.setFieldValue("color", inputColor);
    }

    setInputColor("");
  };

  const handleFinish = async (event) => {
    const data = {
      vin: event.vin ?? "",
      lote: event.lote.trim(),
      year: +event.year.toString().trim(),
      make: event.make.trim().toUpperCase(),
      model: event.model.trim().toUpperCase(),
      color: event.color ? event.color.trim().toUpperCase() : "",
      estado: vehicle ? vehicle.estado : VehiculoEstadoEnum.noSolicitud,
      height: vehicle ? vehicle.height : "",
      length: vehicle ? vehicle.length : "",
      width: vehicle ? vehicle.width : "",
      weight: vehicle ? vehicle.weight : "",
      fecha: vehicle ? vehicle.fecha : null,
      titulo: vehicle ? vehicle.titulo : false,
      llave: vehicle ? vehicle.llave : false,
      catalitico: vehicle ? vehicle.catalitico : false,
      bio: vehicle ? vehicle.bio : false,
      origen: vehicle ? vehicle.origen : null,
      updateBy: vehicle ? vehicle.updateBy : null,
      storage: vehicle ? vehicle.storage : null,
      notas: vehicle ? vehicle.notas : null,
      ubicacion: vehicle ? vehicle.ubicacion : null,
      keyinside: vehicle ? vehicle.keyinside : false,
      radio: vehicle ? vehicle.radio : false,
      llantaResp: vehicle ? vehicle.llantaResp : false,
      billofSale: vehicle ? vehicle.billofSale : false,
      bateria: vehicle ? vehicle.bateria : false,
      cristalTrasero: vehicle ? vehicle.cristalTrasero : false,
      cristalDelantero: vehicle ? vehicle.cristalDelantero : false,
      ventanaIzquierda: vehicle ? vehicle.ventanaIzquierda : false,
      ventanaDerecha: vehicle ? vehicle.ventanaDerecha : false,
      openDoor: vehicle ? vehicle.openDoor : false,
      openCapo: vehicle ? vehicle.openCapo : false,
      estadoInspeccion: vehicle ? vehicle.estadoInspeccion : VehiculoEstadoInspeccionEnum.noInspeccionado,
      destinoCode: vehicle ? vehicle.destinoCode : '',
      idTitle: event.idTitle,
      tituloRecibidoPor: vehicle ? vehicle.tituloRecibidoPor : '',
      tienePiezasInterior: vehicle ? vehicle.tienePiezasInterior : false,
    };

    let newVehicle;

    setLoading(true);
    setIsLoading(true);

    if (vehicle) {
      newVehicle = await editCar(vehicle.vehiculoId, data);
    } else {
      newVehicle = await addCar(data);
    }

    setLoading(false);
    setIsLoading(false);

    if (newVehicle && newVehicle.status) {
      form.resetFields();
      if (onVehicleSaved) {
        onVehicleSaved(newVehicle.data);
      }
    } else {
      if (onVehicleError) {
        onVehicleError(newVehicle.message);
      }
    }
  };

  const handleOnSelectTitle = (event) => {
    const title = titles.find((e) => e.idTitle === event);
    setCanSaveVehicle(title.embarkable);

    if (!title.embarkable) {
      errorMessage(
        "LA SOLICITUD NO PUEDE CREARSE POR QUE EL VEHÍCULO NO ES EMBARCABLE"
      );
    }
  };

  return (
      <Spinner loading={loading}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item label="VIN" name="vin">
            <Input placeholder={"VIN"} />
          </Form.Item>

          <Form.Item
            label="Lote"
            name="lote"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder={"Lote"} />
          </Form.Item>

          <div className="row">
            <div className="col-6">
              <Form.Item
                label="Marca"
                name="make"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  showSearch={true}
                  placeholder={"Buscar marca"}
                  onChange={(e) => onChange(e, "make")}
                  onSearch={(e) => onSearch(e, "make")}
                  filterOption={filterOption}
                  options={makes.map((e) => ({ value: e.make, label: e.make }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="flex-grow-1">
                            <Input
                              placeholder="Please enter make name"
                              value={inputMake}
                              onChange={onInputMakeChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                          </div>
                          <div className="flex-grow-0">
                            <Button type="text" onClick={addNewMake}>
                              Agregar
                            </Button>
                          </div>
                        </div>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </div>

            <div className="col-6">
              <Form.Item
                label="Modelo"
                name="model"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  showSearch={true}
                  placeholder={"Buscar modelo"}
                  onChange={(e) => onChange(e, "model")}
                  onSearch={(e) => onSearch(e, "model")}
                  filterOption={filterOption}
                  options={models.map((model) => ({
                    value: model,
                    label: model,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="flex-grow-1">
                            <Input
                              placeholder="Please enter model name"
                              value={inputModel}
                              onChange={onInputModelChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                          </div>
                          <div className="flex-grow-0">
                            <Button type="text" onClick={addNewModel}>
                              Agregar
                            </Button>
                          </div>
                        </div>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Año"
            name="year"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder={"Año"} />
          </Form.Item>

          <Form.Item label="Color" name="color">
            <Select
              showSearch={true}
              placeholder={"Buscar color"}
              onChange={(e) => onChange(e, "color")}
              onSearch={(e) => onSearch(e, "color")}
              filterOption={filterOption}
              options={colors.map((e) => ({ value: e.color, label: e.color }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="flex-grow-1">
                        <Input
                          placeholder="Please enter color"
                          value={inputColor}
                          onChange={onInputColorChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div className="flex-grow-0">
                        <Button type="text" onClick={addNewColor}>
                          Agregar
                        </Button>
                      </div>
                    </div>
                  </Space>
                </>
              )}
            />
          </Form.Item>
          <Form.Item label="Título" name="idTitle" 
            rules={[{ required: true, message: "El tipo de titulo es requerido" }]}
          >
            <Select
              showSearch={true}
              placeholder={"Buscar título"}
              filterOption={filterOption}
              onChange={handleOnSelectTitle}
              options={titles.map((e) => ({
                value: e.idTitle,
                label: `${e.state} - ${e.titleName} (${
                  e.embarkable ? "Embarcable" : "No Embarcable"
                })`,
              }))}
            />
          </Form.Item>
          {vehicle ? (
            <Button type={"primary"} htmlType={"submit"} block={true}>
              <FontAwesomeIcon icon={faPencil} className="me-2" /> Actualizar
              info del vehículo
            </Button>
          ) : (
            <Button
              type={"primary"}
              htmlType={"submit"}
              block={true}
              disabled={!canSaveVehicle}
              loading={isLoading}
            >
              <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
              {isLoading ? "Guardando..." : "Guardar info del vehículo"}
            </Button>
          )}
        </Form>
      </Spinner>
  );
};

export default VehiculoForm;
