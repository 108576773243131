import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload, faFileText, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { showQuote } from "../Servicios/SolicitudesService";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";
import "../hojas-de-estilo/style.css";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import moment from "moment";
import SpinnerTable from "../Componentes/SpinnerTable";
import {DatePicker, Select} from "antd";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import {showCountries, showOrigen, showSubasta} from "../Servicios/CombosService";
import {getUserSellers} from "../Servicios/UsuarioService";
import {StringFormats} from "../utils/string-formats";

const TablaSolicitudReport = () => {
  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [toDate, setToDate] = useState(undefined);
  const [seller, setSeller] = useState("");
  const [origenId, setOrigenId] = useState(0);
  const [subastaId, setSubastaId] = useState(0);
  const [fromDate, setFromDate] = useState(undefined);
  const [countryId, setCountryId] = useState(0);

  const [countries, setCountries] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [subastas, setSubastas] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [totalElements, setTotalElements] = useState(0);

  const XLSX = require("xlsx");

  const showSolicitudes = async (search, fromDate, toDate, creador, countryId, origenId, subastaId) => {
    setIsLoading(true);

    const from = fromDate ? fromDate.getTime() : 0;
    const to = toDate ? toDate.getTime() : 0;

    const data = await showQuote(0, 0, 0, 0, 0, search, "", creador, origenId, countryId.toString(), false, false, from, to, subastaId);
    setIsLoading(false);

    if (data.status) {
      setQuote(data.list);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    showCountries().then(data => {
      setCountries(data);
    });

    showOrigen().then(data => {
      setOrigenes(data);
    });

    getUserSellers().then(data => {
      setSellers(data);
    });

    showSubasta().then(data => {
      setSubastas(data.list);
    });
  }, []);

  const generarReporte = () => {
    showSolicitudes(filterText, fromDate, toDate, seller, countryId, origenId, subastaId).then();
  }

  const columns = [
    {
      name: "Fecha",
      selector: (row) => row.fechaQ,
      format: (row) => moment(row?.fechaQ).format("l"),
      width: "100px",
    },
    {
      name: "Lote",
      sortable: true,
      selector: (row) => row?.lote,
      width: "90px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Importadora",
      sortable: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Destino",
      sortable: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Origen",
      sortable: true,
      selector: (row) => row?.nameOrigen,
      width: "110px",
    },
    {
      name: "Cliente",
      sortable: true,
      selector: (row) => row?.clienteName,
      width: "250px",
    },
    {
      name: "Flete",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.quoted),
      width: "100px",
    },
    {
      name: "Grua",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.estGrua),
      width: "100px",
    },
    {
      name: "BL",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.bl),
      width: "100px",
    },
    {
      name: "Costo Adicional",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.costoQ),
      width: "150px",
    },
    {
      name: "Total",
      sortable: true,
      selector: (row) => StringFormats.currency((row?.quoted ? +row?.quoted : 0) +
        (row?.estGrua ? +row?.estGrua : 0) +
        (row?.bl ? +row?.bl : 0) +
        (row?.costoQ ? +row?.costoQ : 0)),
      width: "100px",
    },
  ];

  const exportToExcel = () => {
    const excelQuote = quote.map(row => {
      return {
        'Fecha': row?.fechaQ ? moment(row?.fechaQ).format("l") : '---',
        'Lote': row?.lote,
        'Make': row?.vehiculo.make,
        'Model': row?.vehiculo.model,
        'Importadora': row?.import,
        'Aduana': row?.aduanaName,
        'Origen': row?.nameOrigen,
        'Flete': row?.quoted ? +row?.quoted : 0,
        'Grua': row?.estGrua ? +row?.estGrua : 0,
        'BL': row?.bl ? +row?.bl : 0,
        'Costo Adicional': row?.costoQ ? +row?.costoQ : 0,
        'Total': ((row?.quoted ? +row?.quoted : 0) +
          (row?.estGrua ? +row?.estGrua : 0) +
          (row?.bl ? +row?.bl : 0) +
          (row?.costoQ ? +row?.costoQ : 0))
      }
    });

    excelQuote.push({
      'Fecha': '',
      'Lote': '',
      'Make': '',
      'Model': '',
      'Importadora': '',
      'Aduana': '',
      'Origen': '',
      'Flete': '',
      'Grua': '',
      'BL': '',
      'Costo Adicional': '',
      'Total': +(total().replace(/,/g, '').replace(/\$/g, '')),
    })

    const worksheet = XLSX.utils.json_to_sheet(excelQuote);

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      const cell_flete = XLSX.utils.encode_cell({ r: R, c: 7 });
      const cell_grua = XLSX.utils.encode_cell({ r: R, c: 8 });
      const cell_bl = XLSX.utils.encode_cell({ r: R, c: 9 });
      const cell_adicional = XLSX.utils.encode_cell({ r: R, c: 10 });
      const cell_total = XLSX.utils.encode_cell({ r: R, c: 11 });

      if (!worksheet[cell_flete]) continue;
      worksheet[cell_flete].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_grua]) continue;
      worksheet[cell_grua].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_bl]) continue;
      worksheet[cell_bl].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_adicional]) continue;
      worksheet[cell_adicional].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_total]) continue;
      worksheet[cell_total].z = '"$"#,##0.00_);("$"#,##0.00)';
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Solicitudes");
    // Genera el archivo Excel
    XLSX.writeFile(workbook, "solicitudes.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  };

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const handleFilterText = (value) => {
    setFilterText(value);
    showSolicitudes(value, fromDate, toDate, seller, countryId, origenId, subastaId).then();
  }

  const handleChangeRangePicker = (event) => {
    if (event) {
      setFromDate(event[0].$d);
      setToDate(event[1].$d);
    } else {
      setFromDate(undefined);
      setToDate(undefined);
    }
  }

  const handleChangeSelectCountry = (event) => {
    setCountryId(event);
  }

  const handleChangeSelectOrigen = (event) => {
    setOrigenId(event);
  }

  const handleChangeSelectSubasta = (event) => {
    setSubastaId(event);
  }

  const handleChangeSelectSeller = (event) => {
    setSeller(event);
  }

  const total = () => {
    const total = quote
      .reduce((a, b) => {
        return a + ((b?.quoted ? +b?.quoted : 0) +
          (b?.estGrua ? +b?.estGrua : 0) +
          (b?.bl ? +b?.bl : 0) +
          (b?.costoQ ? +b?.costoQ : 0))
      }, 0);

    return StringFormats.currency(total);
  }

  return (
    <Container fluid={true} className="solicitudes">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">Reporte de Solicitudes</h4>

        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload}/> Exportar a Excel
        </Button>
      </div>
      <hr className="mb-0" />
      <Row>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <Form.Label>Filtrar por Fecha</Form.Label>
          <DatePicker.RangePicker className="w-100"
                                  onChange={handleChangeRangePicker} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por País</Form.Label>
            {
              countryId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectCountry(0)} />
            }
          </div>
          <Select className="w-100"
                  value={countryId === 0 ? "" : countryId}
                  onChange={handleChangeSelectCountry}
                  options={(countries ?? []).map(e => {
                    return {
                      value: e.id,
                      label: e.nameCountry
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Origen</Form.Label>
            {
              origenId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectOrigen(0)} />
            }
          </div>
          <Select className="w-100"
                  value={origenId === 0 ? "" : origenId}
                  onChange={handleChangeSelectOrigen}
                  options={(origenes ?? []).map(e => {
                    return {
                      value: e.id,
                      label: e.nameOrigen
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Subasta</Form.Label>
            {
              subastaId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectSubasta(0)} />
            }
          </div>
          <Select className="w-100"
                  value={subastaId === 0 ? "" : subastaId}
                  onChange={handleChangeSelectSubasta}
                  options={(subastas ?? []).map(e => {
                    return {
                      value: e.subId,
                      label: e.subName
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Vendedor</Form.Label>
            {
              seller !== "" &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectSeller("")} />
            }
          </div>
          <Select showSearch={true}
                  className="w-100"
                  value={seller}
                  onChange={handleChangeSelectSeller}
                  options={(sellers ?? []).map(e => {
                    return {
                      value: `${e.firstName} ${e.lastName}`,
                      label: `${e.firstName} ${e.lastName}`
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Label className="input-buscar">
            Buscar Solicitud
          </Form.Label>
          <InputSearch placeholder={'Buscar Solicitud por Lote / Marca / Modelo / Importadora'}
                       doChange={handleFilterText}/>
        </Col>
        <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="m-0 fw-bolder">Total: {totalElements} {totalElements === 1 ? 'solicitud' : 'solicitudes'}</p>
              <p className="m-0 fw-bolder">Total$: { total() }</p>
            </div>
            <div className="text-end">
              <Button size="sm" onClick={generarReporte}>
              <FontAwesomeIcon icon={faFileText}/> Generar Reporte
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <hr/>
      <DataTable
        columns={columns}
        data={quote}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
        progressPending={isLoading}
        progressComponent={<SpinnerTable />}
        persistTableHead
        pagination
      />
    </Container>
  );
};

export default TablaSolicitudReport;
