import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { faCheck, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addCar } from "../Servicios/VehicleService";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import { showVehiclesoli } from "../Servicios/CombosService";
import SpinnerTable from "../Componentes/SpinnerTable";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Vehiculo por Lote"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Vehiculo = () => {
  const [message, setMessage] = useState("");
  // sirve para saber si el formulario fue enviado
  // si le diste click al boton del formulario
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [vehiculos, setVehiculos] = useState([]);

  const [showA, setShowA] = useState(false);
  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const navigate = useNavigate();

  const [newCar, setNewCar] = useState({
    id: "",
    year: 0, // != 0
    make: "", // != ''
    model: "", // != ''
    color: "",
    height: "",
    length: "",
    width: "",
    vin: "",
    weight: "",
    lote: "", // != ''
    fecha: null,
    titulo: false,
    llave: false,
    catalitico: false,
    bio: false,
    estado: "",
    origen: null,
    updateBy: null,
    storage: null,
    notas: null,
    ubicacion: null,
    keyinside: false,
    radio: false,
    llantaResp: false,
    billofSale: false,
    bateria: false,
    cristalTrasero: false,
    cristalDelantero: false,
    ventanaIzquierda: false,
    ventanaDerecha: false,
    openDoor: false,
    openCapo: false,
  });

  const formularioEsValido = () => {
    return (
      newCar.year !== 0 &&
      newCar.make !== "" &&
      newCar.model !== "" &&
      newCar.lote !== ""
    );
  };

  const mostrarVehicle = async () => {
    setIsLoading(true);
    const data = await showVehiclesoli("NO_SOLICITUD").finally(() => {
      setIsLoading(false);
    });
    setVehiculos(data);
  };

  useEffect(() => {
    mostrarVehicle();
    setNewCar({ ...newCar, estado: "NO_SOLICITUD" });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCar({
      ...newCar,
      [name]: value,
    });
  };

  const peticionPost = async () => {
    delete newCar.id;
  };

  const insertarCar = async () => {
    setSubmitted(true);
    if (!formularioEsValido()) {
      return;
    }

    try {
      addCar({
        ...newCar,
        year: +newCar.year,
      })
        .then((data) => {
          notify();
          setTimeout(() => {
            return navigate(`/request/${data.vehiculoId}`);
          }, 4000);
        })
        .catch((error) => {
          setMessage(error.message);
          setTimeout(() => {
            setMessage(" ");
          }, 4000);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  const notify = () =>
    toast.success(
      "Vehiculo registrado correctamente, sera redirigira al siguiente paso",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const columns = [
    {
      name: "Seleccionar",
      width: "60px",
      cell: (row) => (
        <Button as={Link} to={`/request/${row?.vehiculoId}`} variant="primary">
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      ),
    },
    {
      name: "Lote",
      selector: (row) => row.lote,
      width: "120px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row.vehiculoName,
      width: "170px",
    },
    {
      name: "VIN",
      selector: (row) => row.vin,
      width: "170px",
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = vehiculos.filter((item) => {
    return (
      (item?.lote &&
        item?.lote
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.vehiculoName &&
        item?.vehiculoName
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase()))
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <Form.Label className="input-buscar">Filtrar vehiculo </Form.Label>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        </Row>
        <br />
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container>
      <h4>Solicitud de Recolección </h4>
      <br /> <br />
      <Row style={{ justifyContent: "center" }}>
        <Col>
          <Form.Label>
            <strong>Paso 1. - Información del vehículo</strong>
          </Form.Label>
        </Col>{" "}
        |
        <Col>
          <Form.Label>Paso 2. - Información general de recolección</Form.Label>
        </Col>{" "}
        |
        <Col>
          <Form.Label>
            Paso 3. - Imprimir / Enviar Solicitud de recolección
          </Form.Label>
        </Col>
        <hr />
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} lg="5">
          <Card>
            <Card.Header as="h5">Registro de Vehículo</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col>
                    <Form.Label className="text-aling-left">VIN</Form.Label>
                    <Form.Control
                      name="vin"
                      onChange={handleChange}
                      value={newCar?.vin}
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label
                      className={`text-aling-left ${isSubmitted && newCar.lote === "" ? "required" : ""
                        }`}
                    >
                      Lote
                    </Form.Label>
                    <Form.Label className="required">*</Form.Label>
                    <Form.Control
                      required
                      aria-label="Small"
                      onChange={handleChange}
                      name="lote"
                      className={
                        isSubmitted && newCar.lote === "" ? "is-invalid" : ""
                      }
                      aria-describedby="inputGroup-sizing-sm"
                    />
                    {isSubmitted && newCar.lote === "" && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label
                      className={`text-aling-left ${isSubmitted && newCar.make === "" ? "required" : ""
                        }`}
                    >
                      Marca
                    </Form.Label>
                    <Form.Label className="required">*</Form.Label>
                    <Form.Control
                      name="make"
                      onChange={handleChange}
                      value={newCar?.make}
                      required
                      className={
                        isSubmitted && newCar.make === "" ? "is-invalid" : ""
                      }
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                    {isSubmitted && newCar.make === "" && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label
                      className={`text-aling-left ${isSubmitted && newCar.model === "" ? "required" : ""
                        }`}
                    >
                      Modelo
                    </Form.Label>
                    <Form.Label className="required">*</Form.Label>
                    <Form.Control
                      name="model"
                      onChange={handleChange}
                      value={newCar?.model}
                      required
                      className={
                        isSubmitted && newCar.model === "" ? "is-invalid" : ""
                      }
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                    {isSubmitted && newCar.model === "" && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg="6">
                    <Form.Label
                      className={`text-aling-left ${isSubmitted &&
                          (newCar.year === "0" ||
                            newCar.year === "" ||
                            newCar.year.length !== 4)
                          ? "required"
                          : ""
                        }`}
                    >
                      Año
                    </Form.Label>
                    <Form.Label className="required">*</Form.Label>
                    <Form.Control
                      name="year"
                      onChange={handleChange}
                      value={newCar?.year}
                      type={"number"}
                      aria-label="Small"
                      className={
                        isSubmitted &&
                          (newCar.year === "0" ||
                            newCar.year === "" ||
                            newCar.year.length !== 4)
                          ? "is-invalid"
                          : ""
                      }
                      aria-describedby="inputGroup-sizing-sm"
                    />
                    {isSubmitted &&
                      (newCar.year === "0" || newCar.year === "") && (
                        <p
                          className={`m-0 font-12 text-danger font-weight-bold`}
                        >
                          Campo Requerido
                        </p>
                      )}
                    {isSubmitted && newCar.year.length !== 4 && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        El año debe tener 4 digitos
                      </p>
                    )}
                  </Col>
                  <Col xs={12} lg="6">
                    <Form.Label className="text-aling-left">Color</Form.Label>
                    <Form.Control
                      name="color"
                      onChange={handleChange}
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ justifyContent: "center", color: "#ff0000" }}>
                  <Col className="text-center">
                    {message !== "" ? <p>{message}</p> : ""}
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Button
                    type="button"
                    onClick={() => insertarCar()}
                    style={{ width: "250px" }}
                  >
                    Guardar Info de Vehiculo{" "}
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  </Button>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 8 }}>
          <Form.Label className="text-aling-left">
            Nota: Si registró anteriormente un vehículo y no completo la
            solicitud haz
            <Button
              style={{ width: "100px" }}
              onClick={handleShowA}
              variant="light"
            >
              Click Aqui
            </Button>
          </Form.Label>
          <ToastContainer />
        </Col>
      </Row>
      <>
        <Modal
          show={showA}
          onHide={handleCloseA}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Seleccione vehiculo para crear solicitud</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <br />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  progressPending={isLoading}
                  progressComponent={<SpinnerTable />}
                  persistTableHead
                  pagination
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseA}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Container>
  );
};

export default Vehiculo;
