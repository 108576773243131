export const CoordinacionStateEnum = {
  vehiculoNoAsignadoChofer: "Vehiculo no asignado a chofer",
  vehiculoAsignadoChofer: "Vehiculo asignado a chofer",
  vehiculoEnBodega: "Vehiculo en bodega",
  vehiculoPendienteTitulo: "Vehiculo pendiente de titulo",
  vehiculoNoPagadoSubasta: "Vehiculo no pagado en subasta",
  vehiculoPagadoSubasta: "Vehiculo pagado en subasta",
  vehiculoPagadoSubastaSinPreparar: "Vehiculo pagado en subasta pero no prepagado",
  vehiculoCuentaMatusIntl: "Vehiculo de la cuenta Matus Intl",
  vehiculoSinChoferPostCentralDispatch: "Vehiculo no tiene chofer/ Post en central Dispatch",
  vehiculoConProblemasDeCoordinacion: "Vehiculo con problemas de coordinacion",
  vehiculoSinLlave: "Vehiculo sin llave",
  vehiculoConStorage: "Vehiculo con storage",
  vehiculoPendientePrepago: "Pendiente de prepago",
  vehiculoParaCorte: "Vehículo para corte",
  vehiculoDireccionParticular: "Vehículo de dirección particular",
  noPickUp: "No pick Up",
  noDelivery: "No delivery",
  vehiculoPosteado: "Vehículo Posteado",
  vehiculoBodegaSinWarehouse: "Vehículo en bodega sin WareHouse",
  vehiculoBodegaConWarehouse: "Vehículo en bodega con WareHouse",
  vehiculoBodegaTituloPendiente: "Vehículo en bodega con título pendiente",
  vehiculoCuentaDealer: "Vehículo de la cuenta dealer",
  vehiculoEntregaBodega: "Vehículo para entrega en bodega",
  choferAsignado: "Chofer asignado",
  ordenCancelada: "ORDEN CANCELADA",
  completado: "COMPLETADO"
}
