import React, { useState, useEffect } from "react";
import {
  faLeftLong,
  faPaperPlane,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../hojas-de-estilo/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { viewClient } from "../Servicios/ClienteService";
import { showNotas, addNotes } from "../Servicios/ComponedoresService";
import {
  showSegmento,
  showCountries,    
  showImportadora,
  showAduana,
} from "../Servicios/CombosService";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Comentarios from "./CCComp/Comentarios";
import Pagination from "react-bootstrap/Pagination";
import CoordiByClient from "../Coordinaciones/CComp/CoordiByClient";
import useCurrentUser from "../Hooks/UserHook";
import { permisos } from "../utils/permisos";
import { validarPermisos } from "../Servicios/AuthService";
import Spinner from "../shared/spinner";
import { Content } from "antd/es/layout/layout";
import { Card, Col, Row, Space, FloatButton, Drawer } from "antd";
import InitialsAvatar from "../Componentes/widgets/avatar";
import { MessageFilled } from "@ant-design/icons";

function formatNames(fullName) {
  const names = fullName?.split(" ");

  const formattedNames = names?.map((name) => {
    return name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
  });

  const formattedFullName = formattedNames?.join(" ");

  return formattedFullName;
}

function VisualizarCli(props) {
  const [countries, setCountries] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [imports, setImports] = useState([]);
  const [aduanas, setAduana] = useState([]);

  const ITEMS_PER_PAGE = 5;
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [clienteSeleccionado, setClienteSeleccionado] = useState([]);

  const puedeModificarCliente = validarPermisos(permisos.MODIFICAR_CLIENTE);
  const puedeAgregarComentarioCli = validarPermisos(
    permisos.AGREGAR_COMENTARIO_CLIENTE
  );

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setOpen(false);
  };

  let { id } = useParams();

  const mostrarNotas = async () => {
    const data = await showNotas(id);
    setNotes(data.slice(0, ITEMS_PER_PAGE));
    setAllNotes(data);
    setTotalElements(data.length);
  };
  const currentUser = useCurrentUser();

  const [coment, setComent] = useState({
    id: "",
    fecha: "",
    nota: "",
    autor: "",
    clientesId: id,
  });

  const showCliente = async () => {
    setIsLoading(true);
    const data = await viewClient(id).finally(() => {
      setIsLoading(false);
    });
    setClienteSeleccionado(data);
  };

  useEffect(() => {
    mostrarNotas();
    loadCountries();
    loadSegmentos();
    loadImports();
    loadAduana();
    showCliente(id);
  }, [id]);

  useEffect(() => {
    if (currentUser) setComent({ ...coment, autor: currentUser.fullName });
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComent({
      ...coment,
      [name]: value,
    });
  };

  const peticionPost = async () => {
    delete coment.id;
  };

  const navigate = useNavigate();
  const insertarNota = async () => {
    try {
      const data = await addNotes({
        ...coment,
      });
      if (data) {
        mostrarNotas();
        setComent({
          ...coment,
          nota: "",
        });
        return navigate(`/seeProfileClient/${id}`);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const loadCountries = async () => {
    const data = await showCountries();
    setCountries(data);
  };

  const loadSegmentos = async () => {
    const data = await showSegmento();
    setSegmentos(data);
  };

  const loadImports = async () => {
    const data = await showImportadora();
    setImports(data.list);
  };

  const loadAduana = async () => {
    const data = await showAduana();
    setAduana(data);
  };

  const nextHandler = () => {
    const nextPage = currentPage + 1;
    const firstIndex = currentPage * ITEMS_PER_PAGE;

    if (firstIndex === totalElements) return;

    setNotes([...allNotes.slice(firstIndex, firstIndex + ITEMS_PER_PAGE)]);
    setCurrentPage(nextPage);
  };

  const prevHandler = () => {
    const prevPage = currentPage - 1; // 2
    if (prevPage < 1) return;

    const firstIndex = prevPage * ITEMS_PER_PAGE;

    setNotes([...allNotes.slice(firstIndex - ITEMS_PER_PAGE, firstIndex)]);
    setCurrentPage(prevPage);
  };

  return (
    <>
      <Content>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row">
            {/* <h4>Ficha de Cliente - {clienteSeleccionado?.nombreCompleto}</h4> */}
            <h4>Ficha de Cliente</h4>
          </Col>
        </Row>
        <hr />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} >
            <Card
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                width: "100%",
                textAlign: "center",
              }}

              loading={isLoading}
            >
              {clienteSeleccionado?.name1 && (
                <InitialsAvatar
                  firstName={clienteSeleccionado?.name1}
                  lastName={clienteSeleccionado?.apellido1}
                />
              )}

              <hr />

              <div
                style={{
                  textAlign: "center",
                }}
              >
                {/* add h1 font size responsive */}
                <h1 style={{ fontSize: "20px" }}>
                  {formatNames(clienteSeleccionado?.nombreCompleto)}
                </h1>

                <hr />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p>
                      <strong>Numero de ID</strong>
                    </p>
                    <p>
                      <strong>Fecha de vencimiento ID</strong>
                    </p>
                    <p>
                      <strong>Cumpleaños</strong>
                    </p>
                    <p>
                      <strong>Correo Principal</strong>
                    </p>
                    <p>
                      <strong>Correo Secundario</strong>
                    </p>
                    <p>
                      <strong>Link de Archivos Adjuntos</strong>
                    </p>
                    <p>
                      <strong>Fecha de creacion</strong>
                    </p>
                    <p>
                      <strong>Estado</strong>
                    </p>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p>{clienteSeleccionado?.identificacion ? clienteSeleccionado?.identificacion : '-'}</p>
                    <p>{clienteSeleccionado?.expIdent ? clienteSeleccionado?.expIdent : '-' }</p>
                    <p>{clienteSeleccionado?.birth ? clienteSeleccionado?.birth : '-'}</p>
                    <p>{clienteSeleccionado?.email ? clienteSeleccionado?.email : '-'}</p>
                    <p>
                      {clienteSeleccionado?.coDos
                        ? clienteSeleccionado?.coDos
                        : "-"}
                    </p>
                    <p>
                      {clienteSeleccionado?.adjuntos
                        ? clienteSeleccionado?.adjuntos
                        : "-"}
                    </p>
                    <p>{clienteSeleccionado?.fechaCreacion ? clienteSeleccionado?.fechaCreacion : '-'}</p>
                    <span
                      className={
                        clienteSeleccionado?.estado === "Activo"
                          ? "badge bg-success"
                          : "badge bg-danger"
                      }
                    >
                      {clienteSeleccionado?.estado ? clienteSeleccionado?.estado : '-'}
                    </span>
                  </div>
                </div>

                <hr />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p>
                      <strong>POA</strong>
                    </p>
                    <p>
                      <strong>Fecha vencimiento POA</strong>
                    </p>
                    <p>
                      <strong>Segmento</strong>
                    </p>
                    <p>
                      <strong>Clasificacion de Pago</strong>
                    </p>
                    <p>
                      <strong>Aduana de Preferencia</strong>
                    </p>
                    <p>
                      <strong>Asignacion</strong>
                    </p>
                    <p>
                      <strong>Nombre de importadora</strong>
                    </p>
                    <p>
                      <strong>Compra con Cuenta Dealer</strong>
                    </p>
                    <p>
                      <strong>Envia Mercaderia General</strong>
                    </p>
                    <p>
                      <strong>Nombre Comercial/Negocio</strong>
                    </p>
                    <p>
                      <strong>Numero Registro</strong>
                    </p>
                    <p>
                      <strong>Pais</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p>{clienteSeleccionado?.poa ? "Si" : "No"}</p>
                    <p>{clienteSeleccionado?.venciPoa  ? clienteSeleccionado?.venciPoa  : '-'}</p>
                    <p>
                      {
                        segmentos?.filter(
                          (segmento) =>
                            segmento.id === clienteSeleccionado?.segmentoId
                        )[0]?.segName || '-'
                      }
                    </p>
                    <p>{clienteSeleccionado?.classPay}</p>
                    <p>
                      {
                        aduanas?.filter(
                          (aduana) =>
                            aduana.id === clienteSeleccionado?.aduanaId
                        )[0]?.nameAduana || '-'
                      }
                    </p>
                    <p>{clienteSeleccionado?.asignacion || '-'}</p>
                    <p>
                      {
                        imports?.filter(
                          (imp) => imp.id === clienteSeleccionado?.importadoraId
                        )[0]?.impName || '-'
                      }
                    </p>
                    <p>{clienteSeleccionado?.buyDealer ? "Si" : "No"}</p>
                    <p>{clienteSeleccionado?.merchGen ? "Si" : "No"}</p>
                    <p>
                      {clienteSeleccionado?.comercialName
                        ? clienteSeleccionado?.comercialName
                        : "-"}
                    </p>
                    <p>
                      {clienteSeleccionado?.noRegistro
                        ? clienteSeleccionado?.noRegistro
                        : "-"}
                    </p>
                    <p>
                      {
                        countries?.filter(
                          (country) =>
                            country.id === clienteSeleccionado?.countryId
                        )[0]?.nameCountry || '-'
                      }
                    </p>
                  </div>
                </div>

                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p>
                      {" "}
                      <strong>No. Buyer Copart</strong>
                    </p>
                    <p>
                      {" "}
                      <strong>No. Buyer IAAI</strong>
                    </p>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p>{clienteSeleccionado?.numbCop || '-'}</p>
                    <p>{clienteSeleccionado?.numbIaai || '-'}</p>
                  </div>
                </div>

                <hr />
                <strong>Accesos Copart</strong>
                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p>
                      <strong>Usuario</strong>
                    </p>
                    <p>
                      <strong>Contraseña</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p>
                      {clienteSeleccionado?.userCop
                        ? clienteSeleccionado?.userCop
                        : "-"}
                    </p>
                    <p>
                      {clienteSeleccionado?.passCop
                        ? clienteSeleccionado?.passCop
                        : "-"}
                    </p>
                  </div>
                </div>
                <hr />
                <strong>Accesos IAAI</strong>
                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p>
                      <strong>Usuario</strong>
                    </p>
                    <p>
                      <strong>Contraseña</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <p>
                      {clienteSeleccionado?.userIaai
                        ? clienteSeleccionado?.userIaai
                        : "-"}
                    </p>
                    <p>
                      {clienteSeleccionado?.passIaai
                        ? clienteSeleccionado?.passIaai
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Card
              title="Solicitudes Realizadas"
              bordered={false}
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                width: "100%",
                textAlign: "center",
              }}
              loading={isLoading}
            >
              <CoordiByClient />
            </Card>
          </Col>
        </Row>
        <FloatButton
          shape="circle"
          badge={{ count: notes?.length ? notes?.length : 0 }}
          type="primary"
          onClick={showDrawer}
          icon={<MessageFilled />}
        />

        <Drawer
          title="Comentarios"
          footer={
            <div
              style={{
                width: "100%",
              }}
            >
              {puedeAgregarComentarioCli && (
                <Row gutter={24}>
                  <Col span={24}>
                    &nbsp;&nbsp;
                    <Form.Control
                      onChange={handleChange}
                      name="fecha"
                      type="date"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </Col>
                </Row>
              )}
              <br />
              {puedeAgregarComentarioCli && (
                <Row gutter={24}>
                  <Col span={24}>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Escriba su comentario aqui"
                    >
                      <Form.Control
                        name="nota"
                        value={coment?.nota}
                        onChange={handleChange}
                        maxLength={1000}
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "150px" }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}
              <br />
              {puedeAgregarComentarioCli && (
                <Row gutter={24}>
                  <Col span={24}>
                    <Button
                      onClick={() => insertarNota()}
                      variant="primary"
                      style={{ width: "100%" }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      &nbsp;Guardar Comentario
                    </Button>{" "}
                  </Col>
                </Row>
              )}
            </div>
          }
          placement="right"
          onClose={onCloseDrawer}
          open={open}
        >
          <Row gutter={24}>
            <Col span={24}>
              {notes.map((not) => (
                <Comentarios
                  fecha={not.fecha}
                  comentario={not.nota}
                  autor={not?.autor}
                />
              ))}
            </Col>
          </Row>
        </Drawer>

        <br />
        <Row gutter={24} justify={'center'}>
          <Col span={24}>
            <Card
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                textAlign: "center",
              }}

              loading={isLoading}
            >
              <Button
                as={Link}
                to={`/portfolio/country/${clienteSeleccionado.countryId}`}
                variant="warning"
              >
                <FontAwesomeIcon icon={faLeftLong} />
                &nbsp;Regresar
              </Button>{" "}
              &nbsp;
              {puedeModificarCliente && (
                <Button as={Link} to={`/editarCli/${id}`} variant="success">
                  <FontAwesomeIcon icon={faPenToSquare} />
                  &nbsp;Editar
                </Button>
              )}
            </Card>
          </Col>
        </Row>
        {/* <Container className="conte-padre">
          <Spinner loading={isLoading}>
            <Container className="conte-itemb-perfilcliente">
              <div className="conte-cat">
                <h4>Ficha de Cliente - {clienteSeleccionado.nombreCompleto}</h4>
              </div>

              <Accordion className="ficha" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Informacion de Cliente</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <Container>
                        <div className="row">
                          <div className="col-md-12">
                            <Row>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Fecha de Creacion
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="fechaCreacion"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.fechaCreacion
                                  }
                                  type="date"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Activo/Inactivo
                                </Form.Label>
                                <Form.Select
                                  disabled
                                  readOnly
                                  name="estado"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.estado
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                >
                                  <option disabled selected>
                                    Seleccione
                                  </option>
                                  <option>Activo</option>
                                  <option>Inactivo</option>
                                </Form.Select>
                              </div>
                              <div className="col-md-3">
                                <Form.Check
                                  disabled
                                  readOnly
                                  label="POA"
                                  name="poa"
                                  checked={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.poa
                                  }
                                  type="checkbox"
                                  id="inline"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Fecha vencimiento POA
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="venciPoa"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.venciPoa
                                  }
                                  type="date"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Segmento
                                </Form.Label>
                                <Form.Select
                                  disabled
                                  readOnly
                                  name="segmentoId"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.segmentoId
                                  }
                                  aria-label="Small"
                                  defaultValue={"0"}
                                  aria-describedby="inputGroup-sizing-sm"
                                >
                                  <option disabled value={"0"}>
                                    Seleccione
                                  </option>
                                  {segmentos &&
                                    segmentos.length &&
                                    segmentos.map((segmentos, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={segmentos.id}
                                        >
                                          {segmentos.segName}
                                        </option>
                                      );
                                    })}
                                </Form.Select>
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Clasificacion de Pago
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  aria-label="Small"
                                  name="classPay"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.classPay
                                  }
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Aduana de Preferencia
                                </Form.Label>
                                <Form.Select
                                  disabled
                                  readOnly
                                  name="aduanaId"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.aduanaId
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                >
                                  <option disabled selected>
                                    Seleccione
                                  </option>
                                  {aduanas &&
                                    aduanas.length &&
                                    aduanas.map((aduanas, index) => {
                                      return (
                                        <option key={index} value={aduanas.id}>
                                          {aduanas.nameAduana}
                                        </option>
                                      );
                                    })}
                                </Form.Select>
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Asignacion
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  aria-label="Small"
                                  name="asignacion"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.asignacion
                                  }
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-6">
                                <Form.Label className="text-aling-left">
                                  Nombre de importadora
                                </Form.Label>
                                <Form.Select
                                  disabled
                                  readOnly
                                  name="importadoraId"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.importadoraId
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                >
                                  <option disabled selected>
                                    Seleccione
                                  </option>
                                  {imports &&
                                    imports.length &&
                                    imports.map((imports, index) => {
                                      return (
                                        <option key={index} value={imports.id}>
                                          {imports.impName}
                                        </option>
                                      );
                                    })}
                                </Form.Select>
                              </div>
                              <div className="col-md-3">
                                <br />
                                <Form.Check
                                  disabled
                                  readOnly
                                  label="Compra con Cuenta Dealer"
                                  name="buyDealer"
                                  checked={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.buyDealer
                                  }
                                  type="checkbox"
                                  id="inline"
                                />
                              </div>
                              <div className="col-md-3">
                                <br />
                                <Form.Check
                                  disabled
                                  readOnly
                                  label="Envia Mercaderia General"
                                  name="merchGen"
                                  checked={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.merchGen
                                  }
                                  type="checkbox"
                                  id="inline"
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-6">
                                <Form.Label className="text-aling-left">
                                  Nombre Comercial/Negocio
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="comercialName"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.comercialName
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Numero Registro
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="noRegistro"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.noRegistro
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  País
                                </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <Form.Select
                                    disabled
                                    readOnly
                                    name="countryId"
                                    value={
                                      clienteSeleccionado &&
                                      clienteSeleccionado?.countryId
                                    }
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                  >
                                    <option disabled selected>
                                      Seleccione
                                    </option>
                                    {countries &&
                                      countries.length &&
                                      countries.map((countries, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={countries.id}
                                          >
                                            {countries.nameCountry}
                                          </option>
                                        );
                                      })}
                                  </Form.Select>
                                </InputGroup>
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Primer Nombre
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="name1"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.name1
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Segundo Nombre
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="name2"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.name2
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Primer Apellido
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="apellido1"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.apellido1
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Segundo Apellido
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="apellido2"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.apellido2
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Numero de ID
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="identificacion"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.identificacion
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Fecha vencimiento ID
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="expIdent"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.expIdent
                                  }
                                  type="date"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Cumpleaños
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="birth"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.birth
                                  }
                                  type="date"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  No. Buyer Copart
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="numbCop"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.numbCop
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  No. Buyer IAAI
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="numbIaai"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.numbIaai
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Numero de Contacto Principal
                                </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <InputGroup.Text id="inputGroup-sizing-sm">
                                    #1
                                  </InputGroup.Text>
                                  <Form.Control
                                    disabled
                                    readOnly
                                    name="contNum"
                                    value={
                                      clienteSeleccionado &&
                                      clienteSeleccionado?.contNum
                                    }
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-3">
                                <Form.Label className="text-aling-left">
                                  Numero de Contacto Adicional
                                </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <InputGroup.Text id="inputGroup-sizing-sm">
                                    #2
                                  </InputGroup.Text>
                                  <Form.Control
                                    disabled
                                    readOnly
                                    name="numDos"
                                    value={
                                      clienteSeleccionado &&
                                      clienteSeleccionado?.numDos
                                    }
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                  />
                                </InputGroup>
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-4">
                                <Form.Label className="text-aling-left">
                                  Correo Principal
                                </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <InputGroup.Text id="inputGroup-sizing-sm">
                                    @1
                                  </InputGroup.Text>
                                  <Form.Control
                                    disabled
                                    readOnly
                                    name="email"
                                    value={
                                      clienteSeleccionado &&
                                      clienteSeleccionado?.email
                                    }
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-4">
                                <Form.Label className="text-aling-left">
                                  Correo Secundario
                                </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <InputGroup.Text id="inputGroup-sizing-sm">
                                    @2
                                  </InputGroup.Text>
                                  <Form.Control
                                    disabled
                                    readOnly
                                    name="coDos"
                                    value={
                                      clienteSeleccionado &&
                                      clienteSeleccionado?.coDos
                                    }
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                  />
                                </InputGroup>
                              </div>
                            </Row>
                            <Row>
                              <div className="col-md-10">
                                <Form.Label className="text-aling-left">
                                  Link de Archivos Adjuntos
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  readOnly
                                  name="adjuntos"
                                  value={
                                    clienteSeleccionado &&
                                    clienteSeleccionado?.adjuntos
                                  }
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                />
                              </div>
                            </Row>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accesos</Accordion.Header>
                  <Accordion.Body>
                    <div className="general">
                      <div className="row">
                        <div className="col-md-4">
                          <h4>Accesos Copart</h4>
                          <Form.Label className="text-aling-left">
                            Usuario
                          </Form.Label>
                          <Form.Control
                            disabled
                            readOnly
                            name="userCop"
                            value={
                              clienteSeleccionado &&
                              clienteSeleccionado?.userCop
                            }
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Label className="text-aling-left">
                            Contraseña
                          </Form.Label>
                          <Form.Control
                            disabled
                            readOnly
                            name="passCop"
                            value={
                              clienteSeleccionado &&
                              clienteSeleccionado?.passCop
                            }
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <h4>Accesos IAAI</h4>
                          <Form.Label className="text-aling-left">
                            Usuario
                          </Form.Label>
                          <Form.Control
                            disabled
                            readOnly
                            name="userIaai"
                            value={
                              clienteSeleccionado &&
                              clienteSeleccionado?.userIaai
                            }
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Label className="text-aling-left">
                            Contraseña
                          </Form.Label>
                          <Form.Control
                            disabled
                            readOnly
                            name="passIaai"
                            value={
                              clienteSeleccionado &&
                              clienteSeleccionado?.passIaai
                            }
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <hr />
              <Row>
                <CoordiByClient />
              </Row>
            </Container>
          </Spinner>

          <Container className="conte-itemb-perfilcliente-coment">
            <h4>Comentarios</h4>
            {puedeAgregarComentarioCli && (
              <Row>
                &nbsp;&nbsp;
                <Form.Control
                  style={{ width: "200px" }}
                  onChange={handleChange}
                  name="fecha"
                  type="date"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Row>
            )}
            <br />
            {puedeAgregarComentarioCli && (
              <Row>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Escriba su comentario aqui"
                >
                  <Form.Control
                    name="nota"
                    value={coment?.nota}
                    onChange={handleChange}
                    maxLength={1000}
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "150px" }}
                  />
                </FloatingLabel>
              </Row>
            )}
            <br />
            {puedeAgregarComentarioCli && (
              <Row className="conte-padre">
                <Button
                  style={{ width: "200px" }}
                  onClick={() => insertarNota()}
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                  &nbsp;Guardar Comentario
                </Button>{" "}
              </Row>
            )}
            <br />
            <Row>
              {notes.map((not) => (
                <Comentarios
                  fecha={not.fecha}
                  comentario={not.nota}
                  autor={not?.autor}
                />
              ))}

              <Pagination>
                <h5>
                  Pagina: {currentPage}/
                  {Math.ceil(totalElements / ITEMS_PER_PAGE)}
                </h5>{" "}
                &nbsp;&nbsp;
                <Pagination.Prev onClick={prevHandler} />
                <Pagination.Next onClick={nextHandler} />
              </Pagination>
            </Row>
          </Container>
        </Container> */}
      </Content>
    </>
  );
}

export default VisualizarCli;
