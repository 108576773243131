import React, {useEffect, useState} from "react";
import {Col, DatePicker, Row, Select} from "antd";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import {showAduanas} from "../../Servicios/ComponedoresService";
import {CoordinacionStateEnum} from "../../shared/enums/coordinacion-state.enum";
import {showTransp} from "../../Servicios/CombosService";
import dayjs from "dayjs";

const CoordinacionFilter = ({ coordinacionFilters, currentTab, onFilter }) => {
  const [years, setYears] = useState([]);
  const [aduanaList, setAduanaList] = useState([]);
  const [monthsPerYear, setMonthsPerYear] = useState([]);
  const [transporteList, setTransporteList] = useState([]);

  useEffect(() => {
    getAduana().then();
    getTranspote().then();

    initMonthsPerYear();
    initYears();
  }, []);

  const getAduana = async () => {
    const data = await showAduanas();
    setAduanaList(data);
  };

  const getTranspote = async () => {
    const data = await showTransp();
    setTransporteList(data);
  };

  const initMonthsPerYear = () => {
    const meses = Array.from({length: 12}, (_, index) => ({
      value: index + 1,
      label: obtenerNombreMes(index + 1),
    }));

    setMonthsPerYear(meses);
  }

  const initYears = () => {
    // Obtener el año actual
    const currentYear = new Date().getFullYear();

    const years = Array.from(
      {length: 3}, // Cambia el número si deseas más años hacia el futuro
      (_, index) => currentYear - 1 + index
    );

    // Si el año actual no está en la lista, agrégalo
    if (!years.includes(currentYear)) {
      years.push(currentYear);
    }

    setYears(years);
  }

  const obtenerNombreMes = (mes) => {
    const nombresMeses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return nombresMeses[mes - 1];
  };

  const handleChange = (key, value) => {
    const filter = {
      ...coordinacionFilters,
      [key]: value,
    };

    switch (key) {
      case 'day':
        filter.estPickUp = undefined;
        filter.estDelivery = undefined;
        filter.actDelivery = undefined;

        if (value) {
          filter.month = value.getMonth() + 1;
          filter.year = value.getFullYear();
        }
        break;
      case 'estPickUp':
        filter.day = undefined;
        filter.estDelivery = undefined;
        filter.actDelivery = undefined;
        break;
      case 'estDelivery':
        filter.day = undefined;
        filter.estPickUp = undefined;
        filter.actDelivery = undefined;
        break;
      case 'actDelivery':
        filter.day = undefined;
        filter.estPickUp = undefined;
        filter.estDelivery = undefined;
        break;
    }

    onFilter(filter);
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return(
    <>
      <Row gutter={12} className="mb-3 d-flex align-items-center w-100">
        <Col className="mt-3" xs={24} sm={12} md={6} lg={6} xl={3}>
          <Form.Label>Mes</Form.Label>
          <Select value={coordinacionFilters.month}
                  className="w-100"
                  onChange={value => handleChange('month', value)}
                  options={monthsPerYear}/>
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={6} xl={3}>
          <Form.Label>Año</Form.Label>
          <Select value={coordinacionFilters.year}
                  className="w-100"
                  onChange={value => handleChange('year', value)}
                  options={years.map(year => ({value: year, label: year}))} />
        </Col>
        <Col className="mt-3" xs={24} sm={24} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Fecha</Form.Label>
            {
              coordinacionFilters.day &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('day', undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={coordinacionFilters.day ? dayjs(coordinacionFilters.day) : ''}
                      onChange={(value) => handleChange('day', value.$d)} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Aduana</Form.Label>
            {
              coordinacionFilters.aduanaId !== 0 &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('aduanaId', 0)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <Select className="w-100"
                  showSearch={true}
                  filterOption={filterOption}
                  value={coordinacionFilters.aduanaId !== 0
                    ? aduanaList
                      .map(e => ({value: e.id, label: e.nameAduana}))
                      .find(e => e.value === coordinacionFilters.aduanaId)
                    : ''}
                  placeholder={'Seleccionar Aduana'}
                  options={aduanaList.map(aduana => {
                    return {value: aduana.id, label: aduana.nameAduana};
                  })}
                  onChange={(value) => handleChange('aduanaId', value)}/>
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Est./Recolección</Form.Label>
            {
              coordinacionFilters.estPickUp &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('estPickUp', undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={coordinacionFilters.estPickUp ? dayjs(coordinacionFilters.estPickUp) : ''}
                      onChange={(value) => handleChange('estPickUp', value.$d)} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Est./Delivery</Form.Label>
            {
              coordinacionFilters.estDelivery &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('estDelivery', undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={coordinacionFilters.estDelivery ? dayjs(coordinacionFilters.estDelivery) : ''}
                      onChange={(value) => handleChange('estDelivery', value.$d)} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Act./Delivery</Form.Label>
            {
              coordinacionFilters.actDelivery &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('actDelivery', undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={coordinacionFilters.actDelivery ? dayjs(coordinacionFilters.actDelivery) : ''}
                      onChange={(value) => handleChange('actDelivery', value.$d)} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Transportista</Form.Label>
            {
              coordinacionFilters.transporteId !== 0 &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('transporteId', 0)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <Select className="w-100"
                  showSearch={true}
                  filterOption={filterOption}
                  value={coordinacionFilters.transporteId !== 0
                    ? transporteList
                      .map(e => ({value: e.transporteId, label: e.nomComp}))
                      .find(e => e.value === coordinacionFilters.transporteId)
                    : ''}
                  placeholder={'Seleccionar transportista'}
                  options={transporteList.map(transporte => {
                    return {value: transporte.transporteId, label: transporte.nomComp};
                  })}
                  onChange={(value) => handleChange('transporteId', value)}/>
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label className="text-aling-left">
              Filtrar por Estado
            </Form.Label>
            {
              coordinacionFilters.state !== "" && coordinacionFilters.state !== CoordinacionStateEnum.ordenCancelada &&
              <div className="text-danger me-1 cursor-pointer" onClick={() => handleChange('state', "")}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <Select className="w-100"
                  showSearch={true}
                  value={coordinacionFilters.state !== 0
                    ? { value: coordinacionFilters.state, label: coordinacionFilters.state }
                    : ''}
                  disabled={currentTab === "2"}
                  placeholder={'Seleccionar estado'}
                  options={[
                    { value: 'Vehiculo no pagado en subasta', label: 'Vehiculo no pagado en subasta' },
                    { value: 'Vehiculo pagado en subasta', label: 'Vehiculo pagado en subasta' },
                    { value: 'Pendiente de prepago', label: 'Pendiente de prepago' },
                    { value: 'Chofer asignado', label: 'Chofer asignado' },
                    { value: 'Vehículo de dirección particular', label: 'Vehículo de dirección particular' },
                    { value: 'Vehículo para corte', label: 'Vehículo para corte' },
                    { value: 'No pick Up', label: 'No pick Up' },
                    { value: 'No delivery', label: 'No delivery' },
                    { value: 'Vehículo Posteado', label: 'Vehículo Posteado' },
                    { value: 'Vehículo en bodega sin WareHouse', label: 'Vehículo en bodega sin WareHouse' },
                    { value: 'Vehículo en bodega con WareHouse', label: 'Vehículo en bodega con WareHouse' },
                    { value: 'Vehículo en bodega con título pendiente', label: 'Vehículo en bodega con título pendiente' },
                    { value: 'Vehículo de la cuenta dealer', label: 'Vehículo de la cuenta dealer' },
                    { value: 'Vehículo para entrega en bodega', label: 'Vehículo para entrega en bodega' },
                    { value: 'COMPLETADO', label: 'COMPLETADO' },
                    { value: 'ORDEN CANCELADA', label: 'ORDEN CANCELADA' },
                  ]}
                  onChange={(value) => handleChange('state', value)}/>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={24}>
          <Form.Label className="input-buscar">Buscar Coordinación</Form.Label>
          <InputSearch
            initialValue={coordinacionFilters.search ?? ""}
            placeholder={"Buscar por lote, vehículo, nombre de importadora, buyer, subasta o aduana"}
            doChange={(evt) => {
              const filters = {...coordinacionFilters, search: evt};
              onFilter(filters);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default CoordinacionFilter;
