import React, { useState, useEffect } from "react";
import {
  faFloppyDisk,
  faLeftLong,
  faPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../hojas-de-estilo/style.css";
import Form from "react-bootstrap/Form";
import { showOrigen, showTransp } from "../Servicios/CombosService";
import { viewTransp, addTransporte } from "../Servicios/ComponedoresService";
import {
  editQuote,
  enviarEmail,
  viewQuote,
} from "../Servicios/SolicitudesService";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addCoor } from "../Servicios/CoordService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingInside from "../Componentes/LoadingInside";
import { Descriptions, Alert } from "antd";
import Modal from "react-bootstrap/Modal";
import useCurrentUser from "../Hooks/UserHook";
import { SolicitudEstadoEnum } from "../shared/enums/solicitud-estado.enum";
import { errorMessage, successMessage } from "../utils/toast-message";
import { SolicitudEnRevisionEmail } from "../Resources/HtmlEmailBody/SolicitudEnRevision.email";
import environment from "../environments/environment";
import { CoordinacionEntryTypeEnum } from "../shared/enums/coordinacion-entry-type.enum";

function AgregarCoor() {
  const user = useCurrentUser();
  const [transportes, setTransportes] = useState([]);
  const [mostras, setMostras] = useState([]);
  const [idtrans, setIdtrans] = useState([]);
  const [origen, setOrigen] = useState([]);

  const [quoteinfo, setQuoteinfo] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();
  const [message, setMessage] = useState("");

  const [isSubmitted, setSubmitted] = useState(false);

  const [transid, setTransid] = useState([]);

  const [showA, setShowA] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const notify = () =>
    toast.success("Coordinacion guardada correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const loadTransporte = async () => {
    const data = await showTransp();
    setTransportes(data);
  };

  const loadOrigen = async () => {
    const data = await showOrigen();
    setOrigen(data);
  };

  useEffect(() => {
    loadTransporte().then();
    loadOrigen().then();

    viewTransp(idtrans).then((trans) => {
      setMostras(trans);
    });
  }, [idtrans]);

  const infsol = () => {
    setIsLoading(true);
    viewQuote(id)
      .then((quo) => {
        setQuoteinfo(quo);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    infsol();
  }, []);

  useEffect(() => {
    setNewCoor({
      ...newCoor,
      transporteId: transid,
    });
  }, [transid]);

  useEffect(() => {
    setNewCoor({
      ...newCoor,
      aduanaId: quoteinfo.aduanaId,
      clienteId: quoteinfo.clienteId,
      importadoraId: quoteinfo.importadoraId,
      origenId: quoteinfo.origenId,
      subastaId: quoteinfo.subastaId,
      vehiculoId: quoteinfo.vehiculoId,
      countryId: quoteinfo.countryAduId,
      rate_Quoted: quoteinfo.estGrua,
      nota: quoteinfo.comentarios,
      flete: quoteinfo.quoted,
      bl: quoteinfo.bl,
      costAdditional: quoteinfo.costoQ,
      type_pay: quoteinfo.tipoPago,
      buyer: quoteinfo.buyer,
      cdealer: quoteinfo.dealer,
      gatePassPin: quoteinfo.gatePass,
      createBy: user?.fullName,
    });
  }, [quoteinfo]);

  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  const fechaactual = `${day}/${month}/${year}`;

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const date = hoy.toISOString();

  const [newCoor, setNewCoor] = useState({
    id: "",
    fechacor: date, //Fecha de ingreso a Domestic corregir
    paid: null,
    firstBusDay: null,
    lastFreeDay: null,
    estPickUp: null,
    actPickUp: null,
    estDelivery: null,
    actDelivery: null,
    estArrival: null,
    actArrival: null,
    cost: null,
    rate_Quoted: null,
    profit: null,
    st_Cliente: null,
    st_Driver: null,
    st_Matus: null,
    st_Paid: null,
    nota: "",
    state: "",
    quoteId: id,
    vehiculoId: "",
    subastaId: "",
    countryId: "",
    aduanaId: "",
    clienteId: "",
    transporteId: null,
    origenId: "",
    importadoraId: "",
    buyer: null,
    type_pay: "",
    flete: null,
    bl: null,
    costAdditional: null,
    cdealer: false,
    gatePassPin: "",
    entryType: CoordinacionEntryTypeEnum.entryTypeByOwn,
  });

  const formularioEsValido = () => {
    return newCoor.state !== "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCoor({
      ...newCoor,
      [name]: value,
    });
  };

  const handleChangeTransp = (e) => {
    const { name, value } = e.target;
    setMostras({
      ...mostras,
      [name]: value,
    });
  };

  const handleChangeProfit = (e) => {
    const { name, value } = e.target;

    if (name === "cost") {
      const cost = value; // Se mantiene como string
      const costFloat = parseFloat(value);
      const profitFloat = newCoor.rate_Quoted - costFloat;
      const profit = profitFloat.toString();

      setNewCoor({
        ...newCoor,
        [name]: cost,
        profit: profit,
      });
    }
  };

  const handleOnChangeCK = (evt) => {
    const { name, checked } = evt.target;
    setNewCoor({
      ...newCoor,
      [name]: checked,
    });
  };

  const handleOnChangeEntryType = (evt) => {
    const { checked } = evt.target;

    setNewCoor({
      ...newCoor,
      entryType: checked
        ? CoordinacionEntryTypeEnum.entryTypePendingByClient
        : CoordinacionEntryTypeEnum.entryTypeByOwn,
    });
  };

  const insertarTransport = async () => {
    try {
      const data = await addTransporte({
        ...mostras,
      });
      if (data) {
        setTransid(data.transporteId);
        loadTransporte();
        handleCloseA();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleSelectChange = ({ value }) => {
    setIdtrans(value);
    setNewCoor({ ...newCoor, transporteId: value });
  };

  const peticionPost = async () => {
    delete newCoor.id;
  };

  const navigate = useNavigate();

  const insertarCoor = async () => {
    if (!!newCoor.transporteId && !newCoor.estPickUp) {
      errorMessage('La fecha de estimación de salida es requerida');
      return;
    }

    if (!!newCoor.transporteId && !newCoor.estDelivery) {
      errorMessage('La fecha de estimación de llegada es requerida');
      return;
    }

    setSubmitted(true);
    if (!formularioEsValido()) {
      return;
    }

    try {
      setIsLoading(true);
      addCoor({
        ...newCoor,
        transporteId:
          newCoor.transporteId == null ? null : +newCoor.transporteId,
        origenId: +newCoor.origenId,
        clienteId: +newCoor.clienteId,
        importadoraId: +newCoor.importadoraId,
        aduanaId: +newCoor.aduanaId,
        countryId: +newCoor.countryId,
        subastaId: +newCoor.subastaId,
        vehiculoId: +newCoor.vehiculoId,
        quoteId: +newCoor.quoteId,
        createBy: user?.fullName,
        updateBy: "",
        updateDate: null,
      })
        .then((data) => {
          setIsLoading(false);
          notify();
          navigate(`/coordinations/${data.id}/view`);
        })
        .catch((error) => {
          setIsLoading(false);
          setMessage(error.message);
          setTimeout(() => {
            setMessage(" ");
          }, 4000);
        });
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
  };

  const handleChangePrceso = (event) => {
    setQuoteinfo({
      ...quoteinfo,
      proceso: event.target.value,
    });
  };

  const handleSendToReview = async () => {
    const data = {
      ...quoteinfo,
      bl: quoteinfo.bl.toString(),
      costoQ: quoteinfo.costoQ.toString(),
      quoted: quoteinfo.quoted.toString(),
      estGrua: quoteinfo.estGrua.toString(),
      estado: SolicitudEstadoEnum.EnRevision,
      sendedToReview: true,
    };

    setShowReviewModal(false);
    try {
      setIsLoading(true);
      await editQuote(quoteinfo.quoteId, data);
      setIsLoading(false);

      successMessage("La solicitud ha sido enviada a revisión");

      if (!environment.production) {
        quoteinfo.emailUserAssigned = "manuelpc2495@gmail.com";
        quoteinfo.emailUserCreated = "manuelpc2495@gmail.com";
        quoteinfo.emailUserSeller = "manuelpc2495@gmail.com";
      }

      const emails = [];
      const emailsCC = [];

      if (quoteinfo.emailUserAssigned && quoteinfo.emailUserAssigned !== "")
        emailsCC.push(quoteinfo.emailUserAssigned);
      if (quoteinfo.emailUserCreated && quoteinfo.emailUserCreated !== "")
        emails.push(quoteinfo.emailUserCreated);
      if (quoteinfo.emailUserSeller && quoteinfo.emailUserSeller !== "")
        emails.push(quoteinfo.emailUserSeller);

      if (emails.length > 0) {
        await enviarEmail({
          to: emails,
          cc: emailsCC,
          cco: [],
          subject: `Revisión de Solicitud ${quoteinfo.lote} Cliente: ${quoteinfo.clientes?.nombreCompleto}. Asesor Asignado: ${quoteinfo.registradoBy}`,
          body: SolicitudEnRevisionEmail(
            quoteinfo,
            quoteinfo.clientes?.nombreCompleto
          ),
        });
      }

      navigate(`/incomingRequests/${quoteinfo.origenId}`);
    } catch (ex) {
      setIsLoading(false);

      errorMessage("No se ha podido guardar correctamente la solicitud");
    }
  };

  if (isLoading) {
    return <LoadingInside />;
  } else {
    return (
      <Container>
        <Row>
          <h4>Agregar Coordinación</h4>
        </Row>
        <h5>Fecha de ingreso a Domestic: {fechaactual}</h5>
        <hr />
        <strong>Información de solicitud por ventas</strong>
        <Descriptions layout="horizontal" size="small" bordered>
          <Descriptions.Item label="LOTE">
            <strong>{quoteinfo?.lote}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="VEHICULO">
            {quoteinfo?.vehiculoName}
          </Descriptions.Item>
          <Descriptions.Item label="ORIGEN">
            {quoteinfo?.nameOrigen}
          </Descriptions.Item>
          <Descriptions.Item label="VIN">{quoteinfo?.vin}</Descriptions.Item>
          <Descriptions.Item label="COLOR">
            {quoteinfo?.color}
          </Descriptions.Item>
          <Descriptions.Item label="DESTINO">
            {quoteinfo?.aduanaName} - {quoteinfo?.countryName}
          </Descriptions.Item>
          <Descriptions.Item label="SUBASTA" span={2}>
            {quoteinfo?.subName + "-" + quoteinfo?.direccion}
          </Descriptions.Item>
          <Descriptions.Item label="BUYER">
            {quoteinfo?.buyer}
          </Descriptions.Item>
          <Descriptions.Item label="IMPORTADORA" span={3}>
            {quoteinfo?.importadoraName}
          </Descriptions.Item>
          <Descriptions.Item label="CLIENTE">
            {quoteinfo?.clienteName}
          </Descriptions.Item>
        </Descriptions>
        <hr />
        <Row>
          <Col
            style={{ borderRight: "1px", borderColor: "#c7c8c9" }}
            xs={12}
            md={12}
            lg={6}
          >
            <strong>Información de pago</strong>
            <Descriptions layout="vertical" size="small" bordered>
              <Descriptions.Item label="FLETE">
                $ {quoteinfo?.quoted}
              </Descriptions.Item>
              <Descriptions.Item label="BL">
                $ {quoteinfo?.bl}
              </Descriptions.Item>
              <Descriptions.Item label="TIPO DE PAGO">
                {quoteinfo?.tipoPago}
              </Descriptions.Item>
              <Descriptions.Item label="GRUA COTIZADA">
                $ {quoteinfo?.estGrua}
              </Descriptions.Item>
              <Descriptions.Item label="COST. ADD">
                $ {quoteinfo?.costoQ}
              </Descriptions.Item>
              <Descriptions.Item label="TOTAL">
                $ {quoteinfo?.total}
              </Descriptions.Item>
            </Descriptions>
            <br />

            {
              quoteinfo?.notaInterna && (
                <>
              <strong>Información de Coordinación</strong>

              <Alert
                message="Nota Interna"
                description={quoteinfo?.notaInterna}
                type="info"
              />
            </>
              )
            }
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Label>Gate Pass</Form.Label>
                <Form.Control
                  style={{ width: "100px" }}
                  aria-label="Small"
                  value={newCoor?.gatePassPin}
                  type="text"
                  onChange={handleChange}
                  name="gatePassPin"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </div>
              <div className="mt-3 mt-md-0 mt-lg-0 col-lg-4 col-md-6 col-sm-12">
                <Form.Label>Modificar Origen</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  name="origenId"
                  value={newCoor.origenId}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                >
                  <option disabled selected>
                    Seleccione
                  </option>
                  {origen &&
                    origen.length &&
                    origen.map((ori, index) => {
                      return (
                        <option key={index} value={ori.id}>
                          {ori.nameOrigen + " / " + ori.city}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
              <div className="mt-3 mt-md-3 mt-lg-0 col-lg-4 col-md-6 col-sm-12 align-self-end">
                <Form.Check
                  label="Matus Dealer?"
                  name="cdealer"
                  checked={newCoor?.cdealer}
                  onChange={handleOnChangeCK}
                  type="checkbox"
                />

                <Form.Check
                  label="Entrega directa en Bodega"
                  name="entryType"
                  checked={
                    newCoor?.entryType ===
                    CoordinacionEntryTypeEnum.entryTypePendingByClient
                  }
                  onChange={handleOnChangeEntryType}
                  type="checkbox"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Form.Label
                  className={`mt-3 text-aling-left ${
                    isSubmitted && newCoor?.state === "" ? "required" : ""
                  }`}
                >
                  Estado
                </Form.Label>
                <Form.Select
                  onChange={handleChange}
                  name="state"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                >
                  <option disabled selected>
                    Seleccione
                  </option>
                  <option>Vehiculo no pagado en subasta</option>
                  <option>Vehiculo pagado en subasta</option>
                  <option>Pendiente de prepago</option>
                  <option>Chofer asignado</option>
                  <option>Vehículo de dirección particular</option>
                  <option>Vehículo para corte</option>
                  <option>No pick Up</option>
                  <option>No delivery</option>
                  <option>Vehículo Posteado</option>
                  <option>Vehículo en bodega sin WareHouse</option>
                  <option>Vehículo en bodega con WareHouse</option>
                  <option>Vehículo en bodega con título pendiente</option>
                  <option>Vehículo de la cuenta dealer</option>
                  <option>Vehículo para entrega en bodega</option>
                  <option>COMPLETADO</option>
                </Form.Select>
                {isSubmitted && newCoor?.state === "" && (
                  <p className={`m-0 font-12 text-danger font-weight-bold`}>
                    Campo Requerido
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Label className="text-aling-left">
                  Fecha de Pago
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="paid"
                  type="date"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </div>
              <div className="mt-3 mt-md-0 mt-lg-0 col-lg-4 col-md-6 col-sm-12">
                <Form.Label className="text-aling-left">
                  Primer dia Disponible
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="firstBusDay"
                  type="date"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </div>
              <div className="mt-3 mt-md-3 mt-lg-0 col-lg-4 col-md-6 col-sm-12">
                <Form.Label className="text-aling-left">
                  Ultimo dia Disponible
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="lastFreeDay"
                  type="date"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row>
          <div className="col-md-4 find">
            <Form.Label className='text-aling-left'>Buscar Transportista</Form.Label>
            <Select
              onChange={handleSelectChange}
              defaultValue={{ label: "Escriba y Seleccione una opción", value: "empty" }}
              name='transporteId'
              options={transportes && transportes.length && transportes.map(transp => ({
                label: transp.nomComp + "-" + transp.contacto, value: transp.transporteId
              }))}
            />
          </div>
          <div className="col-md-2">
            <br />
            <Tooltip title="Add new carrier">
              <Button variant='success' onClick={handleShowA}>
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
            </Tooltip>
          </div>
        </Row>
        <Row>
          <div className="col-md-4">
            <Form.Label className='text-aling-left'>Compañia de transporte</Form.Label>
            <Form.Control disabled readOnly aria-label="Small" value={mostras?.nomComp} name='nomComp' aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-4">
            <Form.Label className='text-aling-left'>Conductor</Form.Label>
            <Form.Control disabled readOnly aria-label="Small" value={mostras?.contacto} name='contacto' aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Numero del Conductor</Form.Label>
            <Form.Control disabled readOnly aria-label="Small" value={mostras?.telefono} name='telefono' aria-describedby="inputGroup-sizing-sm" />
            <br />
          </div>
          <hr />
        </Row>
        <Row>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Primer dia Disponible</Form.Label>
            <Form.Control onChange={handleChange} name='firstBusDay' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Est. Pick Up</Form.Label>
            <Form.Control onChange={handleChange} name='estPickUp' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Est. Delivery</Form.Label>
            <Form.Control onChange={handleChange} name='estDelivery' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Departing</Form.Label>
            <Form.Control onChange={handleChange} name='estArrival' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
        </Row>
        <Row>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Ultimo dia Disponible</Form.Label>
            <Form.Control onChange={handleChange} name='lastFreeDay' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Act. Pick Up</Form.Label>
            <Form.Control onChange={handleChange} name='actPickUp' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Act. Delivery</Form.Label>
            <Form.Control onChange={handleChange} name='actDelivery' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Arrival</Form.Label>
            <Form.Control onChange={handleChange} name='actArrival' type="date" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
            <br />
          </div>
          <hr />
        </Row>
        <Row>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Storage-Client</Form.Label>
            <Form.Control onChange={handleChange} name='st_Cliente' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Storage-Driver</Form.Label>
            <Form.Control onChange={handleChange} name='st_Driver' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Storage-Matus</Form.Label>
            <Form.Control onChange={handleChange} name='st_Matus' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-3">
            <Form.Label className='text-aling-left'>Storage Pagado</Form.Label>
            <Form.Control onChange={handleChange} name='st_Paid' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
            <br />
          </div>
        </Row>
        <Row>
          <Col>
            <FloatingLabel controlId="floatingTextarea2" label="Comentario">
              <Form.Control
                name='nota'
                value={newCoor?.nota}
                onChange={handleChange}
                placeholder="Leave a comment here"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
          </Col>
          <div className="col-md-2">
            <Form.Label className='text-aling-left'>Costo Grua</Form.Label>
            <Form.Control onChange={handleChangeProfit} name='cost' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-2">
            <Form.Label className='text-aling-left'>Grua Cotizada</Form.Label>
            <Form.Control disabled readOnly value={quoteinfo?.estGrua} name='rate_Quoted' aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
          <div className="col-md-2">
            <Form.Label className='text-aling-left'>Profit</Form.Label>
            <Form.Control disabled readOnly name='profit' value={newCoor.profit} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
          </div>
        </Row>  */}
        <hr />
        <Row className="conte-padre">
          <Button
            style={{ width: "120px" }}
            as={Link}
            to={`/incomingRequests/${quoteinfo.origenId}`}
            variant="warning"
          >
            <FontAwesomeIcon icon={faLeftLong} />
            &nbsp;Regresar
          </Button>
          <Button
            className="mx-3"
            style={{ width: "120px" }}
            onClick={() => insertarCoor()}
            variant="primary"
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
            &nbsp;Guardar
          </Button>
          <Button
            style={{ width: "220px" }}
            onClick={() => setShowReviewModal(true)}
            variant="secondary"
          >
            <FontAwesomeIcon icon={faPlane} />
            &nbsp;Enviar a Revisión
          </Button>

          <ToastContainer />
        </Row>

        <>
          <Modal
            show={showA}
            onHide={handleCloseA}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Agregar Nuevo Transportista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Compañia de transporte</Form.Label>
              <Form.Control
                onChange={handleChangeTransp}
                name="nomComp"
                type="text"
                placeholder="Nombre compañia de transporte"
              />
              <br />
              <Form.Label>Conductor</Form.Label>
              <Form.Control
                onChange={handleChangeTransp}
                name="contacto"
                type="text"
                placeholder="Conductor"
              />
              <br />
              <Form.Label>Numero de Conductor</Form.Label>
              <Form.Control
                onChange={handleChangeTransp}
                name="telefono"
                type="text"
                placeholder="Numero de contacto"
              />
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseA} variant="secondary">
                Cancelar
              </Button>
              <Button onClick={insertarTransport} variant="primary">
                <FontAwesomeIcon icon={faFloppyDisk} /> Guardar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showReviewModal}
            backdrop="static"
            keyboard={false}
            onHide={() => setShowReviewModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Notas de Revisión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Justificación</Form.Label>
              <Form.Control
                onChange={handleChangePrceso}
                name="proceso"
                as="textarea"
                rows={4}
                type="text"
                placeholder="Detalle el motivo de la revisión"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ width: "220px" }}
                onClick={handleSendToReview}
                variant="secondary"
              >
                <FontAwesomeIcon icon={faPlane} />
                &nbsp;Enviar a Revisión
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    );
  }
}

export default AgregarCoor;
