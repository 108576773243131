import ExportationTable from "../ExportationTable";
import {useEffect, useState} from "react";
import {getExportations} from "../../../Servicios/ExportationServices";
import Spinner from "../../../shared/spinner";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import useCurrentUser from "../../../Hooks/UserHook";
import localStorageFilter from "../../../shared/utils/local-storage-filter";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
}

const ExportationNavieraPage = () => {
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [exportations, setExportations] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('exportationValidacion:filter', 'pageCount') ?? 100,
    currentPage: localStorageFilter('exportationValidacion:filter', 'currentPage') ?? 1,
    search: localStorageFilter('exportationValidacion:filter', 'search') ?? "",
  });

  useEffect(() => {
    if (currentUser) {
      initExportations(filters).then();
    }

    // save filters
    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('exportationValidacion:filter', JSON.stringify(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, filters]);

  const initExportations = async (filters: Filters) => {
    const origen = +currentUser.idubc;

    setLoading(true);
    const data = await getExportations(origen, filters.currentPage, filters.pageCount, filters.search, ExportationStateEnum.ShippingInstruction);
    setLoading(false);
    setTotalElements(data?.totalElements);

    if (data && data.status) {
      setExportations(data.list.map((e, i) => ({...e, key: i})));
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      pageCount: pageSize,
      currentPage: page,
    });
  }

  const handleSearch = async (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    });
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="mb-3">
          <InputSearch initialValue={filters.search}
                       placeholder={'Search by shipping name, booking number, vin or lot'}
                       doChange={handleSearch} />
        </div>
        <ExportationTable pageCount={filters.pageCount}
                          currentPage={filters.currentPage}
                          totalElements={totalElements}
                          exportations={exportations}
                          onPageChange={handlePageChange}
                          onEditExportation={() => initExportations(filters).then()} />
      </Spinner>
    </>
  );
}

export default ExportationNavieraPage;
