import {Image} from "antd";
import React from "react";

const DetalleExportInfo = ({detalleExport}) => {
  return(
    <>
      <Image className="detalle-export-info-image"
             src={detalleExport?.werehouse?.vehiculo?.imagenFrontal} height={220} width={'100%'} />
      <p className="d-flex justify-content-between">
        <strong>{detalleExport?.codeExpSecuential}</strong>
      </p>
      <p className="d-flex justify-content-between">
        <strong>Lote:</strong>
        <span className="text-end">{detalleExport?.vehiculo?.lote}</span>
      </p>
      <p className="d-flex justify-content-between">
        <strong>Vehículo:</strong>
        <span className="text-end">{detalleExport?.vehiculo?.vehiculoName}</span>
      </p>
      <p className="d-flex justify-content-between">
        <strong>VIN:</strong>
        <span className="text-end">{detalleExport?.vehiculo?.vin}</span>
      </p>
    </>
  );
}

export default DetalleExportInfo;
