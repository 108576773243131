import {server} from "../environments/environment";
import {toast} from 'react-toastify';
import {ApiService} from "./ApiService";
//CREATE QUOTE SERVICE SAVE, UPDATE AND DELETE

export const getQuotation = async () => {
  const response = await ApiService.get(`${server}Cotizaciones`);
  const {list} = await response.json();
  return list;
}

export const getQuotationById = async (id) => {
  const response = await ApiService.get(`${server}Cotizaciones?codigo=${id}`);
  const {list} = await response.json();
  return list[0];
}

// SAVE QUOTE

export const saveQuotation = async (quote, detailQuote, charges) => {
  const body = {
    ...quote,
    detalles: detailQuote,
    charges: charges
  }

  const response = await ApiService.post(`${server}Cotizaciones`, body);
  const {status, data} = await response.json();

  if (!status) {
    throw Error("No se pudo guardar la cotizacion");
  }

  return data;
}

// UPDATE QUOTE

export const updateQuotation = async (quote, id) => {
  const response = await ApiService.put(`${server}Cotizaciones/${id}`, quote);
  const {data} = await response.json();

  return data;
}


// SAVE DETAIL QUOTE

export const saveQuotationCharge = async (idCotizacion, quotationCharge) => {
  const response = await ApiService.post(`${server}Cotizaciones/${idCotizacion}/cotizacionCharge`, quotationCharge);
  const {data} = await response.json();
  return data;
}

export const updateQuotationCharge = async (idCotizacionCharge, quotationCharge) => {
  const response = await ApiService.put(`${server}Cotizaciones/cotizacionCharge/${idCotizacionCharge}`, quotationCharge);
  const {data} = await response.json();
  return data;
}

export const deleteQuotationCharge = async (idCotizacionCharge) => {
  const response = await ApiService.delete(`${server}Cotizaciones/cotizacionCharge/${idCotizacionCharge}`);
  const {data} = await response.json();
  return data;
}

export const saveQuotationDetail = async (idCotizacion, quotationDetail) => {
  const response = await ApiService.post(`${server}DetalleCotizacion/${idCotizacion}`, quotationDetail);
  const {data} = await response.json();
  return data;
}

export const deleteQuotationDetail = async (idCotizacionDetail) => {
  const response = await ApiService.delete(`${server}DetalleCotizacion/${idCotizacionDetail}`);
  const {data} = await response.json();
  return data;
}

//generar PDF quotation

export const getPDFQuote = async (id) => {
  const URL = `${server}Cotizaciones/reporte/${id}`
  try {
    const response = await ApiService.get(URL);
    const data = await response.json()
    const qo = data.data;
    return qo;

  } catch (ex) {
    throw new Error(ex);
  }
}




