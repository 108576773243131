import {CountryCodeEnum} from "../../shared/enums/country-code.enum";

export const NotificacionArriboEmail = (codigo, country) =>  `
   <!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <meta name="x-apple-disable-message-reformatting">
  <title>ConfirmationRequest</title>
  <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
  <style>
    table,
    td,
    div,
    h1,
    p {
      font-family: Arial, sans-serif;
    }
  </style>
</head>

<body style="margin:0;padding:0;">
  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
      <td align="center" style="padding:0;">
        <table role="presentation"
          style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
            <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
              <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
            </td>
          </tr>
          <tr>
            <td style="padding:5px 25px 0px 25px;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;">
                    <h1 style="text-align: center">Aviso de Arribo ${codigo}</h1>
                    <p>Estimado cliente</p>
                    
                    <p style="margin: 0">
                      Por este medio se le confirma que el contenedor donde se embarcó su vehículo ya ingresó a aduana destino.
                    </p>
                    <p style="margin-top: 0">Por favor tome nota del documento adjunto donde se especifica:</p>
                    <ul>
                        <li><p style="margin: 0">Registro de costos de transporte</p></li>
                        <li><p style="margin: 0">Información general de su importancia.</p></li>
                    </ul>
                    
                    <p style="margin: 0">Tan pronto el contenedor haya sido descargado se le estará notificando en esta línea de correo.</p>
                    <p style="margin-top: 0">Si necesita actualización sobre esta información contacte al equipo de atención al cliente de oficina:</p>
                    
                    <p>Numeros de atención al cliente</p>
                    <p>${country === CountryCodeEnum.SV ? "+503 79851567 <a href='https://wa.me/50379851567'>Ir a Whatsapp</a>" : country === CountryCodeEnum.HN ? "+504 88191095 <a href='https://wa.me/50488191095'>Ir a Whatsapp</a>" : country === CountryCodeEnum.GT ? "+502 55112911 <a href='https://wa.me/50255112911'>Ir a Whatsapp</a>" : "+506 87567950 <a href='https://wa.me/50687567950'>Ir a Whatsapp</a>"}</p>
                    
                    <p>Link de políticas básicas Matus International: <a href="${country === CountryCodeEnum.SV ? "https://drive.google.com/drive/folders/1G6REpox_CBr-vIjKdmIgnA7hZEFEyAbe" : country === CountryCodeEnum.HN ? "https://drive.google.com/drive/folders/1zdt2yZt56cKaF1LRZ_JDlDJk-_D-xqG5?usp=drive_link" : country === CountryCodeEnum.GT ? "https://drive.google.com/drive/folders/1texM-qhk4jvX6n8J5m9-_SB95PTvq8cM?usp=drive_link" : "https://drive.google.com/drive/folders/1v6_yQrpLbQDxXWwTX3jNUihFmApaY86S?usp=drive_link"}">AQUI</a></p>
                    
                    <p style="font-weight: bold;">Importante:</p>
                    <p style="margin: 0">Cualquier reclamo sobre este vehículo tiene 5 días a partir del envío de este correo, pasado este tiempo y si su cuenta se encuentra en mora Matus International no se hace responsable de reclamos.</p>
                    <p style="margin-top: 0">Por favor tome nota que Matus International no se hace responsable por daños o extravíos causados en descarga ya que es responsabilidad de la almacenadora de su elección.</p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding:30px;background:#1C2E51;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;width:50%;" align="left">
                    <p style="color:white;">&reg; Matus International, 2023</p>
                  </td>
                  <td style="padding:0;width:50%;" align="right">
                    <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                      <tr>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.facebook.com/matusintl/"><img
                              src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.instagram.com/matusintl/?hl=en"><img
                              src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web"
                              width="28" style="height:auto;display:block;border:0;" /></a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>
    `;
