export const CoordinacionNotificarCliente = (item) => `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>ConfirmationRequest</title>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <style>
    table, td, div, h1, p {font-family: Arial, sans-serif;}
    </style>
    </head>
    <body style="margin:0;padding:0;">
    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
    <td align="center" style="padding:0;">
      <table role="presentation" style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
          <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
            <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
          </td>
        </tr>
        <tr>
          <td style="padding:5px 25px 0px 25px;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;">
                  <h1 style="text-align: center">${item.subastaId === 571 ? `Confirmación de entrega en bodega` : `Confirmación de Solicitud de recolección`} <br/> ${item.cdealer ? 'Cuenta Dealer' : ''}</h1>
                  <p>Hola estimado cliente, ${item.clienteName} #Tel: ${item.clientes.contNum}</p><br/> 
                  ${item.subastaId === 571 ? `<p>Gracias por tomar la decisión de hacer crecer su negocio con Matus International Inc.<br/><br/>
                  Confirmo que hemos recibido su solicitud a continuación, le presentamos la información de su orden: <br/><br/>
               </p>`:`<p>Gracias por tomar la decisión de hacer crecer su negocio con Matus International Inc.<br/>
                     Confirmo que hemos recibido su solicitud a continuación, le presentamos la información de su orden de recolección, con mucho gusto trabajaremos en este movimiento lo más pronto posible<br/><br/>
                  </p>
                  <p>
                    Adicional le informamos que esta orden tiene un estado: <strong>${item.state}</strong>
                  </p>`} 

                  <p>
                    ${ item.paid ? 'Con fecha de pago: ' + new Date(item.paid).getDate() + '/' + (new Date(item.paid).getMonth() + 1) + '/' + new Date(item.paid).getFullYear() : '' }<br/>
                  </p>
                  
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:10px;">
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    
              <tr>
                <td style="width:260px">
                  <strong>Importadora</strong>
                </td>
                <td style="width:260px">
                  <strong>VIN</strong>
                </td>
                <td style="width:260px">
                  <strong>Vehiculo</strong>
                </td>
                <td style="width:260px">
                  <strong>Subasta</strong>
                </td>
                <td style="width:200px">
                  <strong>Lote</strong>
                </td>
                <td style="width:200px">
                  <strong>GatePass</strong>
                </td>
                <td style="width:200px">
                  <strong>Buyer</strong>
                </td>
              </tr>
              <tr>
                <td style="width:260px">
                  ${item?.importadora?.impName}
                </td>
                <td style="width:200px">
                  ${item?.vin}
                </td>
                <td style="width:200px">
                  ${item?.vehiculoName}
                </td>
                <td style="width:260px">
                  ${item?.subs}
                </td>         
                <td style="width:260px">
                  ${item?.lote}
                </td>       
                <td style="width:260px">
                  ${item?.gatePassPin}
                </td>
                <td style="width:100px">
                  ${item?.buyer}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <br/> <br/>
        <tr>
          <td>
            <h2  style="text-align: center">Datos de exportacion y costos</h2>
            <div style="margin: 0 auto; width: 520px;">
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;"> 
              <tr>
                <td style="width:260px; text-align: center;">
                  <strong>Origen</strong>
                </td>
                <td style="width:260px; text-align: center;">
                  <strong>Destino</strong>
                </td>
              </tr>
              <tr>
                <td style="width:260px; text-align: center;">
                  ${item?.origenName}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item?.aduanaCom}
                </td>
              </tr>
            </table>
            <hr/>
            </div>
            <div>
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;"> 
              <tr>
                <td style="width:260px; text-align: center;">
                  <strong>Flete</strong>
                </td>
                <td style="width:260px; text-align: center;">
                  <strong>Grua</strong>
                </td>
                <td style="width:260px; text-align: center;">
                  <strong>BL</strong>
                </td>
                <td style="width:260px; text-align: center;">
                  <strong>Costo Adicional</strong>
                </td>
                <td style="width:260px; text-align: center; background-color: #f2f2f2;">
                  <strong>Total</strong>
                </td>
              </tr>
              <tr>
                <td style="width:260px; text-align: center;">
                  ${item?.flete}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item?.grua}
                </td>
                <td style="width:260px; text-align: center;">
                  ${item?.bl}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item?.costAdditional ?? '$0.00'}
                </td>
                <td style="width:260px; text-align: center; background-color: #f2f2f2;">
                  ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item?.total ?? 0)}
                </td>
              </tr>
            </table>
            <hr/>
          </div>
          </td>
        </tr>
        <br/> <br/>
        <tr>
          <td>
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;"> 
              <tr>
                <td style="width:260px; text-align: center;">
                  Asesor de ventas
                </td>
                <td style="width:260px; text-align: center;">
                  Confirmado por
                </td>
              </tr>
              <tr>
                <td style="width:260px; text-align: center;">
                  ${item?.quote?.creador}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item?.createBy ?? ""}
                </td>
              </tr>
            </table>
            <br/>
                <p style="text-align: center;">
                  <strong>**Favor leer notas importantes para la recoleccion y embarque de su vehiculo en el archivo adjunto**</strong>
                </p>
          </td>
        </tr>
        <br/> <br/>
        <tr> 
          <td style="padding:30px;background:#1C2E51;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;width:50%;" align="left">
                  <p style="color:white;">&reg; Matus International, 2023</p>
                </td>
                <td style="padding:0;width:50%;" align="right">
                  <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="padding:0 0 0 10px;width:38px;">
                      <a href="https://www.facebook.com/matusintl/"><img src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.instagram.com/matusintl/?hl=en"><img src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
    </tr>
    </table>
    </body>
    </html>`;


export const CoordinacionNotifacarClienteMultiple = (item) => `
<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>ConfirmationRequest</title>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <style>
    table, td, div, h1, p {font-family: Arial, sans-serif;}
    </style>
    </head>
    <body style="margin:0;padding:0;">
    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
    <td align="center" style="padding:0;">
      <table role="presentation" style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
          <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
            <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
          </td>
        </tr>
        <tr>
          <td style="padding:5px 25px 0px 25px;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;">
                  <h1 style="text-align: center">Solicitud orden de recolección <br/> ${item.cdealer ? 'Cuenta Dealer' : ''}</h1>
  
                  <p>Hola estimado cliente, ${item[0]?.clienteName} #Tel: ${item[0]?.clientes?.contNum}<br/><br/> 
                     Gracias por tomar la decisión de hacer crecer su negocio con Matus International Inc.<br/><br/>
                     Confirmo que hemos recibido su solicitud a continuación, le presentamos la información de su orden de recolección, con mucho gusto trabajaremos en este movimiento lo más pronto posible<br/><br/>
                  </p>
                  <p>
                    Adicional le informamos que esta orden tiene un estado: <strong>${item[0].state}</strong>
                    <br/>
                    ${ item[0]?.paid ? 'Con fecha de pago: ' + new Date(item[0]?.paid).getDate() + '/' + (new Date(item[0].paid).getMonth() + 1) + '/' + new Date(item[0].paid).getFullYear() : '' }<br/>
                  </p>
                  
                  <p>
                    <strong>Titulos de vehículos:</strong>

                    ${item.map((i) => {
                      if (i.vehiculo?.title) {
                        return `
                        <li>
                          <strong>Lote:</strong> <span>${i?.lote}</span> <strong>Vehículo:</strong> <span>${i?.vehiculoName}</span> <strong>Titulo: </strong> <span>${i?.vehiculo?.title?.titleName}</span> 
                        </li>
                        `;
                      }

                    })}
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <h2  style="text-align: center">Datos de exportacion y costos</h2>
            <div style="margin: 0 auto; width: 520px;">
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;"> 
              <tr>
                <td style="width:260px; text-align: center;">
                  <strong>Origen</strong>
                </td>
                <td style="width:260px; text-align: center;">
                  <strong>Destino</strong>
                </td>
              </tr>
              <tr>
                <td style="width:260px; text-align: center;">
                  ${item[0]?.origenName}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item[0]?.aduanaCom}
                </td>
              </tr>
            </table>
            </div>
            <div>
            <hr/>
          </div>
          </td>
        </tr>
        <br/>
        <tr>
          <td style="padding:10px;">
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    
              <tr>
              
                <td style="width:260px">
                  <strong>Importadora</strong>
                </td>
                <td style="width:260px">
                  <strong>VIN</strong>
                </td>
                <td style="width:260px">
                  <strong>Vehiculo</strong>
                </td>
                <td style="width:260px">
                  <strong>Subasta</strong>
                </td>
                <td style="width:200px">
                  <strong>Lote</strong>
                </td>
                <td style="width:200px">
                  <strong>GatePass</strong>
                </td>
                <td style="width:200px">
                  <strong>Buyer</strong>
                </td>
              </tr>
              ${item.map((i) => `
              <tr>
                <td style="width:260px">
                  ${i?.importadora?.impName}
                </td>
                <td style="width:200px">
                  ${i?.vin}
                </td>
                <td style="width:200px">
                  ${i?.vehiculoName}
                </td>
                <td style="width:260px">
                  ${i?.subs}
                </td>         
                <td style="width:260px">
                  ${i?.lote}
                </td>       
                <td style="width:260px">
                  ${i?.gatePassPin}
                </td>
                <td style="width:100px">
                  ${i?.buyer}
                </td>
              </tr>
              ` )}
            </table>
          </td>
        </tr>
        <br/> <br/>
        
        <tr>
          <td>
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;"> 
              <tr>
                <td style="width:260px; text-align: center;">
                  Asesor de ventas
                </td>
                <td style="width:260px; text-align: center;">
                  Confirmado por
                </td>
              </tr>
              <tr>
                <td style="width:260px; text-align: center;">
                  ${item[0]?.quote?.creador}
                </td>
                <td style="width:200px; text-align: center;">
                  ${item[0]?.createBy ?? ""}
                </td>
              </tr>
            </table>
            <br/>
                <p style="text-align: center;">
                  <strong>**Favor leer notas importantes para la recoleccion y embarque de su vehiculo en el archivo adjunto**</strong>
                </p>
          </td>
        </tr>
        <br/> <br/>
        <tr> 
          <td style="padding:30px;background:#1C2E51;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;width:50%;" align="left">
                  <p style="color:white;">&reg; Matus International, 2023</p>
                </td>
                <td style="padding:0;width:50%;" align="right">
                  <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="padding:0 0 0 10px;width:38px;">
                      <a href="https://www.facebook.com/matusintl/"><img src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.instagram.com/matusintl/?hl=en"><img src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
    </tr>
    </table>
    </body>
    </html>
`;