import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addQuote } from "../../Servicios/SolicitudesService";
import { addCoor } from "../../Servicios/CoordService";
import {
  addPushAllVehicles,
  deleteOneVehicles,
  deleteAllVehicles,
  addPushAllSolicitud,
  deleteAllSolicitud,
  deleteOneSolicitud,
  getAllCoordinations,
  addPushAllCoordinations,
  deleteAllCoordinations,
  deleteOneCoordinations,
  deleteAllAttachments, 
  addCoordinationSave,
  deleteAllCoordinationSave,
  loading
} from "./";
import { enviarEmail } from "../../Servicios/SolicitudesService";
import environment from "../../environments/environment";
import { CoordinacionNotifacarClienteMultiple } from "../../Resources/HtmlEmailBody/CoordinacionNotificarCliente";
import {addCar} from "../../Servicios/VehicleService";

export const sendAllVehicles = (vehicles) => {
  return async (dispatch) => {
    await dispatch(addPushAllVehicles(vehicles));
    toast.success(`Se han añadio un nuevo vehiculo!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

// eliminar un vehiculo mediante el vin del vehiculo
export const deleteOneVehicle = (vin) => {
  return async (dispatch, getState) => {
    const { allVehicles } = getState().solicitud;
    console.log("allVehicles", allVehicles);
    const newVehicles = allVehicles.filter((item) => item.vin !== vin);
    console.log("newVehicles", newVehicles);
    await dispatch(deleteOneVehicles(newVehicles));
    toast.success(`Se ha eliminado el vehiculo con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

// eliminar todos los vehiculos
export const deleteAllVehicle = () => {
  return async (dispatch) => {
    await dispatch(deleteAllVehicles([]));
    toast.success(`Se han eliminado todos los vehiculos con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

// save all vehicles
export const saveAllVehicles = (arrayVehicle) => {
  return async (dispatch) => {
    arrayVehicle.forEach(async (item, index) => {
      const resp = await addCar(item);
      console.log("Respuesta " + index, resp);
    });

    await dispatch(deleteAllVehicles([]));
    toast.success(`Se han guardado todos los vehiculos con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const sendAllSolicitud = (solicitud) => {
  return async (dispatch) => {
    await dispatch(addPushAllSolicitud(solicitud));
    toast.success(`Se ha añadido una nueva solicitud!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const deleteOneSolicitude = (indexPosition) => {
  return async (dispatch, getState) => {
    const { allSolicitud } = getState().solicitud;
    const newSolicitud = allSolicitud.filter(
      (item, index) => index !== indexPosition
    );
    await dispatch(deleteOneSolicitud(newSolicitud));
    toast.success(`Se ha eliminado la solicitud con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const deleteAllSolicitude = () => {
  return async (dispatch) => {
    await dispatch(deleteAllSolicitud([]));
    toast.success(`Se han eliminado todas las solicitudes con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const saveAllSolicitud = (arraySolicitud, arrayAttachments ,currentUser) => {
  return async (dispatch) => {
    await dispatch(loading(true));
    let arrayResp = [];
    let arrayBuyer = [];

    for (let index = 0; index < arraySolicitud.length; index++) {
      const item = arraySolicitud[index];
      console.log("itemSolicitud", item);
      // eliminar el vehicleName del objeto y el lote para que no se guarde en la base de datos

      const { vehicleName, lote,  ...rest } = item;
      
      const resp = await addQuote(rest);
      console.log("Respuesta " + index, resp);
      arrayResp.push(resp.data)
      arrayBuyer.push({ buyer: resp.data.buyer, lote: resp.data.lote });

    }

    console.log("arrayResp", arrayResp);

    // delete buyer duplicate
    const uniqueBuyer = arrayBuyer.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.buyer === item.buyer)
    );


    console.log("arrayResp", arrayResp);

    let arrayCC = [];
    const getEmail = (email) => (email ? arrayCC.push(email) : null);

    const firstEmail = arrayResp[0]?.emailCli;
    const secondEmail = arrayResp[0]?.emailSecond;

    getEmail(firstEmail);
    getEmail(secondEmail);
    getEmail(currentUser?.email);

    let body = `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>Solicitud de recolección</title>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <style>
    table, td, div, h1, p {font-family: Arial, sans-serif;}
    </style>
    </head>
    <body style="margin:0;padding:0;">
    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
    <td align="center" style="padding:0;">
      <table role="presentation" style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
          <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
            <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
          </td>
        </tr>
        <tr>
          <td style="padding:5px 25px 0px 25px;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;">
                  <h1 style="text-align: center">Notificación de recolección de vehículos</h1>
  
                  <p>Hola departamento de vehículo,<br/><br/> Envio ordenes de recolección del cliente: ${
                    arrayResp[0]?.clienteName
                    } bajo la importadora: ${
                      arrayResp[0]?.importadoraName
                    } con los siguientes datos:<br/></p>
                  
                  <p>
                    <strong>Numero de contacto del Cliente:</strong> ${
                      arrayResp[0]?.contCli
                    } <br/> <br/>
                    <strong>Buyer:</strong> 
                    <ul>
                    ${
                      uniqueBuyer.map((item) => {
                        return `<li> 
                        <strong> Buyer:</strong> <span>${item?.buyer}</span> <strong> Lote:</strong> <span>${item?.lote}</sapn> 
                        </li>`;
                      } 
                      )
                    } </ul>
                    <br/> <br/>
                    <strong>Comentario:</strong> 
                    <ul>
								    ${arrayResp.map((item) => {
                      return `<li> 
                      <strong> Lote:</strong> <span>${item?.lote}</span> <strong> Vehículo:</strong> <span>${item?.vehiculoName}</sapn>  <strong>Comentarios: </strong> <span>${item?.comentarios}</span> 
                          ${item?.vehiculo?.title ? `  <br /> <span><strong>Titulo: </strong><small> ${item?.vehiculo?.title?.titleName}</small> <strong>Estado: </strong> <small>${item?.vehiculo?.title?.state}</small></span>` : ''}
                      </li>`;
                    })}
                    </ul>
                    <br/>
                    <strong>Asesor/a:</strong> ${
                      arrayResp[0]?.creador
                    } <br/> <br/>
                  </p> 
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:10px;">
            <table  style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    
              <tr>
                <td style="width:260px">
                  <strong>Subasta</strong>
                </td>
                <td style="width:200px">
                  <strong>Lote</strong>
                </td>
                <td style="width:200px">
                  <strong>GatePass</strong>
                </td>              
                <td style="width:260px">
                  <strong>Origen</strong>
                </td>              
                <td style="width:260px">
                  <strong>Almacen</strong>
                </td>
                <td style="width:100px">
                  <strong>Grua</strong>
                </td>
                <td style="width:100px">
                  <strong>Flete</strong>
                </td>
                <td style="width:100px">
                  <strong>BL</strong>
                </td>
                <td style="width:100px">
                  <strong>Otros Cargos</strong>
                </td>
                <td style="width:260px">
                  <strong>Metodo de pago</strong>
                </td>
                <td style="width:100px">
                  <strong>Total</strong>
                </td>
              </tr>
              ${arrayResp.map((item) => {
                return `<tr>
                <td style="width:260px">
                  ${item?.subName}
                </td>
                <td style="width:200px">
                  ${item?.lote}
                </td>
                <td style="width:200px">
                  ${item?.gatePass}
                </td>
                <td style="width:260px">
                  ${item?.nameOrigen}
                </td>
                <td style="width:260px">
                  ${item?.aduanaName}
                </td>
                <td style="width:100px">
                  ${item?.n2}
                </td>
                <td style="width:100px">
                  ${item?.n1}
                </td>
                <td style="width:100px">
                  ${item?.n3}
                </td>
                <td style="width:100px">
                  ${item?.n4}
                </td>
                <td style="width:260px">
                  ${item?.tipoPago}
                </td>
                <td style="width:100px">
                ${item?.total}
              </td>
                </tr>`;
              })}
            </table>
          </td>
        </tr>
        <br/> <br/>
        <tr> 
          <td style="padding:30px;background:#1C2E51;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
              <tr>
                <td style="padding:0;width:50%;" align="left">
                  <p style="color:white;">&reg; Matus International, 2023</p>
                </td>
                <td style="padding:0;width:50%;" align="right">
                  <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="padding:0 0 0 10px;width:38px;">
                      <a href="https://www.facebook.com/matusintl/"><img src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.instagram.com/matusintl/?hl=en"><img src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                      </td>
                      <td style="padding:0 0 0 10px;width:38px;">
                        <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
    </tr>
    </table>
    </body>
    </html>`;
    let bodyEmail = {
      to: [environment.emails.grua], //bodeagruas
      cc: arrayCC, // cliente, usuario,
      cco: [],
      subject: `NOTIFICACIÓN DE RECOLECCIÓN DE VEHÍCULOS LOTES: ${arrayResp.map((item) => {
        return item?.lote + ", ";
      })} SUBASTA: ${arrayResp[0]?.subName} DESTINO: ${
        arrayResp[0]?.aduanaName
      }`,
      body: body,
      attachedFile: arrayAttachments,
    };

    const respEmail = await enviarEmail(bodyEmail);
    console.log("Respuesta Email", respEmail);

    await dispatch(deleteAllSolicitud([]));
    await dispatch(deleteAllAttachments([]));
    toast.success(`Se han guardado todas las solicitudes con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    await dispatch(loading(false));
  };
};

// Coordinacion
export const sendAllCoordinations = (coordinations) => {
  return async (dispatch) => {
    await dispatch(addPushAllCoordinations(coordinations));
    toast.success(`Se han añadido una nueva coordinacion!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const deleteOneCoordination = (indexPosition) => {
  return async (dispatch, getState) => {
    const { allCoordinations } = getState().solicitud;
    const newCoordinations = allCoordinations.filter(
      (item, index) => index !== indexPosition
    );
    await dispatch(deleteOneCoordinations(newCoordinations));
    toast.success(`Se ha eliminado la coordinacion con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const deleteAllCoordination = () => {
  return async (dispatch) => {
    await dispatch(deleteAllCoordinations([]));
    toast.success(`Se han eliminado todas las coordinaciones con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const saveAllCoordination = (arrayCoordination) => {
  return async (dispatch) => {
    let arrayResp = [];
    for (const item of arrayCoordination) {
        try {
            const resp = await addCoor(item);
            console.log("Respuesta Coord:", resp);
            arrayResp.push(resp);
        } catch (error) {
            console.error("Error processing item:", error);
        }
    }
   
    await dispatch(addCoordinationSave(arrayResp));
    await dispatch(deleteAllCoordinations([]));
    toast.success(`Se han guardado todas las coordinaciones con exito!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
};

export const getAllCoordination = () => {
  return async (dispatch) => {
    const resp = await getAllCoordinations();
    console.log("Respuesta", resp);
    if (resp.data) {
      await dispatch(getAllCoordinations(resp.data));
    }
  };
};
