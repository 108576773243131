import React from "react";

const PdfViewer = ({ base64 }) => {
  const height = window.innerHeight - 300;
  if (!base64) return <></>

  return(
    <>
      <iframe
        src={`data:application/pdf;base64,${base64}`}
        width="100%"
        height={height}
        title="pdf"
      />
    </>
  );
}

export default PdfViewer;
