import {Select} from "antd";
import React, {useEffect, useState} from "react";

const SelectYear = ({ year, selectYear }) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    initYears();
  }, []);

  const initYears = () => {
    // Obtener el año actual
    const currentYear = new Date().getFullYear();

    const years = Array.from(
      {length: 3}, // Cambia el número si deseas más años hacia el futuro
      (_, index) => currentYear - index
    ).reverse();

    // Si el año actual no está en la lista, agrégalo
    if (!years.includes(currentYear)) {
      years.push(currentYear);
    }

    setYears(years);
  }

  return(
    <Select value={year}
            className="w-100"
            onChange={value => selectYear(value)}
            options={years.map(year => ({value: year, label: year}))} />
  );
}

export default SelectYear;
