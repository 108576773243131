import { useEffect, useState } from "react";
import { getDecodedToken } from "../Servicios/AuthService";
import type {AuthModel} from "../shared/models/auth.model";

function useCurrentUser(): AuthModel {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        try {
            setCurrentUser(getDecodedToken());
        } catch (ex) {
            localStorage.clear();
            document.location.reload();
        }
    }, []);
    
    // username
    // fullName
    // email
    return currentUser;
}

export default useCurrentUser;