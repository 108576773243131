import {getToken} from "./AuthService";

export const ApiService = {
  get: async (endpoint, attr = {}) => {
    const init = {
      ...attr,
      method: 'GET',
      headers: {
        "accept": '*/*',
        "Content-type": 'application/json',
        "Authorization": `Bearer ${getToken()}`
      },
    }

    return fetch(endpoint, init);
  },

  post: async (endpoint, body, attr = {}) => {
    const init = {
      ...attr,
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        "accept": '*/*',
        "Content-type": 'application/json',
        "Authorization": `Bearer ${getToken()}`
      },
    }

    return fetch(endpoint, init);
  },

  put: async (endpoint, body = null, attr = {}) => {
    const headers = {
      "accept": '*/*',
      "Content-type": 'application/json',
      "Authorization": `Bearer ${getToken()}`
    };

    const init = body === null
      ? { ...attr, method: 'PUT', headers: headers }
      : { ...attr, method: 'PUT', headers: headers, body: JSON.stringify(body) }

    return fetch(endpoint, init);
  },

  delete: async (endpoint, attr = {}) => {
    const init = {
      ...attr,
      method: 'DELETE',
      headers: {
        "accept": '*/*',
        "Content-type": 'application/json',
        "Authorization": `Bearer ${getToken()}`
      },
    }

    return fetch(endpoint, init);
  },
}
