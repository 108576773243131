import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import {faPenToSquare, faLeftLong, faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {showCountries} from '../Servicios/CombosService';
import {showAduanas, addAduana, editAduana, viewAduana} from '../Servicios/ComponedoresService';
import Col from 'react-bootstrap/Col';
import {Container} from 'react-bootstrap';

const Aduanas = () => {

  const [aduan, setAduan] = useState([]);
  const [countries, setCountries] = useState([]);

  const [showA, setShowA] = useState(false);
  const [showE, setShowE] = useState(false);

  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const handleCloseE = () => setShowE(false);
  const handleShowE = () => setShowE(true);

  const [idad, setIdad] = useState([]);

  const mostrarAduanas = async () => {
    const data = await showAduanas();
    setAduan(data);
  }

  const loadCountries = async () => {
    const data = await showCountries();
    setCountries(data);
  }

  useEffect(() => {
    mostrarAduanas();
    loadCountries();
  }, [])

  useEffect(() => {
    mostAdu(idad);
  }, [idad])

  const mostAdu = (id) => {
    setIdad(id)
    if (id !== undefined && id !== 0)
      viewAduana(id).then((aduana) => {
        setAddAduanas(aduana)
        handleShowE();
      })
  }

  const [addAduanas, setAddAduanas] = useState({
    id: "",
    nameAduana: "",
    countryId: "",
    code: ""
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setAddAduanas({
      ...addAduanas,
      [name]: value
    });
  }

  const insertarAduana = async () => {
    try {
      const data = await addAduana({
        ...addAduanas,
        countryId: +addAduanas.countryId,
      });
      if (data) {
        handleCloseA();
        mostrarAduanas();
      }

    } catch (ex) {
      console.log(ex);
    }
  }

  const editarAdu = async () => {
    try {
      const data = await editAduana(idad, {
        ...addAduanas,
        countryId: +addAduanas.countryId,
      });
      if (data) {
        handleCloseE();
        mostrarAduanas();
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  const columns = [
    {
      name: 'Editar',
      cell: (row) => <Button value={row.id} onClick={() => mostAdu(row?.id)} variant="primary">
        <FontAwesomeIcon icon={faPenToSquare}/>
      </Button>
    },
    {
      name: 'Aduana',
      selector: row => row.nameAduana,
      width: "200px"
    },
    {
      name: 'Código',
      selector: row => row.code,
      width: "200px"
    },
    {
      name: 'País',
      selector: row => row.country.nameCountry
    }
  ]

  return (
    <Container>
      <Col md={{span: 8, offset: 1}}>
        <Button as={Link} to='/home' variant="warning">
          <FontAwesomeIcon icon={faLeftLong}/>
          &nbsp;Regresar
        </Button>
        {' '}&nbsp;&nbsp;&nbsp;
        <Button onClick={handleShowA} variant="primary">
          <FontAwesomeIcon icon={faFileCirclePlus}/>
          &nbsp;Agregar Aduana
        </Button>
        {' '}&nbsp;
        <hr/>
        <h2>Visualizando Aduanas</h2>
        <DataTable
          columns={columns}
          data={aduan}
          pagination
        />

        <>
          <Modal
            className='Editar'
            show={showE}
            onHide={handleCloseE}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Editando Aduana</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Seleccione el Pais de la Aduana</Form.Label>
              <Form.Select onChange={handleChange} name='countryId' value={addAduanas?.countryId}>
                <option disabled selected>Seleccione</option>
                {
                  countries && countries.length && countries.map((countries, index) => {
                    return <option key={index} value={countries.id}>{countries.nameCountry}</option>
                  })
                }
              </Form.Select>
              <Form.Label>Nombre de la Aduana</Form.Label>
              <Form.Control onChange={handleChange} name='nameAduana' value={addAduanas?.nameAduana} type="text"
                            placeholder="Nombre Aduana"/>
              <Form.Label>Código</Form.Label>
              <Form.Control onChange={handleChange} name='code' value={addAduanas?.code} type="text"
                            placeholder="Código"/>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseE}>
                Cancelar
              </Button>
              <Button onClick={() => editarAdu()} variant="primary">Actualizar</Button>
            </Modal.Footer>
          </Modal>
        </>

        <>
          <Modal
            className='Agregar'
            show={showA}
            onHide={handleCloseA}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Agregar una Nueva Aduana</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Seleccione el Pais de la Aduana</Form.Label>
              <Form.Select onChange={handleChange} name='countryId' aria-label="Default select example">
                <option disabled selected>Seleccione</option>
                {
                  countries && countries.length && countries.map((countries, index) => {
                    return <option key={index} value={countries.id}>{countries.nameCountry}</option>
                  })
                }
              </Form.Select>
              <Form.Label>Nombre de la Aduana</Form.Label>
              <Form.Control onChange={handleChange} name='nameAduana' type="text" placeholder="Nombre Aduana"/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseA}>
                Cancelar
              </Button>
              <Button onClick={() => insertarAduana()} variant="primary">Guardar</Button>
            </Modal.Footer>
          </Modal>
        </>

      </Col>
    </Container>
  )
}

export default Aduanas
