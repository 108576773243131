const roles = {
  ADMIN: 'ADMIN',

  VENTAS: 'VENTAS',
  VENTAS_SV: 'VENTAS-SV',
  VENTAS_GT: 'VENTAS-GT',
  VENTAS_CR: 'VENTAS-CR',
  VENTAS_HN: 'VENTAS-HN',

  CAOFFICE: 'CAOFFICE',
  FLOFFICE: 'FLOFFICE',

  RECIVING_CA: 'RECIVING-CA',
  RECIVING_FL: 'RECIVING-FL',

  COBROS: 'COBROS',
  VEHICULOS: 'VEHICULOS',
  SUPERVISOR: 'SUPERVISOR',

  ATENCION_CLIENTE: 'ATENCION-AL-CLIENTE',
}

export default roles;
