import {Button, Dropdown, Modal, Table} from "antd";
import {DetalleExportacionStateEnum} from "../../shared/enums/detalle-exportacion-state.enum";
import {
  getBillOfLading, getCartaEndoso,
  getCoverLetter,
  getItn,
  getNotificacionArribo,
  getNotificacionEmbarque, updateDetalleExportConsignatarioFinal, updateStatusDetailExportation
} from "../../Servicios/ExportationServices";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {useState} from "react";
import useCurrentUser from "../../Hooks/UserHook";
import PdfReport from "../../utils/pdfReport";
import {useNavigate} from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {exportationContainerStateEnum} from "../../shared/enums/exportation-container-state.enum";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import SendEmail from "../../utils/email";
import {NotificacionEmbarqueEmail} from "../../Resources/HtmlEmailBody/NotificacionEmbarque.email";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import {CountryCodeEnum} from "../../shared/enums/country-code.enum";
import {NotificacionArriboEmail} from "../../Resources/HtmlEmailBody/NotificacionArribo.email";
import InputSearchImportadora from "../../Componentes/InputSearchImportadora/InputSearchImportadora";
import Spinner from "../../shared/spinner";
import ImportadoraForm from "../../Componentes/ImportadoraForm/ImportadoraForm";
import ConsignatarioFinalForm from "../../Componentes/ConsignatarioFinalForm/ConsignatarioFinalForm";

const DetalleExportationTable = ({ detalleExportations, onRefreshTable }) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const [emailCc, setEmailCc] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");

  const [importadora, setImportadora] = useState(undefined);
  const [detalleExport, setDetalleExport] = useState(undefined);
  const [importadoraEdit, setImportadoraEdit] = useState(undefined);

  const [showAduanaEstadoModal, setShowAduanaEstadoModal] = useState(false);
  const [showArriboModalEmail, setShowArriboModalEmail] = useState(false);
  const [showEmbarqueModalEmail, setShowEmbarqueModalEmail] = useState(false);
  const [importadoraFinalEdit, setImportadoraFinalEdit] = useState(false);

  const items = element => {
    const items = [
      {
        key: '1',
        label: 'Generar Cover Letter',
        onClick: () => handleCoverLetter(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_COVER_LETTER)
      },
      {
        key: '2',
        label: 'Generar Bill of Lading',
        onClick: () => handleBillOfLading(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_BILL_LADING)
      },
      {
        key: '3',
        label: 'Send to AES',
        onClick: () => navigate(`/sendToAES/${element.codeExpSecuential}`),
        visible: element.stateSend === DetalleExportacionStateEnum.NoEnviado &&
          element.exportationContainer?.state !== exportationContainerStateEnum.creado
      },
      {
        key: '4',
        label: 'Validar ITN',
        onClick: () => handleValidarITN(element),
        visible: element.stateSend === DetalleExportacionStateEnum.Enviado
      },
      {
        key: '5',
        label: 'Enviar Notificación de Embarque',
        onClick: () => handleNotificacionEmbarque(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_ENVIAR_NOTIFICACION_EMBARQUE)
      },
      {
        key: '6',
        label: 'Enviar Notificación de Arribo',
        onClick: () => handleNotificacionArribo(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_ENVIAR_NOTIFICACION_ARRIBO)
      },
      {
        key: '7',
        label: 'Cambiar Consignatario del Cover Letter',
        onClick: () => handleModificarImportadora(element),
        visible: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER, permisos.DETALLE_EXPORT_MODIFICAR_CONSIGNATARIO) &&
          element?.exportationContainer?.state === exportationContainerStateEnum.asignado &&
          element?.stateSend === DetalleExportacionStateEnum.Aceptado
      },
      {
        key: '8',
        label: 'Modificar Información del Consignatario',
        onClick: () => handleModificarImportadoraEdit(element),
        visible: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER, permisos.DETALLE_EXPORT_MODIFICAR_CONSIGNATARIO) &&
          element?.exportationContainer?.state === exportationContainerStateEnum.asignado &&
          element?.stateSend === DetalleExportacionStateEnum.Aceptado
      },
      {
        key: '9',
        label: 'Consultar estado de envio a Aduana',
        onClick: () => handleMostrarEstadoAduana(element),
        visible: element?.stateSend === DetalleExportacionStateEnum.Enviado
      },
      {
        key: '10',
        label: 'Modificar Consignatario Final',
        onClick: () => handleModificarImportadoraFinalEdit(element),
        visible: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER, permisos.DETALLE_EXPORT_MODIFICAR_CONSIGNATARIO) &&
          element?.exportationContainer?.state === exportationContainerStateEnum.asignado &&
          element?.stateSend === DetalleExportacionStateEnum.Aceptado &&
          element?.exportationContainer?.aduana?.country?.code === CountryCodeEnum.GT
      },
      {
        key: '11',
        label: 'Generar Carta de Endoso',
        onClick: () => handleCartaEndoso(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_CARTA_ENDOSO_GT) &&
          element?.exportationContainer?.aduana?.country?.code === CountryCodeEnum.GT
      },
    ];

    return items.filter(e => e.visible);
  }

  const columns = [
    {
      title: "",
      width: "7%",
      dataIndex: "options",
      key: "options",
      render: (_, element) => (
        <ShowWhen show={items(element).length > 0}>
          <Dropdown
            trigger={['click']}
            menu={{ items: items(element) }}
            placement="bottomLeft"
          >
            <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
          </Dropdown>
        </ShowWhen>
      ),
    },
    {
      title: "Shipment #",
      width: "12%",
      dataIndex: "codeExpSecuential",
      key: "codeExpSecuential",
      render: (value) =><p className="font-12 mb-0">{value}</p>
    },
    {
      title: "Warehouse",
      width: "12%",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (_, element) => {
        return(
          <>
            { element?.werehouse?.hasVehicle && <p className="font-12 mb-0">Lote: {element?.werehouse?.lote}</p> }
            <p className="font-12 mb-0">{element?.werehouse?.codigo}</p>
          </>
        );
      },
    },
    {
      title: "Vehículo",
      width: "16%",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (_, element) => {
        if (element?.werehouse?.hasVehicle)
          return(
            <>
              <p className="font-12 mb-0">VIN: {element?.vehiculo?.vin}</p>
              <p className="font-12 mb-0">{element?.vehiculo?.vehiculoName}</p>
            </>
          )

        return(
          <>
            <p className="font-12 mb-0">{element?.werehouse?.extraItems?.[0]?.extraItemName}</p>
            <p className="font-12 mb-0">(x{element?.werehouse?.extraItems?.[0]?.quantity})</p>
          </>
        )
      },
    },
    {
      title: "Importadora",
      width: "14%",
      dataIndex: "carrier",
      key: "carrier",
      render: (_, element) => {
        return(<>
          <ShowWhen show={element?.importadora?.identf}>
            <p className="font-12 mb-0 text-uppercase">
              {element?.importadora?.identfType}: {element?.importadora?.identf}
            </p>
          </ShowWhen>
          <p className="font-12 mb-0 text-uppercase">{element?.importadora?.impName}</p>
        </>)
      }
    },
    {
      title: "#",
      width: "16%",
      dataIndex: "containerNumber",
      key: "containerNumber",
      render: (_, element) => {
        return(
          <>
            <p className="font-12 mb-0"><span className="fw-bold"># de Contenedor: </span>{element?.exportationContainer?.containerNumber}</p>
            <p className="font-12 mb-0"><span className="fw-bold"># de Sello: </span>{element?.exportationContainer?.sealNumber}</p>
          </>
        );
      },
    },
    {
      title: "ITN",
      width: "13%",
      dataIndex: "itn",
      key: "itn",
      render: (_, element) => <p className="font-12 mb-0">{element?.itn}</p>,
    },
    {
      title: "Estado",
      width: "10%",
      dataIndex: "estado",
      key: "estado",
      render: (_, element) => {
        const bg = element?.stateSend === DetalleExportacionStateEnum.Enviado
          ? 'bg-info'
          : element?.stateSend === DetalleExportacionStateEnum.Aceptado
            ? 'bg-success'
            : 'bg-danger';

        return <div className={'badge ' + bg}>
          {element?.stateSend}
        </div>
      },
    }
  ];

  const handleValidarITN = async (element) => {
    setLoading(true);
    const data = await getItn(element.codeExpSecuential);
    setLoading(false);

    if (data && data.status) {
      successMessage('ITN actualizado exitosamente');
      onRefreshTable();
    } else {
      errorMessage(data.message);
    }
  }

  const handleBillOfLading = async (element) => {
    setVisible(true);
    const res = await getBillOfLading(element.codeExpSecuential);
    setDataReport(res);
  }

  const handleCartaEndoso = async (element) => {
    setVisible(true);
    const res = await getCartaEndoso(element.codeExpSecuential);
    setDataReport(res);
  }

  const handleCoverLetter = async (element) => {
    setVisible(true);
    const res = await getCoverLetter(element.codeExpSecuential, currentUser);
    setDataReport(res);
  }

  const handleNotificacionEmbarque = async (element) => {
    try {
      setVisible(true);
      const res = await getNotificacionEmbarque(element.codeExpSecuential);
      setVisible(false);

      if (element.werehouse.country.code === CountryCodeEnum.SV) {
        setEmailCc("operacion@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.HN) {
        setEmailCc("honduras@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.CR) {
        setEmailCc("operacioncr@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.GT) {
        setEmailCc("Guatemala@matusintl.com");
      }

      setDataReport(res);
      setDetalleExport(element);
      setShowEmbarqueModalEmail(true);
    } catch (ex) {
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleNotificacionArribo = async (element) => {
    try {
      setVisible(true);
      const res = await getNotificacionArribo(element.codeExpSecuential);
      setVisible(false);

      if (element.werehouse.country.code === CountryCodeEnum.SV) {
        setEmailCc("operacion@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.HN) {
        setEmailCc("honduras@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.CR) {
        setEmailCc("operacioncr@matusintl.com");
      } else if (element.werehouse.country.code === CountryCodeEnum.GT) {
        setEmailCc("Guatemala@matusintl.com");
      }

      setDataReport(res);
      setDetalleExport(element);
      setShowArriboModalEmail(true);
    } catch (ex) {
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleModificarImportadora = detalleExport => {
    setImportadora(detalleExport?.coverLetterConsignee ? detalleExport?.coverLetterConsignee : detalleExport?.werehouse?.importadora);
    setDetalleExport(detalleExport);
  }

  const handleModificarImportadoraEdit = detalleExport => {
    setImportadoraEdit(detalleExport?.coverLetterConsignee ? detalleExport?.coverLetterConsignee : detalleExport?.werehouse?.importadora);
    setDetalleExport(detalleExport);
  }

  const handleModificarImportadoraFinalEdit = detalleExport => {
    setImportadoraFinalEdit(true);
    setDetalleExport(detalleExport);
  }

  const handleMostrarEstadoAduana = detalleExport => {
    setDetalleExport(detalleExport);
    setShowAduanaEstadoModal(true);
  }

  const handleOcultarEstadoAduana = () => {
    setDetalleExport(undefined);
    setShowAduanaEstadoModal(false);
  }

  const handleSelectImportadora = async (importadora) => {
    const input = {
      ...detalleExport,
      werehouseId: detalleExport?.werehouse?.werehouseId,
      letter: detalleExport?.exportationContainer?.letter,
      consigneeId: importadora?.id
    };

    setLoading(true);
    await updateStatusDetailExportation(input, detalleExport?.detalleExportId)
    setLoading(false);

    successMessage('Importadora actualizada con exito');
    setImportadora(undefined);
    setDetalleExport(undefined);
    onRefreshTable();
  }

  const handleConsignatarioFinal = async (values) => {
    try {
      setLoading(true);
      await updateDetalleExportConsignatarioFinal(detalleExport?.detalleExportId, values);
      setLoading(false);

      setDetalleExport(undefined);
      setImportadoraFinalEdit(false);
      if (onRefreshTable) onRefreshTable();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Table size={'small'}
             loading={loading}
             columns={columns}
             dataSource={detalleExportations}
             pagination={false} />
      <PdfReport visible={visible} setVisible={setVisible} data={dataReport} setData={setDataReport} />
      <ShowWhen show={showEmbarqueModalEmail && detalleExport}>
        <SendEmail base64Pdf={dataReport}
                   visible={showEmbarqueModalEmail}
                   setVisible={() => {
                     setDataReport("");
                     setDetalleExport(undefined);
                     setShowEmbarqueModalEmail(false);
                   }}
                   titulo={`Enviar Notificación de Embarque`}
                   to={[detalleExport?.werehouse?.emailCli]}
                   cc={emailCc === "" ? [] : [emailCc]}
                   cco={[]}
                   isLoading={loading}
                   subject={`Aviso de Embarque Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`}
                   body={NotificacionEmbarqueEmail(detalleExport?.codeExpSecuential, detalleExport?.aduana?.countryName, detalleExport?.werehouse?.country?.code)}
                   showMessageInput={false}
                   attachments={[
                     {
                       nombreArchivo: `aviso_embarque_${detalleExport?.codeExpSecuential ?? ""}.pdf`,
                       archivo: "data:aplication/pdf;base64," + dataReport,
                     }
                   ]}
                   doSendEmail={() => {
                     setDataReport("");
                     setDetalleExport(undefined);
                   }}
        />
      </ShowWhen>
      <ShowWhen show={showArriboModalEmail && detalleExport}>
        <SendEmail base64Pdf={dataReport}
                   visible={showArriboModalEmail}
                   setVisible={() => {
                     setDataReport("");
                     setDetalleExport(undefined);
                     setShowArriboModalEmail(false);
                   }}
                   titulo={`Enviar Notificación de Arribo`}
                   to={[detalleExport?.werehouse?.emailCli]}
                   cc={emailCc === "" ? [] : [emailCc]}
                   cco={[]}
                   isLoading={loading}
                   subject={`Aviso de Arribo Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`}
                   body={NotificacionArriboEmail(detalleExport?.codeExpSecuential, detalleExport?.werehouse?.country?.code)}
                   showMessageInput={false}
                   attachments={[
                     {
                       nombreArchivo: `aviso_arribo_${detalleExport?.codeExpSecuential ?? ""}.pdf`,
                       archivo: "data:aplication/pdf;base64," + dataReport,
                     }
                   ]}
                   doSendEmail={() => {
                     setDataReport("");
                     setDetalleExport(undefined);
                   }}
        />
      </ShowWhen>
      <Modal open={!!importadora}
             title={'Modificar Consignatario'}
             onCancel={() => {
               setImportadora(undefined);
               setDetalleExport(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <br/>
        <Spinner loading={loading}>
          <InputSearchImportadora selectedImportadora={importadora}
                                  onSelectImportadora={handleSelectImportadora}/>
        </Spinner>
      </Modal>
      <Modal open={!!importadoraEdit}
             title={'Modificar Consignatario'}
             onCancel={() => {
               setDetalleExport(undefined);
               setImportadoraEdit(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm importadora={importadoraEdit}
                         afterSubmit={() => {
                           setDetalleExport(undefined);
                           setImportadoraEdit(undefined);
                           if (onRefreshTable) onRefreshTable();
                         }}
                         onCancel={() => {
                           setDetalleExport(undefined);
                           setImportadoraEdit(undefined);
                         }} />
      </Modal>
      <Modal open={!!importadoraFinalEdit}
             title={'Modificar Consignatario Final'}
             onCancel={() => {
               setDetalleExport(undefined);
               setImportadoraFinalEdit(false);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ConsignatarioFinalForm consigneeName={detalleExport?.consigneeName}
                                consigneeAddress={detalleExport?.consigneeAddress}
                                consigneeIdentification={detalleExport?.consigneeIdentification}
                                afterSubmit={(values) => {
                                  handleConsignatarioFinal(values).then();
                                }}
                                onCancel={() => {
                                  setDetalleExport(undefined);
                                  setImportadoraFinalEdit(false);
                                }} />
      </Modal>
      <ShowWhen show={detalleExport && showAduanaEstadoModal}>
        <Modal open={showAduanaEstadoModal}
               width={'90%'}
               onCancel={handleOcultarEstadoAduana}
               onOk={handleOcultarEstadoAduana}
               title={`AES STATUS ${detalleExport?.codeExpSecuential}`}>
          <iframe width={'100%'}
                  title={'Status'}
                  height={'400px'}
                  src={`https://aes.gsf24.com/aesstatus.aspx?customercode=MATUS&hbl=${detalleExport?.codeExpSecuential}`}></iframe>
        </Modal>
      </ShowWhen>
    </>
  );
}

export default DetalleExportationTable;
