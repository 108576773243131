import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getExportationContainer = async (createdDate = 0, loadingDate = 0, currentPage = 0,
                                              pageCount = 0, state = "", aduanaId = 0, carrierId = 0, origenId = 0,
                                              fromDate = 0, toDate = 0, countryId = 0, lote = "") => {
  const params = {
    createdDate: createdDate,
    loadingDate: loadingDate,
    currentPage: currentPage,
    pageCount: pageCount,
    aduanaId: aduanaId,
    carrierId: carrierId,
    origenId: origenId,
    fromDate: fromDate,
    toDate: toDate,
    countryId: countryId
  };

  if (state !== "") params['state'] = state;
  if (lote !== "") params['lote'] = lote;

  const queryParams = new URLSearchParams(params).toString();

  const response = await ApiService.get(`${server}exportationContainer?${queryParams}`);
  const result = await response.json();

  if (result.status)
    return result;
  throw new Error(result.message);
}

export const getExportationContainerById = async (id) => {
  const response = await ApiService.get(`${server}exportationContainer/${id}`);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const addExportationContainer = async (exportationContainer) => {
  const response = await ApiService.post(`${server}exportationContainer`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainer = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainerLoaded = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}/loaded`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}
