import React from "react";
import { Card } from "antd";
import WeatherDisplay from "../../../Componentes/widgets/weatherDisplay";

const WelcomeSection = ({ user }) => {
  const fullNamesCapitalized =
    user?.fullName &&
    (user?.fullName || "")
      .split(" ")
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
      .join(" ");

  return (
    <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", width: "100%" }}>
      {/* Buenas tardes */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <WeatherDisplay />
        </div>
        <div>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <h1 style={{ textAlign: "right", margin: "0px" }}>
              Bienvenido {fullNamesCapitalized}
            </h1>
            <strong style={{ textAlign: "right", margin: "0px" }}>
              {user?.ubication} - {user?.email}
            </strong>
          </span>
        </div>
      </div>
    </Card>
  );
};

export default WelcomeSection;
