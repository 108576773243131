import {Modal} from "antd";
import React from "react";

const ConfirmDialog = ({ open, message, onOk, onCancel }) => {
  return(
    <>
      <Modal open={open}
             title={'Confirmar'}
             onOk={onOk}
             onCancel={onCancel}>
        {message}
      </Modal>
    </>
  );
}

export default ConfirmDialog;
