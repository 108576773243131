import Spinner from "../../shared/spinner";
import React, {useEffect, useState} from "react";
import {Button, Modal, Table} from "antd";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import CarrierForm from "./CarrierForm";
import {getListCarrier} from "../../Servicios/ExportationServices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

const CarrierPage = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [carrier, setCarrier] = useState(undefined);
  const [carrierList, setCarrierList] = useState([]);
  const [filteredCarrierList, setFilteredCarrierList] = useState([]);

  const columns = [
    {
      title: '',
      render: (_, element) => {
        return <Button type={'primary'}
                       size={'small'}
                       onClick={() => {
                         setOpenModal(true);
                         setCarrier(element);
                       }}>
          <FontAwesomeIcon icon={faPencil}/>
        </Button>
      }
    },
    { title: 'Code', dataIndex: 'oceanCarrierCode', key: 'oceanCarrierCode' },
    { title: 'Sac Code', dataIndex: 'oceanCarrierSacCode', key: 'oceanCarrierSacCode' },
    { title: 'Name', dataIndex: 'oceanCarrierName', key: 'oceanCarrierName' },
    {
      title: 'Apply Shipping Instruction', dataIndex: 'applyShippingInstruction', key: 'applyShippingInstruction',
      render: value => value ? <div className="badge bg-success">SI</div> : <div className="badge bg-danger">NO</div>
    }
  ];

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    setLoading(true);
    const data = await getListCarrier();
    setLoading(false);

    setCarrierList(data);
    setFilteredCarrierList(data);
  }

  const handleInputSearch = (event) => {
    const data = carrierList
      .filter(e => event === "" || `${e.oceanCarrierName}`.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilteredCarrierList(data);
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <h5 className="m-0">Carrier</h5>
          <Button type="primary" onClick={() => setOpenModal(true)}>Add new Carrier</Button>
        </div>

        <div className="mb-3">
          <InputSearch placeholder={'Search for carrier'}
                       doChange={handleInputSearch}/>
        </div>

        <Table dataSource={filteredCarrierList}
               columns={columns}/>

        <Modal open={openModal}
               loading={loading}
               title={carrier ? 'Modificar Carrier' : 'Crear Carrier'}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}
               onCancel={() => {
                 setCarrier(undefined);
                 setOpenModal(false);
               }}>
          <CarrierForm carrrier={carrier}
                       onSave={() => {
                         setCarrier(undefined);
                         setOpenModal(false);
                         init().then();
                       }} />
        </Modal>
      </Spinner>
    </>
  );
}

export default CarrierPage;
