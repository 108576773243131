import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../hojas-de-estilo/login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faUser, faKey} from '@fortawesome/free-solid-svg-icons';
import logocirc from '../image/logocir.png';
import { login } from '../Servicios/AuthService';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from '../Componentes/LoadingSpinner';


const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');
  const authContext = useAuth();

    const submit = async () => {
      setIsLoading(true);
      setError('');

      login(username, password).then(data => {
        localStorage.setItem('token', data.token);
        authContext.login();
        window.location.href = '/web';
      }).catch(error => {
        console.log(error.message);
        setError('Usuario y contrasena invalida');
        setIsLoading(false);
      });
    }
    
    if (isLoading){
      return (
        <LoadingSpinner/>
      )
    }
else{
    return (
        <div className="modal-dialog text-center bodylogin">
          <div className='col-sm-4 main-section'>
            <div className='modal-content'>
                  <div className='col-12 ini-img'>
                    <img className='imagen-inicio' src={logocirc}/>
                  </div>
                
                  <div className='col-12 form-section'>
                  <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className='text-aling-left'> <FontAwesomeIcon icon={faUser}/> &nbsp; Usuario</Form.Label>
                  <Form.Control name='username' 
                    value={username} 
                    type="email" 
                    placeholder="Ingrese su Usuario" 
                    onChange={(event) => setUsername(event.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                  
                  <Form.Label><FontAwesomeIcon icon={faKey}/> &nbsp; Contraseña</Form.Label>
                  
                  
                  <Form.Control name='password' 
                    type="password" 
                    value={password}
                    placeholder="Ingrese su Contraseña" 
                    onChange={(event)=> setPassword(event.target.value)} />
                    <NavLink exact to="/reset-password">Olvide mi contraseña</NavLink>
                  </Form.Group>
                  
                  <Button variant="primary" type="submit" onClick={submit} >
                   <FontAwesomeIcon icon={faSignIn}/>  Ingresar
                  </Button>
                  
                  <p className='text-danger mt-3'>
                  { error }
                  </p>
                  </Form>
                  </div>
            </div>
           </div>           
		</div>
    );
}
}

export default Login;
