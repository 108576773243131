import {Button, Col, Form, Input, InputNumber, Row, Space, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {editCar} from "../Servicios/VehicleService";
import Spinner from "../shared/spinner";
import {errorMessage} from "../utils/toast-message";

const VehicleReceivingForm = ({ vehicle, onSaveVehicle }) => {
  const [formModal] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const [titulo, setTitulo] = useState(false);
  const [llave, setLlave] = useState(false);

  useEffect(() => {
    if (vehicle) {
      formModal.setFieldsValue({
        year: vehicle.year,
        vehicleName: vehicle.vehiculoName,
        vin: vehicle.vin,
        ubicacion: vehicle.ubicacion,
        lote: vehicle.lote,
      });

      setTitulo(vehicle.titulo);
      setLlave(vehicle.llave);
    }
  }, [vehicle]);

  const editarVehiculo = async (values) => {
    const body = {
      ...vehicle,
      year: values.year,
      ubicacion: values.ubicacion,
      vin: values.vin,
      lote: values.lote,
      storage: values.storage,
      titulo: titulo,
      llave: llave,
    }

    setLoading(true);
    const data = await editCar(vehicle.vehiculoId, body);
    setLoading(false);

    if (data.status) {
      if (onSaveVehicle) {
        onSaveVehicle(body);
      }
    } else {
      errorMessage(data.message);
    }
  };

  const onChangeTitle = (checked: boolean) => {
    setTitulo(checked);
  };

  const onChangeKey = (checked: boolean) => {
    setLlave(checked);
  };

  return(
    <>
      <Spinner loading={isLoading}>
        <Form
          layout={"vertical"}
          form={formModal}
          initialValues={{ layout: "vertical", remember: true }}
          autoComplete="off"
          onFinish={editarVehiculo}
        >
          <Row gutter={[22, 24]}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Year:" name="year" className="w-50">
                <InputNumber disabled={vehicle.year} className="w-50"/>
              </Form.Item>
              <Form.Item label="Vehicle:" name="vehicleName">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Lote:" name="lote">
                <Input />
              </Form.Item>
              <Form.Item label="VIN:" name="vin">
                <Input placeholder="VIN" />
              </Form.Item>
              <Form.Item label="Key Number:" name="ubicacion">
                <Input placeholder="Key Number" />
              </Form.Item>
              <Form.Item label="Storage:" name="storage">
                <Input placeholder="Storage" />
              </Form.Item>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item label="Has Title?">
                    <Switch checked={titulo} onChange={onChangeTitle} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Has Key?">
                    <Switch checked={llave} onChange={onChangeKey} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button
                  block
                  style={{ backgroundColor: "green", color: "white" }}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spinner>
    </>
  );
}

export default VehicleReceivingForm;
