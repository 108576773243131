import Select from "react-select";
import React, {useEffect, useState} from "react";
import {listClients} from "../../Servicios/ClienteService";
import {ClienteTypeEnum} from "../../shared/enums/cliente-type.enum";
import useCurrentUser from "../../Hooks/UserHook";
import {getCountriesId} from "../../Servicios/AuthService";
import './InputSearchCustomer.css';

const InputSearchCustomer = ({ disabled, selectedCustomer, filterByVipCustomer, filterByFinalCustomer, onSelectedCustomer }) => {
  const pageCount = 50;
  const currentUser = useCurrentUser();

  const [clientList, setClientList] = useState([]);
  const [searchName, setSearchName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchNameDebounce, setSearchNameDebounce] = useState("");

  useEffect(() => {
    const countriesId = getCountriesId();

    loadCustomerList(countriesId, currentPage, searchNameDebounce).then();
  }, [currentUser, currentPage, searchNameDebounce, selectedCustomer]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchName !== null) {
        setSearchNameDebounce(searchName);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchName, 500]);

  const loadCustomerList = async (countriesId, currentPage, searchName = "") => {
    setLoading(true);
    const data = await listClients(
      countriesId,
      0,
      searchName !== "" ? 1 : currentPage,
      pageCount,
      searchName,
      filterByFinalCustomer ? ClienteTypeEnum.typeFinalCustomer : "",
      filterByVipCustomer ? 1 : 0,
        0,
        0,
    );
    setLoading(false);

    if (selectedCustomer) {
      const list = data.list;
      if (!clientList.some(e => e.id === selectedCustomer.id)) {
        list.push(selectedCustomer);
      }

      setClientList(currentPage === 1 ? [...list] : [...clientList, ...list]);
    } else {
      setClientList(currentPage === 1 ? [...data.list] : [...clientList, ...data.list]);
    }
  };

  const handleSearchable = (event) => {
    event.preventDefault();
    return true;
  }

  const handleChange = (event) => {
    if (onSelectedCustomer) {
      onSelectedCustomer(event.customer);
    }
  }

  return(
    <>
      <Select name="searchClient"
              isSearchable={handleSearchable}
              onChange={handleChange}
              isLoading={isLoading}
              isDisabled={disabled}
              defaultValue={{ label: "Escriba y Seleccione una opción", value: "empty" }}
              onMenuScrollToBottom={() => setCurrentPage(currentPage + 1)}
              onInputChange={(inputValue) => {
                setCurrentPage(1);
                setSearchName(inputValue);
              }}
              value={!selectedCustomer ? undefined : {
                value: selectedCustomer.id,
                label: `${selectedCustomer.nombreCompleto} - ${selectedCustomer.importadoraName}`.toUpperCase(),
                customer: selectedCustomer
              }}
              options={ clientList && clientList?.length
                ? clientList?.map(client => ({
                  label: `${client.nombreCompleto} - ${client.importadoraName}`.toUpperCase(),
                  value: client.id,
                  customer: client
                }))
                : []
        }
      />
    </>
  );
}

export default InputSearchCustomer;
