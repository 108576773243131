import {Button, Card, Col, Layout, Menu, Modal, Row, Tag, theme} from "antd";
import {useEffect, useState} from "react";
import {errorMessage} from "../../../utils/toast-message";
import ExportationContainerForm from "./ExportationContainerForm";
import Spinner from "../../../shared/spinner";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faPlus} from "@fortawesome/free-solid-svg-icons";
import ExportationContainerTable from "./ExportationContainerTable";
import {getExportationContainer} from "../../../Servicios/ExportationContainerService";
import useCurrentUser from "../../../Hooks/UserHook";
import {getDateForMilliseconds} from "../../../utils/date.helper";
import moment from "moment/moment";
import {getListDateLoadingProgram} from "../../../Servicios/ExportationServices";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import Sider from "antd/es/layout/Sider";
import {ExportationProvider} from "../../../contexts/ExportationContext";

class Filters {
  currentPage: number;
  pageCount: number;
  loadingDate: Date | undefined;
  lote: string;
}

const ExportationContainerPage = () => {
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loadingProgramDates, setLoadingProgramDates] = useState([]);
  const [exportationContainers, setExportationContainers] = useState([]);
  const [updateExportationContainer, setUpdateExportationContainer] = useState(undefined);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [filters, setFilters] = useState({
    currentPage: localStorageFilter('exportationContainer:filter', 'currentPage') ?? 1,
    pageCount: localStorageFilter('exportationContainer:filter', 'pageCount') ?? 10,
    loadingDate: localStorageFilter('exportationContainer:filter', 'loadingDate', true),
    lote: localStorageFilter('exportationContainer:filter', 'lote') ?? "",
  });

  useEffect(() => {
    if (!currentUser) return;
    const localFilters = localStorageFilter();

    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem('exportationContainer:filter', JSON.stringify(filters));
    }

    init(filters).then();
  }, [filters, currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    fetchLoadingProgramDates().then();
  }, [currentUser]);

  const fetchLoadingProgramDates = async () => {
    const list = await getListDateLoadingProgram(currentUser.idubc);
    setLoadingProgramDates(list);
  };

  const init = async (filters: Filters) => {
    try {
      setLoading(true);
      const data = await getExportationContainer(
        0,
        filters.loadingDate ? filters.loadingDate.getTime() : 0,
        filters.currentPage,
        filters.pageCount,
        "",
        0,
        0,
        currentUser?.idubc ?? 0,
        0,
        0,
        0,
        filters.lote
      );
      setLoading(false);

      setTotalElements(data.totalElements);
      setExportationContainers(data.list.map((e, index) => {
        return {
          key: index,
          ...e,
        }
      }));
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  };

  const handleAddExportationContainer = () => {
    setShowForm(true);
  }

  const handleUpdateExportationContainer = (element) => {
    setShowForm(true);
    setUpdateExportationContainer(element);
  }

  const handleSelectDate = async item => {
    const currentDate = getDateForMilliseconds(item?.key);
    setSelectedMenu([item.key]);
    setFilters({
      ...filters,
      loadingDate: currentDate
    });
  };

  const handleUpdate = async () => {
    await fetchLoadingProgramDates();
    await init(filters);
  }

  const handleInputSearch = (search) => {
    setFilters({
      ...filters,
      lote: search,
    });
  }

  return(
    <>
      <ExportationProvider>
        <Layout className="exportation-container-page" style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Sider style={{ background: colorBgContainer }}
                 collapsible
                 width={'180px'}
                 trigger={null}
                 collapsed={collapsed}
                 collapsedWidth={0}
                 onCollapse={(value) => setCollapsed(value)}>
            <p className="pt-3 text-center fw-bolder">
              Fecha de Carga
            </p>

            <Menu theme="light"
                  mode="inline"
                  selectedKeys={selectedMenu}
                  onSelect={handleSelectDate}
                  items={loadingProgramDates.map((item) => {
                    return {
                      key: item.date,
                      label: <div key={item?.date} className="d-flex align-items-center justify-content-between flex-wrap">
                        <p className="mb-0 me-3 fw-bolder">
                          {moment(item.date).format( "MM-DD-YYYY")}
                        </p>
                        <Tag color={"blue"} className="m-0"><p className="mb-0 fw-bold">{item.count}</p></Tag>
                      </div>
                    }
                  })}/>
          </Sider>
          <Layout>
            <Card className="m-1" size={'small'}>
              <Spinner loading={loading}>
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                  <h5 className="mb-3 mb-sm-0 d-flex align-items-center justify-content-start flex-wrap gap-2">
                    <Button type="text"
                            icon={<FontAwesomeIcon icon={faBars} />}
                            onClick={() => setCollapsed(!collapsed)} />
                    Planificación de Contenedores
                  </h5>
                  <ShowWhen show={validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)}>
                    <Button type={'primary'} onClick={handleAddExportationContainer}>
                      <FontAwesomeIcon className="me-2" size={'sm'} icon={faPlus}/> Agregar Contenedor
                    </Button>
                  </ShowWhen>
                </div>
                <div className="mb-3">
                  <InputSearch initialValue={filters.lote} placeholder={'Buscar por lote o vin'}
                               doChange={handleInputSearch}/>
                </div>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ExportationContainerTable filters={filters}
                                               currentUser={currentUser}
                                               totalElements={totalElements}
                                               exportationContainers={exportationContainers}
                                               onUpdate={handleUpdate}
                                               onPageChange={handlePageChange}
                                               fechaSeleccionada={selectedMenu.length > 0 ? moment(selectedMenu[0]).format('MM-DD-yyyy') : undefined}
                                               onUpdateExportationContainer={handleUpdateExportationContainer} />
                  </Col>
                </Row>

                <Modal width={'80%'}
                       open={showForm}
                       title={'Asignar contenedor'}
                       closable={false}
                       okButtonProps={{style: {display: 'none'}}}
                       cancelButtonProps={{style: {display: 'none'}}}>
                  <ExportationContainerForm idOrigen={currentUser?.idubc}
                                            editable={true}
                                            exportationContainer={updateExportationContainer}
                                            onCancel={() => {
                                              setShowForm(false);
                                              setUpdateExportationContainer(undefined);
                                              init(filters).then();
                                            }}
                                            onSubmit={() => {
                                              setShowForm(false);
                                              setUpdateExportationContainer(undefined);
                                              init(filters).then();
                                              fetchLoadingProgramDates().then();
                                            }} />
                </Modal>
              </Spinner>
            </Card>
          </Layout>
        </Layout>
      </ExportationProvider>
    </>
  );
}

export default ExportationContainerPage;
