import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {logout} from "../../Servicios/AuthService";

const GuestGuard = ({children}) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return Navigate({ to: "/login", replace: true });
  } else {
    const decoded = jwtDecode(token);
    if (decoded && decoded.exp) {
      const exp = decoded.exp;

      let expDate = new Date(exp);
      if (exp.toString().length === 10) {
        expDate = new Date(+`${exp}000`);
      }

      if (new Date().getTime() > expDate.getTime()) {
        logout();
        return Navigate({ to: "/login", replace: true });
      }
    }
  }

  return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
}

export default GuestGuard;
