import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {VehiculoFilMakeModelvehi} from '../Servicios/SolicitudesService';
import 'react-toastify/dist/ReactToastify.css';
import {faFloppyDisk} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {decodeVin} from '../Servicios/SolicitudesService';
import Loadingsmall from '../Componentes/Loadingsmall';
import {showOrigen} from '../Servicios/CombosService';
import useCurrentUser from '../Hooks/UserHook';
import {ToastContainer, toast} from 'react-toastify';
import {addCar} from "../Servicios/VehicleService";

const NewVehicle = () => {
  const [validated, setValidated] = useState(false);
  const [msjsuccess, setMsjsuccess] = useState('');
  const [message, setMessage] = useState('');
  const [origen, setOrigen] = useState([]);

  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);

  const currentUser = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);
  // sirve para saber si el formulario fue enviado
  // si le dio click al boton del formulario
  const [isSubmitted, setSubmitted] = useState(false);

  const notify = () => toast.success("Se regitro correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [vinInfo, setVinInfo] = useState({
    decovin: ""
  });

  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  const fechaactual = (`${day}/${month}/${year}`);

  var moment = require('moment');
  var now = moment().format();

  const [newCar, setNewCar] = useState({
    id: "",
    year: 0, // != 0
    make: "", // != ''
    model: "", // != ''
    color: "",
    height: "",
    length: "",
    width: "",
    vin: "",
    weight: "",
    lote: "", // != ''
    fecha: now,
    titulo: false,
    llave: false,
    catalitico: false,
    bio: false,
    estado: "",
    origen: "",
    updateBy: "",
    storage: "",
    notas: "",
    ubicacion: "",
    keyinside: false,
    radio: false,
    llantaResp: false,
    billofSale: false,
    bateria: false,
    cristalTrasero: false,
    cristalDelantero: false,
    ventanaIzquierda: false,
    ventanaDerecha: false,
    openDoor: false,
    openCapo: false,
  })

  const formularioEsValido = () => {
    return newCar.year !== 0 && newCar.make !== '' && newCar.model !== '' && newCar.lote !== '' && newCar.origen != '';
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setNewCar({
      ...newCar,
      [name]: value
    });
    setVinInfo({
      ...vinInfo,
      [name]: value
    });
  }

  useEffect(() => {
    loadOrigen();
    setNewCar({
      ...newCar,
      updateBy: currentUser?.fullName,
      origen: currentUser?.idubc,
      estado: "RECIBIDO_EN_BODEGA",
    })
  }, [currentUser]);

  const ori = currentUser?.idubc;

  const loadFechas = () => {
    const fechaActual = moment();
    const inicio = fechaActual.clone().startOf('month');
    const fin = fechaActual.clone().endOf('month');
    setFechaInicio(inicio.format('YYYY-MM-DD'));
    setFechaFin(fin.format('YYYY-MM-DD'));
  }

  const loadSuggest = async () => {
    if (fechaInicio !== null && fechaFin !== null) {
      try {
        setIsLoading(true);
        const data = await VehiculoFilMakeModelvehi(fechaInicio, fechaFin, ori);
        setVehicleData(data);
      } catch (error) {
        console.error('Error al cargar datos sugeridos:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    loadFechas();
    loadSuggest();
  }, [fechaInicio, fechaFin]);

  console.log(vehicleData);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const loadOrigen = async () => {
    const data = await showOrigen();
    setOrigen(data);
  }

  const insertarCar = async () => {
    setSubmitted(true);
    if (!formularioEsValido()) {
      return;
    }

    try {
      addCar({
        ...newCar,
        year: +newCar.year,
      }).then(() => {
        notify();
        setMsjsuccess("Vehiculo registrado correctamente");
        setTimeout(() => {
        }, 4000);
      }).catch(error => {
        setMessage(error.message);
        setTimeout(() => {
          setMessage(" ");
        }, 4000);
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  const handleOnChangeCK = (evt) => {
    const {name, checked} = evt.target
    setNewCar({
      ...newCar,
      [name]: checked
    });
  }

  return (
    <div>
      <h5>Registro de Vehículos</h5>

      <Form>
        <Row>
          <Col xs={12} lg="3">
            <Form.Label className='text-aling-left'>Fecha</Form.Label>
            <Form.Control value={fechaactual} disabled readOnly aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="3">
            <Form.Label className='text-aling-left'>VIN</Form.Label>
            <Form.Control name='vin' onChange={handleChange} value={newCar?.vin} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="3">
            <Form.Label
              className={`text-aling-left ${isSubmitted && newCar.lote === '' ? 'required' : ''}`}>Lote</Form.Label>
            <Form.Label
              className='required'>*</Form.Label>
            <Form.Control required
                          aria-label="Small"
                          onChange={handleChange}
                          name='lote'
                          className={isSubmitted && newCar.lote === '' ? 'is-invalid' : ''}
                          aria-describedby="inputGroup-sizing-sm"/>
            {
              isSubmitted && newCar.lote === '' &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
            }
          </Col>
        </Row>
        <Row>

          <Col xs={12} lg="3">
            <Form.Label
              className={`text-aling-left ${isSubmitted && newCar.make === '' ? 'required' : ''}`}>Marca</Form.Label>
            <Form.Label
              className='required'>*</Form.Label>
            <Form.Control name='make' onChange={handleChange} value={newCar?.make} required
                          className={isSubmitted && newCar.make === '' ? 'is-invalid' : ''}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
            {
              isSubmitted && newCar.make === '' &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
            }
          </Col>
          <Col xs={12} lg="3">
            <Form.Label
              className={`text-aling-left ${isSubmitted && newCar.model === '' ? 'required' : ''}`}>Modelo</Form.Label>
            <Form.Label
              className='required'>*</Form.Label>
            <Form.Control name='model' onChange={handleChange} value={newCar?.model} required
                          className={isSubmitted && newCar.model === '' ? 'is-invalid' : ''}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
            {
              isSubmitted && newCar.model === '' &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
            }
          </Col>
          <Col xs={12} lg="3">
            <Form.Label
              className={`text-aling-left ${isSubmitted && (newCar.year === '0' || newCar.year === '' || newCar.year.length !== 4) ? 'required' : ''}`}>Año</Form.Label>
            <Form.Label
              className='required'>*</Form.Label>
            <Form.Control name='year' onChange={handleChange} value={newCar?.year} type={'number'} aria-label="Small"
                          className={isSubmitted && (newCar.year === '0' || newCar.year === '' || newCar.year.length !== 4) ? 'is-invalid' : ''}
                          aria-describedby="inputGroup-sizing-sm"/>
            {
              isSubmitted && (newCar.year === '0' || newCar.year === '') &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
            }
            {
              isSubmitted && newCar.year.length !== 4 &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>El año debe tener 4 digitos</p>
            }
          </Col>
          <Col xs={12} lg="3">
            <Form.Label className='text-aling-left'>Color</Form.Label>
            <Form.Control name='color' onChange={handleChange} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Storage</Form.Label>
            <Form.Control name='storage' onChange={handleChange} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={3}>
            <br/>
            <Form.Check
              checked={newCar && newCar?.titulo}
              label="Titulo"
              name="titulo"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline1"
            />
            <Form.Check
              checked={newCar && newCar?.bio}
              label="BioH"
              name="bio"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline2"
            />
          </Col>
          <Col xs={6} md={3}>
            <br/>
            <Form.Check
              checked={newCar && newCar?.llave}
              label="Llave"
              name="llave"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline3"
            />
            <Form.Check
              checked={newCar && newCar?.catalitico}
              label="Catalitico"
              name="catalitico"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline4"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={3}>
            <Form.Check
              checked={newCar && newCar?.keyinside}
              label="KeyInside"
              name="keyinside"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline5"
            />
            <Form.Check
              checked={newCar && newCar?.radio}
              label="Radio"
              name="radio"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline6"
            />
          </Col>
          <Col xs={6} md={3}>
            <Form.Check
              checked={newCar && newCar?.bateria}
              label="Bateria"
              name="bateria"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline7"
            />
            <Form.Check
              checked={newCar && newCar?.llantaResp}
              label="Llanta Resp."
              name="llantaResp"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline8"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4}>
            <Form.Check
              checked={newCar && newCar?.billofSale}
              label="Bill of sale?"
              name="billofSale"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline9"
            />
          </Col>
          <Col xs={6} md={4}>
            <Form.Check
              checked={newCar && newCar?.openDoor}
              label="Abre puerta?"
              name="openDoor"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline10"
            />
          </Col>
          <Col xs={6} md={4}>
            <Form.Check
              checked={newCar && newCar?.openCapo}
              label="Abre capo?"
              name="openCapo"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline11"
            />
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col xs={6} md={3}>
            <Form.Label className="text-aling-left">
              Cristales rotos?
            </Form.Label>
            <br/>
            <Form.Check
              checked={newCar && newCar?.cristalDelantero}
              label="Cristal Front"
              name="cristalDelantero"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline12"
            />
            <Form.Check
              checked={newCar && newCar?.ventanaIzquierda}
              label="Ventana Izquierda"
              name="ventanaIzquierda"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline13"
            />
          </Col>
          <Col xs={6} md={3}>
            <br/>
            <Form.Check
              checked={newCar && newCar?.cristalTrasero}
              label="Cristal Back"
              name="cristalTrasero"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline14"
            />
            <Form.Check
              checked={newCar && newCar?.ventanaDerecha}
              label="Ventana Derecha"
              name="ventanaDerecha"
              onChange={handleOnChangeCK}
              type="checkbox"
              id="inline15"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Recibido Por:</Form.Label>
            <Form.Control disabled readOnly name='updateBy' aria-label="Small" value={newCar?.updateBy}
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="3">
            <Form.Label className='text-aling-left'>Origen</Form.Label>
            <Form.Select disabled readOnly name='origen' value={newCar?.origen} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                origen && origen.length && origen.map((ori, index) => {
                  return <option key={index} value={ori.id}>{ori.nameOrigen}</option>
                })
              }
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg="3">
            <Form.Label className='text-aling-left'>Notas</Form.Label>
            <Form.Control name='notas' onChange={handleChange} maxLength={300}
                          as="textarea" rows={3}/>
          </Col>
        </Row>
        <br/>
        <Row style={{justifyContent: "center", color: "#ff0000"}}>
          <Col xs={12} lg="5">
            {
              msjsuccess !== "" ? <div className=' alert alert-success'>{msjsuccess}</div> : ''
            }
            {
              message !== "" ? <p>{message}</p> : ''
            }
          </Col>
        </Row>
        <Row style={{justifyContent: "center"}}>
          <Button type="button" onClick={() => insertarCar()} style={{width: "250px"}}>Guardar Info de
            Vehiculo <FontAwesomeIcon icon={faFloppyDisk}/></Button>
          <ToastContainer/>
        </Row>

      </Form>

    </div>
  )
}

export default NewVehicle

