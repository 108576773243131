import {Button, Col, Form, Row, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {showAduana} from "../../Servicios/CombosService";

const AduanaSelector = ({ onClose, onSelect }) => {
  const [element_form_aduana] = Form.useForm();

  const [aduanas, setAduanas] = useState([]);

  useEffect(() => {
    loadAduanas().then();
  }, []);

  const loadAduanas = async () => {
    const data = await showAduana();
    setAduanas(data);
  };

  return(
    <>
      <Form layout="vertical"
            form={element_form_aduana}
            onFinish={(values) => onSelect(aduanas.find(e => e.id === values.aduanaIdUpdate))}
            name="form_in_modal">
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label="Aduana Destino"
              name="aduanaIdUpdate"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione una aduana",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otra aduana",
                  value: "empty",
                }}
                options={
                  aduanas &&
                  aduanas.length &&
                  aduanas.map((aduanas) => {
                    return {
                      label: `${aduanas.nameAduana}-${aduanas.countryName}`,
                      value: aduanas.id,
                    };
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={12}>
            <Button key="back" onClick={onClose} block>Cancelar</Button>
          </Col>

          <Col lg={12} md={12} sm={12} xs={12}>
            <Button type="primary" htmlType="submit" block>
              <FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AduanaSelector;
