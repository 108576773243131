import React, {useEffect, useState} from "react";
import {showCountries, showOrigen, showSubasta} from "../Servicios/CombosService";
import moment from "moment/moment";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileText, faTimes} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {DatePicker, Select} from "antd";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import DataTable from "react-data-table-component";
import SpinnerTable from "../Componentes/SpinnerTable";
import {showCoord} from "../Servicios/CoordService";
import {StringFormats} from "../utils/string-formats";
import XLSX from "xlsx";

const StorageReport = () => {
  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [toDate, setToDate] = useState(undefined);
  const [origenId, setOrigenId] = useState(0);
  const [subastaId, setSubastaId] = useState(0);
  const [fromDate, setFromDate] = useState(undefined);
  const [countryId, setCountryId] = useState(0);

  const [countries, setCountries] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [subastas, setSubastas] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [totalElements, setTotalElements] = useState(0);

  const XLSX = require("xlsx");

  const showCoordinaciones = async (search, fromDate, toDate, countryId, origenId, subastaId) => {
    setIsLoading(true);

    const from = fromDate ? fromDate.getTime() : 0;
    const to = toDate ? toDate.getTime() : 0;

    const data = await showCoord(origenId, "", 0, 0, 0, 0, search, "", 0, 0, 0, 0, from, to, countryId, subastaId);
    setIsLoading(false);

    if (data.status) {
      setQuote(data.list);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    showCountries().then(data => {
      setCountries(data);
    });

    showOrigen().then(data => {
      setOrigenes(data);
    });

    showSubasta().then(data => {
      setSubastas(data.list);
    });
  }, []);

  const generarReporte = () => {
    showCoordinaciones(filterText, fromDate, toDate, countryId, origenId, subastaId).then();
  }

  const columns = [
    {
      name: "Fecha",
      selector: (row) => row.fechacor,
      format: (row) => moment(row?.fechacor).format("l"),
      width: "100px",
    },
    {
      name: "Lote",
      sortable: true,
      selector: (row) => row?.lote,
      width: "90px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Importadora",
      sortable: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Destino",
      sortable: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Origen",
      sortable: true,
      selector: (row) => row?.origenName,
      width: "110px",
    },
    {
      name: "Cliente",
      sortable: true,
      selector: (row) => row?.clienteName,
      width: "250px",
    },
    {
      name: "Creado Por",
      sortable: true,
      selector: (row) => row?.createBy,
      width: "250px",
    },
    {
      name: "St Cliente",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.st_Cliente ?? "0"),
      width: "100px",
    },
    {
      name: "St Driver",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.st_Driver ?? "0"),
      width: "100px",
    },
    {
      name: "St Matus",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.st_Matus ?? "0"),
      width: "100px",
    },
    {
      name: "St Matus/Cliente",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.stMatusCliente ?? "0"),
      width: "150px",
    },
    {
      name: "St Paid",
      sortable: true,
      selector: (row) => StringFormats.currency(row?.st_Paid ?? "0"),
      width: "100px",
    },
    {
      name: "Total",
      sortable: true,
      selector: (row) => StringFormats.currency((row?.st_Cliente ? +row?.st_Cliente : 0) +
        (row?.st_Driver ? +row?.st_Driver : 0) +
        (row?.st_Matus ? +row?.st_Matus : 0) +
        (row?.stMatusCliente ? +row?.stMatusCliente : 0) +
        (row?.st_Paid ? +row?.st_Paid : 0)),
      width: "100px",
    },
  ];

  const exportToExcel = () => {
    const excelQuote = quote.map(row => {
      return {
        'Fecha': row?.fechacor ? moment(row?.fechacor).format("l") : '---',
        'Lote': row?.lote,
        'Marca': row?.vehiculo.make,
        'Modelo': row?.vehiculo.model,
        'Importadora': row?.import,
        'Aduana': row?.aduanaName,
        'Origen': row?.origenName,
        'Creado Por': row?.createBy,
        'St Cliente': row?.st_Cliente ? +row?.st_Cliente : 0,
        'St Driver': row?.st_Driver ? +row?.st_Driver : 0,
        'St Matus': row?.st_Matus ? +row?.st_Matus : 0,
        'St Matus/Cliente': row?.stMatusCliente ? +row?.stMatusCliente : 0,
        'St Paid': row?.st_Paid ? +row?.st_Paid : 0,
        'Total': (row?.st_Cliente ? +row?.st_Cliente : 0) +
          (row?.st_Driver ? +row?.st_Driver : 0) +
          (row?.st_Matus ? +row?.st_Matus : 0) +
          (row?.stMatusCliente ? +row?.stMatusCliente : 0) +
          (row?.st_Paid ? +row?.st_Paid : 0)
      }
    });

    excelQuote.push({
      'Fecha': '',
      'Lote': '',
      'Marca': '',
      'Modelo': '',
      'Importadora': '',
      'Aduana': '',
      'Origen': '',
      'Creado Por': '',
      'St Cliente': subtotal('st_Cliente'),
      'St Driver': subtotal('st_Driver'),
      'St Matus': subtotal('st_Matus'),
      'St Matus/Cliente': subtotal('stMatusCliente'),
      'St Paid': subtotal('st_Paid'),
      'Total': subtotal('st_Cliente') + subtotal('st_Driver') + subtotal('st_Matus') + subtotal('stMatusCliente') + subtotal('st_Paid')
    })

    const worksheet = XLSX.utils.json_to_sheet(excelQuote);
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      const cell_1 = XLSX.utils.encode_cell({ r: R, c: 8 });
      const cell_2 = XLSX.utils.encode_cell({ r: R, c: 9 });
      const cell_3 = XLSX.utils.encode_cell({ r: R, c: 10 });
      const cell_4 = XLSX.utils.encode_cell({ r: R, c: 11 });
      const cell_5 = XLSX.utils.encode_cell({ r: R, c: 12 });
      const cell_6 = XLSX.utils.encode_cell({ r: R, c: 13 });

      if (!worksheet[cell_1]) continue;
      worksheet[cell_1].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_2]) continue;
      worksheet[cell_2].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_3]) continue;
      worksheet[cell_3].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_4]) continue;
      worksheet[cell_4].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_5]) continue;
      worksheet[cell_5].z = '"$"#,##0.00_);("$"#,##0.00)';

      if (!worksheet[cell_6]) continue;
      worksheet[cell_6].z = '"$"#,##0.00_);("$"#,##0.00)';
    }

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte de Storage");
    // Genera el archivo Excel
    XLSX.writeFile(workbook, "reporte_storage.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  };

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const handleFilterText = (value) => {
    setFilterText(value);
    showCoordinaciones(value, fromDate, toDate, countryId, origenId, subastaId).then();
  }

  const handleChangeRangePicker = (event) => {
    if (event) {
      setFromDate(event[0].$d);
      setToDate(event[1].$d);
    } else {
      setFromDate(undefined);
      setToDate(undefined);
    }
  }

  const handleChangeSelectCountry = (event) => {
    setCountryId(event);
  }

  const handleChangeSelectOrigen = (event) => {
    setOrigenId(event);
  }

  const handleChangeSelectSubasta = (event) => {
    setSubastaId(event);
  }

  const subtotal = (key) => {
    return quote
      .reduce((a, b) => {
        return a + (b[key] !== null && b[key] !== undefined && b[key] !== "" ? +(b[key]) : 0);
      }, 0);
  }

  const total = () => {
    return StringFormats.currency(subtotal('st_Cliente') +
      subtotal('st_Driver') +
      subtotal('st_Matus') +
      subtotal('stMatusCliente') +
      subtotal('st_Paid'))
  }

  return (
    <Container fluid={true} className="solicitudes">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">Reporte de Storage</h4>

        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload}/> Exportar a Excel
        </Button>
      </div>
      <hr className="mb-0" />
      <Row>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <Form.Label>Filtrar por Fecha</Form.Label>
          <DatePicker.RangePicker className="w-100"
                                  onChange={handleChangeRangePicker} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por País</Form.Label>
            {
              countryId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectCountry(0)} />
            }
          </div>
          <Select className="w-100"
                  value={countryId === 0 ? "" : countryId}
                  onChange={handleChangeSelectCountry}
                  options={(countries ?? []).map(e => {
                    return {
                      value: e.id,
                      label: e.nameCountry
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Origen</Form.Label>
            {
              origenId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectOrigen(0)} />
            }
          </div>
          <Select className="w-100"
                  value={origenId === 0 ? "" : origenId}
                  onChange={handleChangeSelectOrigen}
                  options={(origenes ?? []).map(e => {
                    return {
                      value: e.id,
                      label: e.nameOrigen
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Subasta</Form.Label>
            {
              subastaId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleChangeSelectSubasta(0)} />
            }
          </div>
          <Select className="w-100"
                  value={subastaId === 0 ? "" : subastaId}
                  onChange={handleChangeSelectSubasta}
                  options={(subastas ?? []).map(e => {
                    return {
                      value: e.subId,
                      label: e.subName
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Label className="input-buscar">
            Buscar Coordinacion
          </Form.Label>
          <InputSearch placeholder={'Buscar por Lote / Marca / Modelo / Importadora'}
                       doChange={handleFilterText}/>
        </Col>
        <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <p className="m-0 fw-bolder">
                Total: {totalElements} {totalElements === 1 ? 'coordinacion' : 'coordinaciones'}
              </p>

              <div className="d-flex gap-2 flex-wrap">
                <p className="m-0 fw-bolder">Total St Cliente: {StringFormats.currency(subtotal('st_Cliente'))} |</p>
                <p className="m-0 fw-bolder">Total St Driver: {StringFormats.currency(subtotal('st_Driver'))} |</p>
                <p className="m-0 fw-bolder">Total St Matus: {StringFormats.currency(subtotal('st_Matus'))} |</p>
                <p className="m-0 fw-bolder">Total St Matus/Cliente: {StringFormats.currency(subtotal('stMatusCliente'))} |</p>
                <p className="m-0 fw-bolder">Total St Paid: {StringFormats.currency(subtotal('st_Paid'))} |</p>
                <p className="m-0 fw-bolder">Total: {total()}</p>
              </div>
            </div>
            <div className="text-end">
              <Button size="sm" onClick={generarReporte}>
                <FontAwesomeIcon icon={faFileText}/> Generar Reporte
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <hr/>
      <DataTable
        columns={columns}
        data={quote}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
        progressPending={isLoading}
        progressComponent={<SpinnerTable />}
        persistTableHead
        pagination
      />
    </Container>
  );
}

export default StorageReport;
