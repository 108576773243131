import React, {useEffect, useState} from "react";
import {showImportadora} from "../../Servicios/CombosService";
import {Select} from "antd";

const InputSearchImportadora = ({ disabled, selectedImportadora, onSelectImportadora }) => {
  const pageCount = 100;

  const [searchName, setSearchName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [importadoraList, setImportadoraList] = useState([]);
  const [searchNameDebounce, setSearchNameDebounce] = useState("");

  useEffect(() => {
    const loadImportadoraList = async (currentPage, searchName = "") => {
      setLoading(true);
      const data = await showImportadora(searchName, searchName !== "" ? 1 : currentPage, pageCount);
      setLoading(false);

      if (selectedImportadora) {
        const list = data.list;
        if (!importadoraList.some(e => e.id === selectedImportadora.id)) {
          list.push(selectedImportadora);
        }

        setImportadoraList(currentPage === 1 ? [...list] : [...importadoraList, ...list]);
      } else {
        setImportadoraList(currentPage === 1 ? [...data.list] : [...importadoraList, ...data.list]);
      }
    };

    loadImportadoraList(currentPage, searchNameDebounce).then();
  }, [currentPage, searchNameDebounce, selectedImportadora]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchName !== null) {
        setSearchNameDebounce(searchName);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchName, 500]);

  const handleChange = (event, item) => {
    if (onSelectImportadora) {
      onSelectImportadora(item.importadora);
    }
  }

  const label = importadora => {
    return(
      <>
        <p className="m-0 text-uppercase">{importadora?.impName}</p>
        <p className="m-0 text-uppercase">
          {importadora?.identfType ?? "Identificador"}: {importadora?.identf ?? "Sin Asignar"}</p>
        <p className="m-0 text-uppercase">{importadora?.direccion}</p>
      </>
    );
  }

  return (
    <>
      <Select className="w-100"
              showSearch={true}
              disabled={disabled}
              loading={isLoading}
              onChange={handleChange}
              filterOption={(input: string, option?: { labelName: string; value: string }) =>
                (option?.labelName ?? '').toLowerCase().includes(input.toLowerCase())}
              onSearch={(value) => {
                setCurrentPage(1);
                setSearchName(value);
              }}
              onFocus={() => {
                setCurrentPage(1);
                setSearchName("");
              }}
              placeholder={'Escriba y Seleccione una opción'}
              value={!selectedImportadora ? undefined : {
                value: selectedImportadora.id,
                label: label(selectedImportadora),
                labelName: `${selectedImportadora?.impName} - ${selectedImportadora?.identf}`,
                importadora: selectedImportadora
              }}
              options={importadoraList && importadoraList?.length
                ? importadoraList?.map(importadora => ({
                  label: label(importadora),
                  labelName: `${importadora?.impName} - ${importadora?.identf}`,
                  value: importadora.id,
                  importadora: importadora
                }))
                : []
              } />
    </>
  );
}

export default InputSearchImportadora;
