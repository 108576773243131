

export const SolicitudCambioAduana = (item) =>  `
   <!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <meta name="x-apple-disable-message-reformatting">
  <title>ConfirmationRequest</title>
  <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
  <style>
    table,
    td,
    div,
    h1,
    p {
      font-family: Arial, sans-serif;
    }
  </style>
</head>

<body style="margin:0;padding:0;">
  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
      <td align="center" style="padding:0;">
        <table role="presentation"
          style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
            <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
              <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
            </td>
          </tr>
          <tr>
            <td style="padding:5px 25px 0px 25px;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;">
                    <h1 style="text-align: center">Cambio de aduana #Lote ${item.lote}</h1>
                    <p>
                      Hola equipo, esoy notificando cambio de aduana para el vehiculo con los siguientes datos <br />
                    </p>

                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding:10px;">
              <table style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">

                <tr>
                  <td style="width:260px">
                    <strong>Importadora</strong>
                  </td>
                  <td style="width:260px">
                    <strong>VIN</strong>
                  </td>
                  <td style="width:260px">
                    <strong>Vehiculo</strong>
                  </td>
                  <td style="width:260px">
                    <strong>Subasta</strong>
                  </td>
                  <td style="width:200px">
                    <strong>Lote</strong>
                  </td>
                  <td style="width:200px">
                    <strong>GatePass</strong>
                  </td>
                  <td style="width:200px">
                    <strong>Buyer</strong>
                  </td>
                </tr>
                <tr>
                  <td style="width:260px">
                    ${item?.importadoraName}
                  </td>
                  <td style="width:200px">
                    ${item?.vin}
                  </td>
                  <td style="width:200px">
                    ${item?.vehiculoName}
                  </td>
                  <td style="width:260px">
                    ${item?.subName}
                  </td>
                  <td style="width:260px">
                    ${item?.lote}
                  </td>
                  <td style="width:260px">
                    ${item?.gatePass}
                  </td>
                  <td style="width:100px">
                    ${item?.buyer}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <br /> <br />
          <tr>
            <td>
              <h2 style="text-align: center">Datos actualizados de exportacion y costos</h2>
              <div style="margin: 0 auto; width: 520px;">
                <table style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                  <tr>
                    <td style="width:260px; text-align: center;">
                      <strong>Origen</strong>
                    </td>
                    <td style="width:260px; text-align: center;">
                      <strong>Destino</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:260px; text-align: center;">
                      ${item?.origen}
                    </td>
                    <td style="width:200px; text-align: center;">
                      ${item?.aduanaName}
                    </td>
                  </tr>
                </table>
                <hr />
              </div>
              <div>
                <table style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                  <tr>
                    <td style="width:260px; text-align: center;">
                      <strong>Flete</strong>
                    </td>
                    <td style="width:260px; text-align: center;">
                      <strong>Grua</strong>
                    </td>
                    <td style="width:260px; text-align: center;">
                      <strong>BL</strong>
                    </td>
                    <td style="width:260px; text-align: center;">
                      <strong>Costo Adicional</strong>
                    </td>
                    <td style="width:260px; text-align: center; background-color: #f2f2f2;">
                      <strong>Total</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:260px; text-align: center;">
                      ${item?.quoted}
                    </td>
                    <td style="width:200px; text-align: center;">
                      ${item?.estGrua}
                    </td>
                    <td style="width:260px; text-align: center;">
                      ${item?.bl}
                    </td>
                    <td style="width:200px; text-align: center;">
                      ${item?.costoQ ?? '$0.00'}
                    </td>
                    <td style="width:260px; text-align: center; background-color: #f2f2f2;">
                      ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item?.total ?? 0)}
                    </td>
                  </tr>
                </table>
                <hr />
              </div>
            </td>
          </tr>
          <br /> <br />
          <tr>
            <td>
              <table style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="width:260px; text-align: center;">
                    Asesor de ventas
                  </td>
                  <td style="width:260px; text-align: center;">
                    
                  </td>
                </tr>
                <tr>
                  <td style="width:260px; text-align: center;">
                    ${item?.creador}
                  </td>
                  <td style="width:200px; text-align: center;">
                    
                  </td>
                </tr>
              </table>
              <br />
            </td>
          </tr>
          <br /> <br />
          <tr>
            <td style="padding:30px;background:#1C2E51;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;width:50%;" align="left">
                    <p style="color:white;">&reg; Matus International, 2023</p>
                  </td>
                  <td style="padding:0;width:50%;" align="right">
                    <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                      <tr>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.facebook.com/matusintl/"><img
                              src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.instagram.com/matusintl/?hl=en"><img
                              src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web"
                              width="28" style="height:auto;display:block;border:0;" /></a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>
    `;
