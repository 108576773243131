import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {Button, Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";

export const CancelarSolicitudBtn = ({ block, onCancelar }) => {
  const [description, setDescription] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCancelarSolicitud = () => {
    setOpenConfirmationModal(false);
    if (onCancelar) onCancelar(description);
  }

  return(
    <>
      <Button onClick={() => setOpenConfirmationModal(true)} type={"dashed"} danger={true} block={block}>
        <FontAwesomeIcon icon={faTimes} className="me-2" /> Cancelar Solicitud
      </Button>

      <Modal open={openConfirmationModal}
             title={'Confirmar'}
             onOk={handleCancelarSolicitud}
             onCancel={() => {
               setOpenConfirmationModal(false);
             }}>
        <p>Esta seguro que desea cancelar esta solicitud?</p>

        <TextArea
          value={description}
          onChange={(e) => {setDescription(e.target.value)}}
          placeholder="Escriba el motivo de la cancelación"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Modal>
    </>
  );
}

export default CancelarSolicitudBtn;
