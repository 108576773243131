export const CorreoEmbarqueEmail = (exportation, exportationContainer) => {
  const tabla = () => {
    return exportationContainer?.detalleExport?.map(exp => {
      return(
        `<tr>
          <td style="border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.vehiculoName}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.vin}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.lote}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.flete}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.grua}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.bl}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.costoAdicional ?? 0}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.costoAdicional2 ?? 0}</td>
          <td style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">${exp?.werehouse?.total ?? 0}</td>
          <td style="border: 1px solid black; padding: 4px 8px; font-size: 11px;">Cliente: ${exp?.werehouse?.clientes?.nombreCompleto}. Importadora: ${exp?.werehouse?.clientes?.importadoraName}</td>
        </tr>`
      );
    }).join("");
  }

  return `
   <!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <meta name="x-apple-disable-message-reformatting">
      <title>CorreoEmbarque</title>
      <style>
        table,
        td,
        div,
        h1,
        p {
          font-family: Arial, sans-serif;
        }
      </style>
    </head>
    <body style="margin:0;padding:0;">
      <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
        <tr>
          <td align="center" style="padding:0;">
            <table role="presentation"
              style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
              <tr>
                <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                  <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
                </td>
              </tr>
              <tr>
                <td style="padding:5px 25px 0px 25px;">
                  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="padding:0;">
                        <p style="margin-bottom: 0">Buenos días</p>
                        <p style="margin-top: 0">A continuación, detalles de embarque:</p>
                        <table>
                            <tr>
                                <td style="width: 15%; vertical-align: top">
                                  <p style="margin: 0"><span style="font-weight: bold">Número de file:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">Referencia:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">BK:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">From:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">Destino:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">POD:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">Shipping line:</span></p>
                                  <p style="margin: 0"><span style="font-weight: bold">ETD:</span></p>
                                </td>
                                <td style="width: 25%; vertical-align: top">
                                  <p style="margin: 0">${exportationContainer?.detalleExport[0]?.codeExpSecuential}</p>
                                  <p style="margin: 0">${exportation?.customerReference}</p>
                                  <p style="margin: 0">${exportation?.bookingNum}</p>
                                  <p style="margin: 0">Matus ${exportation?.origen?.state}</p>
                                  <p style="margin: 0">${exportation?.countryName}</p>
                                  <p style="margin: 0">${exportation?.placeDelivery}</p>
                                  <p style="margin: 0">${exportation?.loadingPierTerminal}</p>
                                  <p style="margin: 0">${new Date(exportation?.departureDate)?.toLocaleDateString()}</p>
                                </td>
                                <td style="width: 30%; vertical-align: top">
                                  <p style="margin: 0; font-weight: bold;">Shipper:</p>
                                  <p style="margin: 0">${exportation?.shipper?.subName}</p>
                                  <p style="margin: 0">${exportation?.shipper?.direccion} ${exportation?.shipper?.codeState}</p>
                                </td>
                                <td style="width: 30%; vertical-align: top">
                                  <p style="margin: 0; font-weight: bold;">Consignee:</p>
                                  <p style="margin: 0">${exportation?.ultimateConsignee?.impName}</p>
                                  <p style="margin: 0">${exportation?.ultimateConsignee?.addressLine1}</p>
                                  <p style="margin: 0">${exportation?.ultimateConsignee?.addressLine2}</p>
                                  <p style="margin: 0">${exportation?.ultimateConsignee?.city} - ${exportation?.ultimateConsignee?.country}</p>
                                  <p style="margin: 0">CONTACTO: ${exportation?.ultimateConsignee?.phoneNumber}</p>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <table style="width: 100%; border-spacing: 0">
                            <thead>
                                <tr>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">
                                        <p style="margin: 0">Descripcion</p>
                                        <p style="margin: 0">${exportationContainer?.container?.nameContainer}</p>
                                    </th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">VIN</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Lot #</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Flete (WR)</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Grua (WR)</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">BL (WR)</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Storage (WR)</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Otros (WR)</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">Total USD</th>
                                    <th style="text-align: center; border: 1px solid black; padding: 4px 8px; font-size: 11px;">
                                        <p style="margin: 0">Warehouse Receipt: Notes</p>
                                        <p style="margin: 0">${exportation?.shipmentName}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                ${tabla()}
                            </tbody>
                        </table>
                        <br>
                        <p>NOTA PARA ${exportation?.ultimateConsignee?.impName?.toUpperCase()}: Por favor nos ayudan revisando y confirmando los correos.:</p>
                        <p style="margin-bottom: 0">Equipo financiero Matus, es importante que vean las notas en cada vehículo.</p>
                        <p style="margin-top: 0">También tengan en cuenta que este BL tiene que ir prepaid y liberado para que el equipo de RF no tenga atrasos en las gestiones de aduana en destino.</p>
                        
                        <p>Gracias y Saludos,</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:30px;background:#1C2E51;">
                  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="padding:0;width:50%;" align="left">
                        <p style="color:white;">&reg; Matus International, 2023</p>
                      </td>
                      <td style="padding:0;width:50%;" align="right">
                        <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                          <tr>
                            <td style="padding:0 0 0 10px;width:38px;">
                              <a href="https://www.facebook.com/matusintl/"><img
                                  src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38"
                                  style="height:auto;display:block;border:0;" /></a>
                            </td>
                            <td style="padding:0 0 0 10px;width:38px;">
                              <a href="https://www.instagram.com/matusintl/?hl=en"><img
                                  src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38"
                                  style="height:auto;display:block;border:0;" /></a>
                            </td>
                            <td style="padding:0 0 0 10px;width:38px;">
                              <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web"
                                  width="28" style="height:auto;display:block;border:0;" /></a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
  </html>`;
};
