import {decodeToken} from "react-jwt";

export class AuthRole {
  id: number;
  name: string;

  constructor(props?: any) {
    if (!props) return;
    this.id = props['Id'];
    this.name = props['Name'];
  }
}

export class AuthUserRole {
  id: number;
  rolId: number;
  usuarioId: number;
  countryId: number;
  role: AuthRole;

  constructor(props: any) {
    this.id = props['Id'];
    this.rolId = props['RolId'];
    this.usuarioId = props['UsuarioId'];
    this.countryId = props['CountryId'];

    this.role = new AuthRole(props['Rol']);
  }
}

export class AuthPermission {
  id: number;
  name: string;
  code: string;

  constructor(props: any) {
    this.id = props['Id'];
    this.name = props['Name'];
    this.code = props['Code'];
  }
}

export class AuthModel {
  username: string = '';
  fullName: string = '';
  email: string = '';
  ubication: string;
  ref: string;
  idusr: string;
  idubc: string;
  idpais: string;
  address: string;

  roles: AuthUserRole[];
  permission: AuthPermission[];

  constructor(props: any) {
    this.username = props['username'];
    this.fullName = props['fullName'];
    this.email = props['email'];
    this.ubication = props['ubication'];
    this.ref = props['ref'];
    this.idusr = props['idusr'];
    this.idubc = props['idubc'];
    this.idpais = props['idpais'];
    this.address = props['address'];

    const decodedRoles = decodeToken(props['roles']);
    const decodedPermisions = decodeToken(props['permisos']);

    this.roles = decodedRoles.payload.map(e => new AuthUserRole(e));
    this.permission = decodedPermisions.payload.map(e => new AuthPermission(e));
  }
}
