import React, {useEffect, useState} from "react";
import {deleteRole, getRoles} from "../../Servicios/AdminService";
import {Modal, Space, Table} from "antd";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPencil, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import RolForm from "./RolForm";
import Spinner from "../../shared/spinner";
import {errorMessage, successMessage} from "../../utils/toast-message";
import RolPermisoForm from "./RolPermisoForm";

const RolPage = () => {
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [addNewRole, setAddNewRole] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [editRole, setEditRole] = useState(undefined);
  const [dropRole, setDropRole] = useState(undefined);
  const [assignRolePermission, setAssignRolePermission] = useState(undefined);

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    setLoading(true);
    const roles = await getRoles();
    setLoading(false);

    setRoles([...roles]);
    setFilteredRoles([...roles]);
  }

  const columns = [
    { title: 'ID',    dataIndex: "id",  key: "id" },
    { title: 'Name',  dataIndex: "name", key: "name" },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
      width: '180px',
      render: (_, element) => (<>
        <Space key={element.id}>
          <Button variant={"primary"}
                  size={'sm'}
                  title={'Asignar Permiso'}
                  onClick={() => setAssignRolePermission(element)}><FontAwesomeIcon icon={faLink}/></Button>
          <Button variant={"info"}
                  size={'sm'}
                  onClick={() => setEditRole(element)}><FontAwesomeIcon icon={faPencil}/></Button>
          <Button variant={"danger"}
                  size={'sm'}
                  onClick={() => {
                    setDropRole(element);
                    setOpenConfirmationModal(true);
                  }}><FontAwesomeIcon icon={faTrash}/></Button>
        </Space>
      </>)
    }
  ];

  const handleSearch = (event) => {
    const data = roles
      .filter(e => event === "" || e.name.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilteredRoles(data);
  }

  const handleDeleteRol = async () => {
    setOpenConfirmationModal(false);
    setLoading(true);

    const data = await deleteRole(dropRole.id);
    setLoading(false);
    setDropRole(undefined);

    if (data.status) {
      successMessage("El rol fue eliminado correctamente");
      await init();
    } else {
      errorMessage(data.message);
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 className="m-0">Roles de Usuario</h4>
          <Button variant={"primary"} onClick={() => setAddNewRole(true)}>
            <FontAwesomeIcon icon={faPlus}/> Nuevo Rol
          </Button>
        </div>

        <div className="mb-3">
          <InputSearch placeholder={'buscar rol'} doChange={handleSearch}/>
        </div>

        <Table size={"middle"}
               columns={columns}
               dataSource={filteredRoles}
               pagination={{pageSize: 10}}/>

        <Modal title={'Crear Rol'}
               open={addNewRole || editRole}
               onCancel={() => {
                 setAddNewRole(false);
                 setEditRole(undefined);
               }}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}>
          <RolForm role={editRole}
                   doSubmit={() => {
                     setAddNewRole(false);
                     setEditRole(undefined);

                     init().then();
                   }} />
        </Modal>

        <Modal open={openConfirmationModal}
               title={'Confirmar'}
               onOk={handleDeleteRol}
               onCancel={() => {
                 setDropRole(undefined);
                 setOpenConfirmationModal(false);
               }}>
          Esta seguro que desea eliminar este rol?
        </Modal>

        <Modal open={assignRolePermission !== undefined}
               title={'Permisos'}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}
               onCancel={() => {
                 setAssignRolePermission(undefined);
               }}>
          <RolPermisoForm rol={assignRolePermission} />
        </Modal>
      </Spinner>
    </>
  );
}

export default RolPage;
