import React, {createContext, useContext, useEffect, useState} from "react";
import {
  showCodeExport,
  showLicenceType,
  showOrigin,
  showSchedule,
  showStates,
  showTypeMerch
} from "../Servicios/WerehouseService";
import {showOrigen} from "../Servicios/CombosService";

const WarehouseContext = createContext();

export const useWarehouseContext = () => useContext(WarehouseContext);

export const WarehouseProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [origin, setOrigin] = useState([]);
  const [states, setStates] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);
  const [typemerch, setTypemerch] = useState([]);
  const [codeexport, setCodeexport] = useState([]);
  const [licencetype, setLicencetype] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const origin = await showOrigin();
        const states = await showStates();
        const origenes = await showOrigen();
        const schedules = await showSchedule();
        const typeMerch = await showTypeMerch();
        const codeExports = await showCodeExport();
        const licenceTypes = await showLicenceType();

        setOrigin(origin);
        setStates(states);
        setOrigenes(origenes);
        setScheduleb(schedules);
        setTypemerch(typeMerch);
        setCodeexport(codeExports);
        setLicencetype(licenceTypes);

        setLoading(false);
      } catch (ex) {
        setError(ex.toString());
        setLoading(false);
      }
    };

    fetchData().then();
  }, []);

  return (
    <WarehouseContext.Provider value={{
      origin,
      states,
      origenes,
      scheduleb,
      typemerch,
      codeexport,
      licencetype,
      error,
      loading
    }}>
      {children}
    </WarehouseContext.Provider>
  );
}
