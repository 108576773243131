import { createSlice } from "@reduxjs/toolkit";
const initAllVehicles = {
  id: "",
  height: "",
  length: "",
  width: "",
  weight: "",
  fecha: null,
  titulo: false,
  llave: false,
  catalitico: false,
  bio: false,
  origen: null,
  updateBy: null,
  storage: null,
  notas: null,
  ubicacion: null,
  keyinside: false,
  radio: false,
  llantaResp: false,
  billofSale: false,
  bateria: false,
  cristalTrasero: false,
  cristalDelantero: false,
  ventanaIzquierda: false,
  ventanaDerecha: false,
  openDoor: false,
  openCapo: false,
}

const initAllSolictud = {
  id: "",
  estado: "SinAsignar",
  activa: "Activa",
  proceso: "Sin ninguna Accion Realizada",
  coorasign: 0
}

const initCoordinations = { 
  paid: null,
  firstBusDay: null,
  lastFreeDay: null,
  estPickUp: null,
  actPickUp: null,
  estDelivery: null,
  actDelivery: null,
  estArrival: null,
  actArrival: null,
  cost: "",
  profit: "",
  st_Cliente: "",
  st_Driver: "",
  st_Matus: "",
  st_Paid: "",
  // state: "",
  quoteId: null,
  transporteId: null,
}

const initialState = {
  loading: false,
  allVehicles: [],
  allSolicitud: [],
  allCoordinations: [],
  allAttachments: [],
  allCoordinationSave: [],
};

export const solicitudSlice = createSlice({
  name: "solicitudesVehiculos",
  initialState,
  reducers: {
    loading: (state, { payload }) => {
      state.loading = payload;
    },
    getAllVehicles: (state, { payload }) => {
      state.allVehicles = payload;
    },
    addPushAllVehicles: (state, { payload }) => {
      state.allVehicles = [...state.allVehicles, {...initAllVehicles, ...payload}];
    },
    addCoordinationSave: (state, { payload }) => {
      console.log('payload', payload)
      state.allCoordinationSave = payload;
    },

    deleteAllCoordinationSave: (state, { payload }) => {
      state.allCoordinationSave = payload;
    },

    deleteAllVehicles: (state, { payload }) => {
      state.allVehicles = payload;
    },
    deleteOneVehicles: (state, { payload }) => {
      state.allVehicles = payload;
    },

    getAllSolicitud: (state, { payload }) => {
      state.allSolicitud = payload;
    },
    addPushAllSolicitud: (state, { payload }) => {
      state.allSolicitud = [...state.allSolicitud, {...initAllSolictud, ...payload}];
    },
    deleteAllSolicitud: (state, { payload }) => {
      state.allSolicitud = payload;
    },
    deleteOneSolicitud: (state, { payload }) => {
      state.allSolicitud = payload;
    },

    getAllCoordinations: (state, { payload }) => {
      state.allCoordinations = payload;
    },
    addPushAllCoordinations: (state, { payload }) => {
      state.allCoordinations = [...state.allCoordinations, {...initCoordinations, ...payload}];
    },
    deleteAllCoordinations: (state, { payload }) => {
      state.allCoordinations = payload;
    },
    deleteOneCoordinations: (state, { payload }) => {
      state.allCoordinations = payload;
    },
    addPushAllAttachments: (state, { payload }) => {
      state.allAttachments = [...state.allAttachments, payload];
    },
    deleteIndexAttachments: (state, { payload }) => {
      state.allAttachments = payload;
    },
    deleteAllAttachments: (state, { payload }) => {
      state.allAttachments = payload;
    }
  },
});

export const {
  getAllVehicles,
  getAllSolicitud,
  loading,
  addPushAllVehicles,
  addPushAllSolicitud,
  deleteAllVehicles,
  deleteAllSolicitud,
  deleteOneVehicles,
  deleteOneSolicitud,

  getAllCoordinations,
  addPushAllCoordinations,
  deleteAllCoordinations,
  deleteOneCoordinations,
  deleteAllCoordinationSave,
  addCoordinationSave,
  addPushAllAttachments,
  deleteAllAttachments
} = solicitudSlice.actions;


