import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Layout, message } from "antd";
import logocirc from "../../image/logocir.png";
import "./styles.css";
import { getTracking } from "../../Servicios/TrackingService";

const { Content } = Layout;
const { Search } = Input;

const SearchTracking = () => {
  const [lotNumber, setLotNumber] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    if (lotNumber) {
      getTracking(lotNumber)
        .then((data) => {
          console.log(data);
          if (data.data && data.status === true) {
            navigate("/DetailTracking", { state: { data: data.data } });
            setLoading(false);
          } else {
            message.error(
              "No se encontró información para el número de lote ingresado"
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("Ocurrió un error al obtener la información del lote");
            setLoading(false);
        });
    } else {
      message.error("Ingrese un número de lote");
        setLoading(false);
    }
  };

  return (
    <Layout>
      <Content style={{ minHeight: "100vh" }}>
        <Content className="home-container">
          <div className="ini-img">
            <img className="imagen-inicio" src={logocirc} alt="Logo" />
          </div>
          <div className="input-container">
            <Search
              placeholder="Ingrese número de lote"
              enterButton="Buscar"
              size="large"
              onSearch={handleSearch}
              onChange={(e) => setLotNumber(e.target.value)}
              className="search-input"
              loading={loading}
            />
          </div>
        </Content>
      </Content>
    </Layout>
  );
};

export default SearchTracking;
