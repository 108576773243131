import {createContext, useContext, useEffect, useState} from "react";
import {getAduana, getColor, getContainer, getListCarrier} from "../Servicios/ExportationServices";

const ExportationContext = createContext();

export const useExportationContext = () => useContext(ExportationContext);

export const ExportationProvider = ({ children }) => {
  const [errorExportation, setErrorExportation] = useState(null);
  const [loadingExportation, setLoadingExportation] = useState(true);

  const [colors, setColors] = useState([]);
  const [aduanas, setAduanas] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingExportation(true);

      try {
        const colors = await getColor();
        const aduanas = await getAduana();
        const carriers = await getListCarrier();
        const containers = await getContainer();

        setColors(colors);
        setAduanas(aduanas.filter(e => !e.isProvisional));
        setCarriers(carriers);
        setContainers(containers);

        setLoadingExportation(false);
      } catch (ex) {
        setErrorExportation(ex.toString());
        setLoadingExportation(false);
      }
    }

    fetchData().then();
  }, []);

  return (
    <ExportationContext.Provider value={{
      colors,
      aduanas,
      carriers,
      containers,
      errorExportation,
      loadingExportation,
    }}>
      {children}
    </ExportationContext.Provider>
  );
}
