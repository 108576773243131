const ShowWhen = ({ show, ...props}) => {
  if (show) {
    return(
      <>
        { props.children }
      </>
    );
  }

  return <></>
}

export default ShowWhen;
