import React from 'react';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

function formatNames(fullName) {
  const names = fullName?.split(" ");

  const formattedNames = names?.map((name) => {
    return name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
  });

  const formattedFullName = formattedNames?.join(" ");

  return formattedFullName;
}


const Comentarios = (props) => {

  return (
    <Alert variant="secondary">
      <p className="mb-0">
      {props.comentario}
      </p>
      <br/>
      <small>{props.fecha} by {formatNames(props.autor)}</small>

    </Alert>
      
  )
}

export default Comentarios;
