import {Button, Dropdown, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faEye, faFileExcel, faFileText, faMailBulk, faPencil} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import DetalleExportationTable from "./DetalleExportationTable";
import {
  getBillOfLadingMaster,
  getDockReceipt,
  getLetterOfIntent,
} from "../../Servicios/ExportationServices";
import {useState} from "react";
import SendEmail from "../../utils/email";
import {errorMessage} from "../../utils/toast-message";
import PdfReport from "../../utils/pdfReport";
import {ExportationStateEnum} from "../../shared/enums/exportation-state.enum";
import {enviarEmail} from "../../Servicios/SolicitudesService";
import environment from "../../environments/environment";
import ExportationAvisoForm from "./ExportationAvisoForm";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {CorreoEmbarqueEmail} from "../../Resources/HtmlEmailBody/CorreoEmbarque.email";
import ExportationContainerSelectTable from "./ExportationContainerSelectTable";
const XLSX = require("xlsx");

const ExportationTable = ({ exportations, showShippingInstruction, pageCount, currentPage, totalElements, onPageChange, onRefreshTable, onEditExportation }) => {
  const navigate = useNavigate();
  const [exportation, setExportation] = useState(undefined);
  const [exportationContainer, setExportationContainer] = useState(undefined);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState("");
  const [codshipment, setCodshipment] = useState("");
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [showAvisoForm, setShowAvisoForm] = useState(false);
  const [correosEmbarqueList, setCorreosEmbarqueList] = useState([]);
  const [correosEmbarqueCcList, setCorreosEmbarqueCcList] = useState([]);
  const [showEmailEmbarqueModal, setShowEmailEmbarqueModal] = useState(false);
  const [showExportationContainerModal, setShowExportationContainerModal] = useState(false);

  const items = element => {
    const items = [
      {
        key: '1',
        icon: <FontAwesomeIcon icon={faFileText} />,
        label: 'Generar Dock Receipt',
        onClick: () => generateDockReceivePDF(element.shipmentName),
        isVisible: validarPermisos(permisos.EXPORTATION_GENERAR_DOCK_RECEIPT),
      },
      {
        key: '2',
        icon: <FontAwesomeIcon icon={faFileText} />,
        label: 'Generar BL Master',
        onClick: () => generateBillOfLadingPDF(element.shipmentName),
        isVisible: validarPermisos(permisos.EXPORTATION_GENERAR_BL_MASTER),
      },
      {
        key: '3',
        icon: <FontAwesomeIcon icon={faEye} />,
        label: 'Ver exportación',
        onClick: () => handleVerExportacion(element),
        isVisible: element.state === ExportationStateEnum.ShippingInstruction,
      },
      {
        key: '4',
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: 'Editar exportación',
        onClick: () => handleEditarExportacion(element),
        isVisible: element.state !== ExportationStateEnum.ShippingInstruction && validarPermisos(permisos.EXPORTATION_MODIFICAR),
      },
      {
        key: '5',
        icon: <FontAwesomeIcon icon={faMailBulk} />,
        label: 'Enviar Dock Receipt',
        onClick: () => handleEnviarDockReceipt(element),
        isVisible: validarPermisos(permisos.EXPORTATION_GENERAR_DOCK_RECEIPT),
      },
      {
        key: '6',
        icon: <FontAwesomeIcon icon={faMailBulk} />,
        label: 'Enviar Correo de Embarque',
        onClick: () => handleEnviarCorreoEmbarque(element),
        isVisible: validarPermisos(permisos.EXPORTATION_GENERAR_CORREO_EMBARQUE),
      },
      {
        key: '7',
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: 'Generar Shipping Instruction',
        onClick: () => navigate(`/exportation/${element.exportationId}/shipping-instruction`),
        isVisible: element.carrier?.applyShippingInstruction &&
          (element.state === ExportationStateEnum.ShippingInstruction || element.state === ExportationStateEnum.Completado) &&
          validarPermisos(permisos.EXPORTATION_GENERAR_SHIPPING_INSTRUCTION),
      },
      {
        key: '8',
        icon: <FontAwesomeIcon icon={faFileExcel} />,
        label: 'Exportar Excel',
        onClick: () => handleExportarExcel(element),
        isVisible: true,
      },
      {
        key: '9',
        icon: <FontAwesomeIcon icon={faFileText} />,
        label: 'Generar Letter of Intent',
        onClick: () => generateLetterOfIntent(element),
        isVisible: element.carrier.letterOfIntent && validarPermisos(permisos.EXPORTATION_GENERAR_LETTER_INTENT),
      },
      {
        key: '10',
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: 'Configurar Avisos',
        onClick: () => handleShowAviso(element),
        isVisible: validarPermisos(permisos.EXPORTACION_CONFIGURAR_AVISOS)
      },
    ];

    return items.filter(e => e.isVisible);
  }

  const columns = [
    {
      title: "",
      key: "option",
      dataIndex: "option",
      width: "70px",
      fixed: 'left',
      visible: true,
      render: (_, element) => (
        <Dropdown trigger={['click']}
                  menu={{ items: items(element) }}
                  placement="bottomLeft" >
          <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
        </Dropdown>
      ),
    },
    {
      visible: true,
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      width: "100px",
      render: (_, element) => (
        <>
          <p className="mb-0">{moment(element.createDate).format('DD/MM/yyyy')}</p>
          <p className="mb-0">{moment(element.createDate).format('HH:mm:ss')}</p>
        </>
      )
    },
    {
      visible: true,
      title: "Shipment Name",
      dataIndex: "shipmentName",
      key: "shipmentName",
      width: "100px",
    },
    {
      visible: true,
      title: "Booking Number",
      dataIndex: "bookingNum",
      key: "bookingNum",
      width: "100px",
    },
    {
      visible: true,
      title: "Destino",
      width: "140px",
      dataIndex: "aduanaName",
      key: "aduanaName",
      render: (value, element) => `${value}, ${element?.aduana?.countryName}`
    },
    {
      visible: true,
      title: "Carrier",
      width: "140px",
      dataIndex: "carrierName",
      key: "carrierName",
      render: (_, element) => `${element?.carrier?.oceanCarrierName}`
    },
    {
      visible: true,
      title: "ETD",
      width: "140px",
      dataIndex: "departureDate",
      key: "departureDate",
      render: (value) => value ? <>
        <p className="mb-0">{moment(value).format('DD MMM yyyy')}</p>
      </> : '---'
    },
    {
      visible: true,
      title: "ETA",
      width: "140px",
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      render: (value) => value ? <>
        <p className="mb-0">{moment(value).format('DD MMM yyyy')}</p>
      </> : '---'
    },
    {
      visible: showShippingInstruction,
      title: "Shipping Instruction",
      width: "80px",
      dataIndex: "shippingInstruction",
      key: "shippingInstruction",
      render: (_, element) => !!element.shippingInstructionVersion
        ? <div className="badge bg-success">GENERADO</div>
        : <div className="badge bg-info">PENDIENTE</div>
    }
  ];

  const handleVerExportacion = exportation => {
    navigate(`/exportation/edit/${exportation.exportationId}`);
  }

  const handleEditarExportacion = exportation => {
    onEditExportation(exportation);
    handleVerExportacion(exportation);
  }

  const handleEnviarDockReceipt = async exportation => {
    setVisibleEmail(true);
    setCodshipment(exportation.shipmentName);

    setLoading(true);
    const res = await getDockReceipt(exportation.shipmentName);
    setLoading(false);
    setDataReport(res);
  }

  const handleEnviarCorreoEmbarque = async exportation => {
    setExportation(exportation);
    setCorreosEmbarqueList(environment.emails.correosEmbarqueHonduras);
    setCorreosEmbarqueCcList([environment.emails.honduras]);

    if (exportation?.exportationContainers?.length > 1) {
      setShowExportationContainerModal(true);
    } else {
      setExportationContainer(exportation?.exportationContainers[0]);
      setShowEmailEmbarqueModal(true);
    }
  }

  const handleCloseExportationContainerModal = () => {
    setExportation(undefined);
    setExportationContainer(undefined);
    setCorreosEmbarqueList([]);
    setCorreosEmbarqueCcList([]);
    setShowEmailEmbarqueModal(false);
    setShowExportationContainerModal(false);
  }

  const generateDockReceivePDF = async (shipmentName) => {
    setVisible(true);
    const data = await getDockReceipt(shipmentName)

    if (data.status) {
      setDataReport(data.data);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const generateBillOfLadingPDF = async (shipmentName) => {
    setVisible(true);
    const data = await getBillOfLadingMaster(shipmentName);

    if (data.status) {
      setDataReport(data.data);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const generateLetterOfIntent = async (exportation) => {
    try {
      console.log(exportation);

      setVisible(true);
      const data = await Promise.all(exportation.exportDetails.map(e => getLetterOfIntent(e.codeExpSecuential)));

      const files = data.map((e, index) => ({
        archivo: e.data,
        nombreArchivo: `${exportation.exportDetails[index].codeExpSecuential}.pdf`
      }));
      console.log(files);

      const item = await enviarEmail({
        to: [environment.emails.seabordMarine],
        body: `<p>Hi, Seaboard</p><p>Please see attached copy of the letters of intent to be signed.</p><br><p>Best Regards</p>`,
        subject: `LETTER OF INTENT BOOKING #${exportation.bookingNum}`,
        attachedFile: files
      });
      setVisible(false);
      console.log(item);
    } catch (ex) {
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const expandedRowRender = (exp) => {
    return <DetalleExportationTable detalleExportations={exp?.exportDetails}
                                    onRefreshTable={onRefreshTable} />
  };

  const handleTableChange = (pagination) => {
    if (onPageChange) onPageChange(pagination);
  }

  const handleShowAviso = (exportation) => {
    setExportation(exportation);
    setShowAvisoForm(true);
  }

  const handleExportarExcel = exportation => {
    const list = exportation.exportDetails.map((detail) => {
      return {
        '1': detail.codeExpSecuential,
        '2': detail?.werehouse?.importadora?.impName,
        '3': detail.itn,
        '4': detail.werehouse.vin,
        '5': `CBP09-ITN ${detail.itn} CERTIFICATE`,
        '6': exportation.carrier.oceanCarrierDisplayName,
        '7': exportation.portCodeLoading.port
      }
    });

    list.unshift({
      '1': 'Waybill Number',
      '2': 'Consignee',
      '3': 'ITN',
      '4': 'VIN',
      '5': 'Nombre Archivo',
      '6': 'Carrier Name',
      '7': 'Port'
    });

    list.unshift({'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''});
    list.unshift({'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''});

    list.unshift({
      '1': moment(new Date()).format('ddd MMM DD, yyyy'),
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'Listado de Hijos (USD)',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'Shipment',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'MATUS INTERNATIONAL INC.',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    const worksheet = XLSX.utils.json_to_sheet(list, {
      skipHeader: true,
    });

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Shipments");

    // Genera el archivo Excel
    XLSX.writeFile(workbook, `Shipment.xls`, {
      bookType: "xls",
      type: "blob",
    });
  }

  return(
    <>
      <Table
        columns={columns.filter(e => e.visible)}
        expandable={{expandedRowRender,  defaultExpandedRowKeys: ["0"]}}
        dataSource={exportations}
        size="small"
        scroll={{x: 1300, y: 600}}
        pagination={{
          total: totalElements,
          current: currentPage,
          pageSize: pageCount,
          onChange: handleTableChange
        }}
      />

      <SendEmail visible={visibleEmail}
                 setVisible={setVisibleEmail}
                 titulo={`Envio de Dock Receipt ${codshipment}`}
                 to={[]}
                 cc={[]}
                 cco={[]}
                 isLoading={loading}
                 subject={`Envio de Dock Receipt ${codshipment}`}
                 body={`Estimado cliente, adjunto encontrara el Dock Receipt de la exportación ${codshipment}`}
                 attachments={[
                   {
                     nombreArchivo: "Dockreceipt.pdf",
                     archivo: "data:aplication/pdf;base64," + dataReport,
                   }
                 ]} />

      <Modal open={showExportationContainerModal}
             title={'Seleccionar Contenedores'}
             width={'90%'}
             onOk={handleCloseExportationContainerModal}
             onCancel={handleCloseExportationContainerModal}>
        <ExportationContainerSelectTable exportationContainers={exportation?.exportationContainers}
                                         onSelectContainer={(exportationContainer) => {
                                     setShowEmailEmbarqueModal(true);
                                     setExportationContainer(exportationContainer);
                                   }} />
      </Modal>
      <ShowWhen show={exportation && exportationContainer && showEmailEmbarqueModal}>
        <SendEmail visible={showEmailEmbarqueModal}
                   setVisible={() => handleCloseExportationContainerModal()}
                   titulo={`Enviar Correo de Embarque ${exportationContainer?.detalleExport[0]?.codeExpSecuential}`}
                   to={correosEmbarqueList}
                   cc={correosEmbarqueCcList}
                   cco={[]}
                   isLoading={loading}
                   showMessageInput={false}
                   subject={`${exportation?.shipmentName} ${exportation?.loadingPierTerminal} BK: ${exportation?.bookingNum} ETD: ${new Date(exportation?.departureDate)?.toLocaleDateString()}`}
                   body={CorreoEmbarqueEmail(exportation, exportationContainer)} />
      </ShowWhen>

      <PdfReport visible={visible} setVisible={setVisible} data={dataReport} setData={setDataReport} />

      <Modal open={showAvisoForm}
             title={'Aviso'}
             onCancel={() => {
               setShowAvisoForm(false);
               setExportation(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ExportationAvisoForm exportation={exportation} onUpdate={() => {
          setShowAvisoForm(false);
          setExportation(undefined);
          onRefreshTable();
        }} />
      </Modal>
    </>
  );
}

export default ExportationTable;
