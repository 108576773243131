import moment from "moment";

export const NotificacionCargaEmail = (exportationContainer) =>  `
   <!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <meta name="x-apple-disable-message-reformatting">
  <title>ConfirmationRequest</title>
  <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
  <style>
    table,
    td,
    div,
    h1,
    p {
      font-family: Arial, sans-serif;
    }
  </style>
</head>

<body style="margin:0;padding:0;">
  <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
      <td align="center" style="padding:0;">
        <table role="presentation"
          style="width:1000px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
          <tr>
            <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
              <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
            </td>
          </tr>
          <tr>
            <td style="padding:5px 25px 0px 25px;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;">
                    <h1 style="text-align: center">Aviso de Carga</h1>
                    <p style="text-align: right">${moment().format('MM/DD/YYYY')}</p> 
                    <p style="margin: 0">Buenos días</p>
                    <p style="margin-top: 0">La presente es para indicar que la referencia ${exportationContainer?.shipmentName}, se encuentra COMPLETADO.</p>
                    
                    <p style="text-align: center; text-decoration: underline; font-weight: bold">DETALLES DE LA RECOLECCIÓN</p>
                    <table>
                        <tr>
                            <td style="width: 40%;">
                                <p style="margin: 0">REFERENCIA: </p>
                                <p style="margin: 0">BOOKING: </p>
                                <p style="margin: 0"># DE CONTENEDOR: </p>
                                <p style="margin: 0">NAVIERA: </p>
                                <p style="margin: 0">TRUCKER: </p>
                            </td>
                            <td style="width: 60%;">
                               <p style="margin: 0">${exportationContainer?.shipmentName}</p>
                                <p style="margin: 0">${exportationContainer?.exportation?.bookingNum}</p>
                                <p style="margin: 0">${exportationContainer?.containerNumber}</p>
                                <p style="margin: 0">${exportationContainer?.exportation?.aduanaName}</p>
                                <p style="margin: 0">${exportationContainer?.exportation?.preCarriageBy?.nombre}</p>         
                            </td>
                        </tr>
                    </table>
                    
                    <p style="margin-bottom: 0">Pedimos su apoyo en su pronta recolección</p>
                    <p style="margin-top: 0">Gracias por su apoyo</p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding:30px;background:#1C2E51;">
              <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                <tr>
                  <td style="padding:0;width:50%;" align="left">
                    <p style="color:white;">&reg; Matus International, 2023</p>
                  </td>
                  <td style="padding:0;width:50%;" align="right">
                    <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                      <tr>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.facebook.com/matusintl/"><img
                              src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.instagram.com/matusintl/?hl=en"><img
                              src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38"
                              style="height:auto;display:block;border:0;" /></a>
                        </td>
                        <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web"
                              width="28" style="height:auto;display:block;border:0;" /></a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>
    `;
