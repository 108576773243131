import {useEffect, useState} from "react";
import {
  asignarUsuarioPermiso,
  getPermissions,
  removerUsuarioPermiso
} from "../../Servicios/AdminService";
import {Switch} from "antd";
import Spinner from "../../shared/spinner";
import {errorMessage} from "../../utils/toast-message";

const UsuarioPermisoForm = ({ usuario, afterSave }) => {
  const [permisos, setPermisos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (usuario) {
      init().then();
    } else {
      setPermisos([]);
    }
  }, [usuario]);

  const init = async () => {
    if (permisos.length === 0) {
      const data = await getPermissions();
      setPermisos(data);
    }
  }

  const selected = permiso => {
    if (usuario.permisos.length === 0) return false;
    return usuario.permisos.some(e => e.id === permiso.id);
  }

  const handleChange = async permiso => {
    setLoading(true);
    if (selected(permiso)) {
      const data = await removerUsuarioPermiso({ usuarioId: usuario.idUser, permisoId: permiso.id });

      if (!data.status) {
        errorMessage(data.message);
      }
    } else {
      const data = await asignarUsuarioPermiso({ usuarioId: usuario.idUser, permisoId: permiso.id });

      if (!data.status) {
        errorMessage(data.message);
      }
    }
    setLoading(false);
    afterSave();
    init().then();
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="container-fluid">
          <div className="row">
            {
              permisos.map(permiso => {
                return (
                  <div key={`rol-permiso-${permiso.id}`} className="col-6 pb-3 mt-3 border-bottom">
                    <div className="d-flex align-items-start justify-content-between gap-1">
                      <p className="m-0 fw-bolder">{permiso.name}</p>
                      <Switch checked={selected(permiso)}
                              onChange={() => handleChange(permiso)}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </Spinner>
    </>
  );
}

export default UsuarioPermisoForm;
