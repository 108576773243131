import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import PayTransportTable from "./PayTransportTable";
import {editCoordinacionPago, showCoord} from "../../Servicios/CoordService";
import {hasRol} from "../../Servicios/AuthService";
import PayTransportFilter from "./PayTransportFilter";
import moment from "moment/moment";
import roles from "../../utils/roles";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {errorMessage} from "../../utils/toast-message";
import OrigenTab from "../../Componentes/OrigenTab/OrigenTab";

const XLSX = require("xlsx");

interface CoordinacionFilters {
  origen: number;
  search: string;
  year: number;
  month: number;
  day: Date | undefined;
  transporteId: number;
  currentTab: string;
  subastaId: number;
}

const TAB_PAGOS_DEL_DIA = 'PAGOS DEL DIA';

const localStorageFilter = (key = undefined, isDate = false) => {
  if (!localStorage.getItem('coordinacionPagosTransportista:filter')) return undefined;
  if (!key) return JSON.parse(localStorage.getItem('coordinacionPagosTransportista:filter'));
  if (!JSON.parse(localStorage.getItem('coordinacionPagosTransportista:filter'))[key]) return undefined;

  if (isDate) {
    return new Date(JSON.parse(localStorage.getItem('coordinacionPagosTransportista:filter'))[key]);
  }

  return JSON.parse(localStorage.getItem('coordinacionPagosTransportista:filter'))[key];
}

const PayTransportPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const [coordi, setCoordi] = useState([]);

  const [coordinacionFilters, setCoordinacionFilters] = useState({
    origen: localStorageFilter('origen') ?? hasRol(roles.FLOFFICE) ? 1 : 2,
    search: localStorageFilter('search') ?? "",
    year: localStorageFilter('year') ?? new Date().getFullYear(),
    month: localStorageFilter('month') ?? new Date().getMonth() + 1,
    day: localStorageFilter('day', true) ?? new Date(),
    transporteId: localStorageFilter('transporteId') ?? 0,
    currentTab: localStorageFilter('currentTab') ?? TAB_PAGOS_DEL_DIA,
    subastaId: localStorageFilter('subastaId') ?? 0,
  });

  useEffect(() => {
    showCoordinaciones(coordinacionFilters).then();

    const filters = localStorageFilter();
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('coordinacionPagosTransportista:filter', JSON.stringify(coordinacionFilters));
    }
  }, [coordinacionFilters]);

  useEffect(() => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      origen: hasRol(roles.FLOFFICE) ? 1 : 2
    });
  }, []);

  const showCoordinaciones = async (filters: CoordinacionFilters) => {
    let origenId = filters.origen;
    if (hasRol(roles.FLOFFICE)) origenId = 1;
    else if (hasRol(roles.CAOFFICE)) origenId = 2;

    setIsLoading(true);
    const data = await showCoord(
      origenId,
      "",
      filters.year,
      filters.month,
      0,
      0,
      filters.search,
      "",
      0,
      filters.day ? filters.day.getDate() : 0,
      0,
      0,
      0,
      0,
      "",
      filters.subastaId,
      0,
      filters.transporteId,
      0,
      1,
      '',
      -1,
      0,
      filters.currentTab
    );

    setIsLoading(false);

    if (data.status) {
      setCoordi(data.list);
      setTotalElements(data.totalElements);
    }
  };

  const subtotal = (key, list) => {
    return list
      .reduce((a, b) => {
        return a + (b[key] !== null && b[key] !== undefined && b[key] !== "" ? +(b[key]) : 0);
      }, 0);
  }

  const exportToExcel = async () => {
    setIsLoading(true);
    const data = await showCoord(
      coordinacionFilters.origen,
      "",
      coordinacionFilters.year,
      coordinacionFilters.month,
      0,
      0,
      coordinacionFilters.search,
      "",
      0,
      coordinacionFilters.day ? coordinacionFilters.day.getDate() : 0,
      0,
      0,
      0,
      0,
      0,
      coordinacionFilters.subastaId,
      0,
      coordinacionFilters.transporteId,
      0,
      1,
      '',
      -1,
      0,
      coordinacionFilters.currentTab
    );
    setIsLoading(false);

    if (data && data.status) {
      const list = data.list.map(e => {
        return {
          'Lote': e.lote,
          'Fecha': e.vehiculo && e.vehiculo.fecha ? moment(e.vehiculo.fecha).format('l') : '---',
          'Vehiculo': e.vehiculoName,
          'Vin': e.vin,
          'Titulo': e.vehiculo.titulo ? 'Si' : 'No',
          'Estorage Driver': e.st_Driver ? +e.st_Driver : 0,
          'Estorage Pagado': e.st_Paid ? +e.st_Paid : 0,
          'Valor Grua': e.cost ? +e.cost : 0,
          'Transporte': e.transporteName ?? 'No Asignado',
          'Ubic./Subasta': e.subs,
          'Destino': e.aduanaName,
          'Estado': e.estadoPagoTransp,
        }
      });

      list.push({
        'Lote': '',
        'Fecha': '',
        'Vehiculo': '',
        'Vin': '',
        'Titulo': '',
        'Estorage Driver': subtotal('st_Driver', data.list),
        'Estorage Pagado': subtotal('st_Paid', data.list),
        'Valor Grua': subtotal('cost', data.list),
        'Transporte': '',
        'Ubic./Subasta': '',
        'Destino': '',
        'Estado': '',
      });

      const worksheet = XLSX.utils.json_to_sheet(list);
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cell_1 = XLSX.utils.encode_cell({ r: R, c: 5 });
        const cell_2 = XLSX.utils.encode_cell({ r: R, c: 6 });
        const cell_3 = XLSX.utils.encode_cell({ r: R, c: 7 });

        if (!worksheet[cell_1]) continue;
        worksheet[cell_1].z = '"$"#,##0.00_);("$"#,##0.00)';

        if (!worksheet[cell_2]) continue;
        worksheet[cell_2].z = '"$"#,##0.00_);("$"#,##0.00)';

        if (!worksheet[cell_3]) continue;
        worksheet[cell_3].z = '"$"#,##0.00_);("$"#,##0.00)';
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Pago a Transportistas");

      // Genera el archivo Excel
      XLSX.writeFile(workbook, "coordinaciones.xlsx", {
        bookType: "xlsx",
        type: "blob",
      });
    }
  }

  const handleChangeTab = tab => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      origen: tab
    })
  };

  const  titulo = () => {
    if (!coordinacionFilters.origen) return "";
    return coordinacionFilters.origen === 1 ? ' - Florida' : ' - California';
  }

  const handleUpdateVehiculoTituloEstado = async (input) => {
    try {
      setIsLoading(true);
      await editCoordinacionPago(input.id, input);
      setIsLoading(false);

      await showCoordinaciones(coordinacionFilters);
    } catch (ex) {
      errorMessage(`${ex.toString()}`);
    }
  }

  return(
    <>
      <Row>
        <Col xs={24} md={24} lg={24} className="">
          <h5 className="mb-2">Pagos a transportistas {titulo()}</h5>
          <ShowWhen show={!hasRol(roles.FLOFFICE, roles.CAOFFICE)}>
            <OrigenTab selectedOrigen={coordinacionFilters.origen} onChange={handleChangeTab} />
          </ShowWhen>
        </Col>
      </Row>
      <PayTransportFilter coordinacionFilters={coordinacionFilters}
                                   onFilter={filter => setCoordinacionFilters(filter)} />
      <PayTransportTable totalElements={totalElements}
                         coordi={coordi}
                         isLoading={isLoading}
                         exportToExcel={exportToExcel}
                         coordinacionFilters={coordinacionFilters}
                         setCoordinacionFilters={filter => setCoordinacionFilters(filter)}
                         onUpdateVehiculoTituloEstado={handleUpdateVehiculoTituloEstado} />
    </>
  );
}

export default PayTransportPage;
