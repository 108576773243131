import { server } from "../environments/environment";
import {ApiService} from "./ApiService";


export const showVehicleLote = async (lote) => {
    // const URL = `${server}Vehiculo/buscar?lote=${lote}&pageIndex=1&pageSize=1000`
    const URL = `${server}Vehiculo?lote=${lote}`
    const response = await ApiService.get(`${URL}`)
    const data = await response.json()
    console.log(data,"lOTES")
    return data;
}
export const showSegmento = async () => {
    const URL = `${server}Segmento`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showCountries = async () => {
    const URL = `${server}Country`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showProvinces = async () => {
    const URL = `${server}Country/province`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showImportadora = async (search = "", currentPage = 0, pageCount = 0) => {
    const URL = `${server}Importadora`

    let params = {
        currentPage: currentPage,
        pageCount: pageCount,
    }

    if (search !== "") params['search'] = search;

    let queryParams = new URLSearchParams(params).toString();
    const response = await ApiService.get(`${URL}?${queryParams}`)

    return response.json();
}

export const showAduana = async (countryId = 0) => {
    const URL = `${server}Aduana`
    let response;
    response = await ApiService.get(`${URL}?countryId=${countryId}`);
    const data = await response.json()
    return data.list;
}

export const showOrigen = async () => {
    const URL = `${server}Origen`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showTransp = async () => {
    const URL = `${server}Transporte`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showVehicle = async () => {
    const URL = `${server}Vehiculo`
    const response = await ApiService.get(URL)
    const data = await response.json()
    return data.list;
}

export const showVehiclesoli = async (estado = "") => {
    const URL = `${server}Vehiculo`
    let response;
    response = await ApiService.get(`${URL}?estado=${estado}`);
    const data = await response.json()
    return data.list;
}

export const showVehicleWr = async ( origen = 0, estado = "") => {
    const URL = `${server}Vehiculo`
    let response;
    response = await ApiService.get(`${URL}?origen=${origen}&estado=${estado}`);
    const data = await response.json()
    return data.list;
}

export const showSubasta = async (search = "", currentPage = 0, pageCount = 0) => {
    const URL = `${server}Subasta`

    let params = {
        currentPage: currentPage,
        pageCount: pageCount,
    }

    if (search !== "") params['search'] = search;

    let queryParams = new URLSearchParams(params).toString();
    const response = await ApiService.get(`${URL}?${queryParams}`)

    return response.json();
}
