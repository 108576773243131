import {Button, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";

const ExportationContainerSelectTable = ({ exportationContainers, onSelectContainer }) => {
  const columns = [
    {
      title: "",
      key: "option",
      dataIndex: "option",
      render: (_, element) => <Button type={'primary'}
                                      shape="circle"
                                      onClick={() => onSelectContainer(element)}>
        <FontAwesomeIcon icon={faMailBulk} size={'sm'}/>
      </Button>
    },
    {
      title: "Shipment Ref",
      key: "codeExpSecuential",
      dataIndex: "codeExpSecuential",
      render: (_, element) => element?.detalleExport[0]?.codeExpSecuential
    },
    {
      title: "# Contenedor",
      key: "containerNumber",
      dataIndex: "containerNumber",
    },
    {
      title: "# Sello",
      key: "sealNumber",
      dataIndex: "sealNumber",
    },
    {
      title: "Contenedor",
      key: "nameContainer",
      dataIndex: "nameContainer",
      render: (_, element) => element?.container?.nameContainer,
    },
    {
      title: "Destino",
      key: "nameAduana",
      dataIndex: "nameAduana",
      render: (_, element) => `${element?.aduana?.nameAduana}, ${element?.aduana?.countryName}`,
    },
    {
      title: "Carrier",
      key: "oceanCarrierName",
      dataIndex: "oceanCarrierName",
      render: (_, element) => element?.carrier?.oceanCarrierName,
    },
  ];

  return(
    <Table columns={columns}
           dataSource={exportationContainers}
           size="small"/>
  );
}

export default ExportationContainerSelectTable;
