import React, { useEffect, useState } from "react";
// todo lo nuevo
import { Layout, Menu } from "antd";
import menuItem from "../utils/menu";
import logoblanco from '../image/logoblanco.png';

const { Sider } = Layout;


const Sidebar = () => {

  return (
    <Sider breakpoint="lg" collapsedWidth="0" collapsible width={250} 
    >
      <img
        src={logoblanco}
        width="160"
        height="59"
        style={{margin:10}}
      />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={menuItem}
      />
    </Sider>
  );
};

export default Sidebar;
