import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getVehicleInput,
  listColor,
  listMake,
} from "../../Servicios/VehicleService";
import moment from "moment/moment";
import useCurrentUser from "../../Hooks/UserHook";
import InputSearchCustomer from "../InputSearchCustomer/InputSearchCustomer";
import ShowWhen from "../ShowWhen/ShowWhen";
import { VehiculoEstadoInspeccionEnum } from "../../shared/enums/vehiculo-estado-inspeccion.enum";
import { getUserReceiving } from "../../Servicios/UsuarioService";
import {
  showQuotePoridvehi,
} from "../../Servicios/SolicitudesService";
import Spinner from "../../shared/spinner";

const VehiculoInspeccionForm = ({
  origen,
  title,
  vehicle,
  showCustomerField,
  isLoading,
  onSubmit,
  onCancel,
  isReceiving,
  newVehicle,
  showInspection,
  showInspectionField,
}) => {
  const currentUser = useCurrentUser();
  const [form] = Form.useForm();

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [colors, setColors] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const [inputMake, setInputMake] = useState("");
  const [inputModel, setInputModel] = useState("");
  const [inputColor, setInputColor] = useState("");
  const [inputCustomer, setInputCustomer] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState(null);
  const [fileEvidence, setFileEvidence] = useState({
    titulo: [
      {
        uid: "-1",
        name: "xxx.png",
        status: "done",
        url: "http://www.baidu.com/xxx.png",
      },
    ],
    frontal: [],
    vin: [],
  });

  const [switchData, setSwitchData] = useState({
    titulo: false,
    llave: false,
    bio: false,
    catalitico: false,
    keyinside: false,
    radio: false,
    llantaResp: false,
    bateria: false,
    cristalDelantero: false,
    ventanaIzquierda: false,
    cristalTrasero: false,
    ventanaDerecha: false,
    billofSale: false,
    openDoor: false,
    openCapo: false,
    tienePiezasInterior: false,
    factura: false,
  });
  const fechaActual = moment().format("YYYY-MM-DDTHH:mm:ss");

  useEffect(() => {
    if (!currentUser) return;
    if (newVehicle) {
      form.resetFields();
    }
    if (vehicle) {
      console.log("vehicle", vehicle);
      form.setFieldsValue({
        fecha: fechaActual,
        vin: vehicle.vin,
        lote: vehicle.lote,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        color: vehicle.color,
        updateBy: vehicle.updateBy,
        notas: vehicle.notas,
        inspeccionadoPor: vehicle.inspeccionadoPor,
      });

      setSwitchData({
        titulo: vehicle.titulo,
        llave: vehicle.llave,
        bio: vehicle.bio,
        catalitico: vehicle.catalitico,
        keyinside: vehicle.keyinside,
        radio: vehicle.radio,
        llantaResp: vehicle.llantaResp,
        bateria: vehicle.bateria,
        cristalDelantero: vehicle.cristalDelantero,
        ventanaIzquierda: vehicle.ventanaIzquierda,
        cristalTrasero: vehicle.cristalTrasero,
        ventanaDerecha: vehicle.ventanaDerecha,
        billofSale: vehicle.billofSale,
        openDoor: vehicle.openDoor,
        openCapo: vehicle.openCapo,
        tienePiezasInterior: vehicle.tienePiezasInterior,
        factura: vehicle.factura,
      });

      setFileEvidence({
        titulo: vehicle.imagenTitulo
          ? [
              {
                url: vehicle.imagenTitulo,
                name: vehicle.imagenTitulo.split("/").pop(),
              },
            ]
          : [],
        frontal: vehicle.imagenFrontal
          ? [
              {
                url: vehicle.imagenFrontal,
                name: vehicle.imagenFrontal.split("/").pop(),
              },
            ]
          : [],
        vin: vehicle.imagenVin
          ? [
              {
                url: vehicle.imagenVin,
                name: vehicle.imagenVin.split("/").pop(),
              },
            ]
          : [],
      });
    } else {
      form.setFieldsValue({
        fecha: moment().format("YYYY-MM-DDTHH:mm:ss"),
        updateBy: "",
      });

      setSwitchData({
        titulo: false,
        llave: false,
        bio: false,
        catalitico: false,
        keyinside: false,
        radio: false,
        llantaResp: false,
        bateria: false,
        cristalDelantero: false,
        ventanaIzquierda: false,
        cristalTrasero: false,
        ventanaDerecha: false,
        billofSale: false,
        openDoor: false,
        openCapo: false,
        tienePiezasInterior: false,
        factura: false,
      });

      setFileEvidence({
        titulo: [],
        frontal: [],
        vin: [],
      });
    }

    init().then();
  }, [vehicle, currentUser, newVehicle]);

  const init = async () => {
    setLoading(true);
    const makes = await listMake();
    const colors = await listColor();
    const usuarios = await getUserReceiving();
    if (isReceiving) {
      setLoading(true);
      const quote = await showQuotePoridvehi(vehicle.vehiculoId).finally(() => {
        setLoading(false);
      });
      setQuote(quote);
    }
    const list = usuarios.filter((e) => !origen || e.ubicationId === +origen);

    setMakes(makes);
    setColors(colors);
    setUsuarios(list);
    setLoading(false);
    if (list.some((e) => e.fullName === currentUser.fullName)) {
      form.setFieldValue("updateBy", currentUser.fullName);
    }
  };

  const onChange = (value, key) => {
    form.setFieldValue(key, value);

    if (key === "make") {
      setModels(makes.find((e) => e.make === value).models);
    }
  };

  const onInputMakeChange = (event) => {
    setInputMake(event.target.value);
  };

  const onInputModelChange = (event) => {
    setInputModel(event.target.value);
  };

  const onInputColorChange = (event) => {
    setInputColor(event.target.value);
  };

  const addNewMake = (e) => {
    e.preventDefault();

    if (inputMake !== "" && !makes.some((e) => e.make === inputMake)) {
      setMakes([...makes, { make: inputMake, models: [] }]);

      form.setFieldValue("make", inputMake);
    }

    setInputMake("");
  };

  const addNewModel = (e) => {
    e.preventDefault();

    if (inputModel !== "" && !models.some((e) => e === inputModel)) {
      setModels([...models, inputModel]);

      form.setFieldValue("model", inputModel);
    }

    setInputModel("");
  };

  const onSearch = (value, key) => {
    form.setFieldValue(key, value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSubmit = async (value) => {
    setLoading(true);

    delete value.imagenTitulo;
    delete value.imagenFrontal;
    delete value.imagenVin;

    const input = {
      ...getVehicleInput(vehicle),
      ...value,
      ...switchData,
      year: +value.year,
      origen: vehicle ? vehicle.origen : currentUser?.idubc,
      clienteId: inputCustomer ? inputCustomer.id : null,
      destinoCode: vehicle ? vehicle.destinoCode : "",
      idTitle: vehicle ? vehicle.idTitle : null,
      ubicacion: vehicle ? vehicle.ubicacion : null,
      fotoFile: fileEvidence,
    };

    if (onSubmit) {
      onSubmit(input, quote);
      setFileEvidence({
        titulo: [],
        frontal: [],
        vin: [],
      });

      setQuote(null);
      form.resetFields();
      setLoading(false);
    }
  };

  const handleSwitch = (key, value) => {
    setSwitchData({
      ...switchData,
      [key]: value,
    });
  };

  return (
    <>
      <h5>{title}</h5>
      <br />
      <Spinner loading={loading || isLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <ShowWhen show={showCustomerField && !vehicle}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-3">
                <p className="mb-2">Seleccionar Cliente</p>
                <InputSearchCustomer
                  filterByVipCustomer={true}
                  onSelectedCustomer={(customer) => setInputCustomer(customer)}
                />
              </Col>
            </ShowWhen>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Fecha Recibido" name="fecha">
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="VIN" name="vin">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="LOTE" name="lote">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Marca"
                name="make"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  showSearch={true}
                  placeholder={"Buscar marca"}
                  onChange={(e) => onChange(e, "make")}
                  onSearch={(e) => onSearch(e, "make")}
                  filterOption={filterOption}
                  options={makes.map((e) => ({ value: e.make, label: e.make }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="flex-grow-1">
                            <Input
                              placeholder="Please enter make name"
                              value={inputMake}
                              onChange={onInputMakeChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                          </div>
                          <div className="flex-grow-0">
                            <Button type="text" onClick={addNewMake}>
                              Agregar
                            </Button>
                          </div>
                        </div>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Modelo"
                name="model"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  showSearch={true}
                  placeholder={"Buscar modelo"}
                  onChange={(e) => onChange(e, "model")}
                  onSearch={(e) => onSearch(e, "model")}
                  filterOption={filterOption}
                  options={models.map((model) => ({
                    value: model,
                    label: model,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="flex-grow-1">
                            <Input
                              placeholder="Please enter model name"
                              value={inputModel}
                              onChange={onInputModelChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                          </div>
                          <div className="flex-grow-0">
                            <Button type="text" onClick={addNewModel}>
                              Agregar
                            </Button>
                          </div>
                        </div>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Año"
                name="year"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Color" name="color">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={8} sm={8} md={6} lg={6} xl={6}>
              <Form.Item label="Titulo" valuePropName="checked">
                <Switch
                  checked={switchData.titulo}
                  onChange={(checked) => handleSwitch("titulo", checked)}
                />
              </Form.Item>
            </Col>

            <Col xs={8} sm={8} md={6} lg={6} xl={6}>
              <Form.Item label="Factura" valuePropName="checked">
                <Switch
                  checked={switchData.factura}
                  onChange={(checked) => handleSwitch("factura", checked)}
                />
              </Form.Item>
            </Col>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Llave" valuePropName="checked">
                  <Switch
                    checked={switchData.llave}
                    onChange={(checked) => handleSwitch("llave", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="BioH" valuePropName="checked">
                  <Switch
                    checked={switchData.bio}
                    onChange={(checked) => handleSwitch("bio", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Radio" valuePropName="checked">
                  <Switch
                    checked={switchData.radio}
                    onChange={(checked) => handleSwitch("radio", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Catalitico" valuePropName="checked">
                  <Switch
                    checked={switchData.catalitico}
                    onChange={(checked) => handleSwitch("catalitico", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Bateria" valuePropName="checked">
                  <Switch
                    checked={switchData.bateria}
                    onChange={(checked) => handleSwitch("bateria", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Bill of sale?" valuePropName="checked">
                  <Switch
                    checked={switchData.billofSale}
                    onChange={(checked) => handleSwitch("billofSale", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Abre capo?" valuePropName="checked">
                  <Switch
                    checked={switchData.openCapo}
                    onChange={(checked) => handleSwitch("openCapo", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Abre puerta?" valuePropName="checked">
                  <Switch
                    checked={switchData.openDoor}
                    onChange={(checked) => handleSwitch("openDoor", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Llanta Resp." valuePropName="checked">
                  <Switch
                    checked={switchData.llantaResp}
                    onChange={(checked) => handleSwitch("llantaResp", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="KeyInside" valuePropName="checked">
                  <Switch
                    checked={switchData.keyinside}
                    onChange={(checked) => handleSwitch("keyinside", checked)}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item label="Cristal Back" valuePropName="checked">
                  <Switch
                    checked={switchData.cristalTrasero}
                    onChange={(checked) =>
                      handleSwitch("cristalTrasero", checked)
                    }
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Cristales rotos?, Front"
                  valuePropName="checked"
                >
                  <Switch
                    checked={switchData.cristalDelantero}
                    onChange={(checked) =>
                      handleSwitch("cristalDelantero", checked)
                    }
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Ventana Izquierda rota?"
                  valuePropName="checked"
                >
                  <Switch
                    checked={switchData.ventanaIzquierda}
                    onChange={(checked) =>
                      handleSwitch("ventanaIzquierda", checked)
                    }
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Ventana Derecha rota?"
                  valuePropName="checked"
                >
                  <Switch
                    checked={switchData.ventanaDerecha}
                    onChange={(checked) =>
                      handleSwitch("ventanaDerecha", checked)
                    }
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <ShowWhen show={showInspection}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Trae algún material en el interior?"
                  valuePropName="checked"
                >
                  <Switch
                    checked={switchData.tienePiezasInterior}
                    onChange={(checked) =>
                      handleSwitch("tienePiezasInterior", checked)
                    }
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            {switchData.titulo && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Subir evidencias de título">
                  <Upload
                    maxCount={1}
                    fileList={fileEvidence.titulo}
                    beforeUpload={(file) => {
                      setFileEvidence({ ...fileEvidence, titulo: [file] });
                      return false;
                    }}
                    onRemove={() =>
                      setFileEvidence({ ...fileEvidence, titulo: null })
                    }
                  >
                    <Button>Subir título</Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Subir evidencias de Frontal"
                name="imagenFrontal"
              >
                <Upload
                  maxCount={1}
                  fileList={fileEvidence.frontal}
                  beforeUpload={(file) => {
                    setFileEvidence({ ...fileEvidence, frontal: [file] });
                    return false;
                  }}
                  onRemove={() =>
                    setFileEvidence({ ...fileEvidence, frontal: null })
                  }
                >
                  <Button>Subir Frontal</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Subir evidencias de Vin" name="imagenVin">
                <Upload
                  maxCount={1}
                  fileList={fileEvidence.vin}
                  beforeUpload={(file) => {
                    setFileEvidence({ ...fileEvidence, vin: [file] });
                    return false;
                  }}
                  onRemove={() =>
                    setFileEvidence({ ...fileEvidence, vin: null })
                  }
                >
                  <Button>Subir Vin</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Recibido Por:"
                name="updateBy"
                rules={[{ required: true, message: "Este campo es requerido" }]}
              >
                <Select
                  className="w-100"
                  showSearch={true}
                  disabled={!newVehicle && !isReceiving && showInspection} // validarlo con carlos para ver el funcionamiento del disabled de momento queda en enabled para todas los usos del modal
                  filterOption={filterOption}
                  placeholder={"Seleccionar usuario"}
                  options={usuarios.map((usuario) => {
                    return { value: usuario.fullName, label: usuario.fullName };
                  })}
                  onChange={(value) => onChange(value, "updateBy")}
                />
              </Form.Item>
            </Col>

            <ShowWhen show={showInspectionField}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Inspeccionado Por:"
                  name="inspeccionadoPor"
                  rules={
                    showInspection && vehicle
                      ? [{ required: true, message: "Este campo es requerido" }]
                      : null
                  }
                >
                  <Select
                    className="w-100"
                    showSearch={true}
                    disabled={
                      vehicle &&
                      vehicle.estadoInspeccion !==
                        VehiculoEstadoInspeccionEnum.noInspeccionado
                    }
                    filterOption={filterOption}
                    placeholder={"Seleccionar usuario"}
                    options={usuarios.map((usuario) => {
                      return {
                        value: usuario.fullName,
                        label: usuario.fullName,
                      };
                    })}
                    onChange={(value) => onChange(value, "inspeccionadoPor")}
                  />
                </Form.Item>
              </Col>
            </ShowWhen>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Notas" name="notas">
                <Input.TextArea />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <label className="fw-bold">Subir evidencias de título, frontal del vehículo y documento</label>
                <UploadGoogleDrive
                  vehiculo={vehicle}
                  fechaRecibido={vehicle?.fecha ? vehicle?.fecha : fechaActual}
                  tipoEvidencia={"raiz"}
                  triggerUpload={(method) => setUploadMethod(() => method)}
                />
            </Col> */}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Button
                onClick={() => {
                  form.resetFields();
                  if (onCancel) onCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={0} md={0} lg={0} xl={0}>
              <br />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Button type="primary" htmlType="submit" block>
                {vehicle ? (isReceiving ? "Recibir" : "Actualizar") : "Guardar"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spinner>
    </>
  );
};

export default VehiculoInspeccionForm;
