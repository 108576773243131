import {server} from "../environments/environment";
import {ApiService} from "./ApiService";
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";

const URL = `${server}Clientes`;

export const listClients = async (countriesId = "", segmentoId = 0, currentPage = 0, pageCount = 0, name = '', type = '',
                                  clienteVip = 0, clasificacion = 0, deudor = -1, clienteParticular = -1) => {
  let dataParams = {
    segmentoId: segmentoId,
    currentPage: currentPage,
    pageCount: pageCount,
    clienteVip: clienteVip,
    clasificacion: clasificacion,
    deudor: deudor,
    clienteParticular: clienteParticular
  };

  if (name !== "") {
    dataParams['name'] = name;
  }

  if (countriesId !== "") {
    dataParams['countriesId'] = countriesId;
  }

  if (type !== "") {
    dataParams['type'] = type;
  } else {
    dataParams['type'] = ClienteTypeEnum.typeCustomer;
  }

  const params = new URLSearchParams(dataParams);
  const response = await ApiService.get(`${URL}/list?${params.toString()}`);

  return response.json()
}

export const addClient = async (client) => {
  const response = await ApiService.post(`${server}Clientes`, client);
  const data = await response.json();

  if (data.status) {
    return data.data;
  } else {
    throw Error(data.message)
  }
}

export const viewClient = async (id) => {
  try {
    const response = await ApiService.get(URL + "/" + id);
    const data = await response.json()
    const client = data.data;

    if (client?.birth && client?.birth.indexOf("T00:00:00") !== -1) {
      client.birth = client.birth.substring(0, client.birth.indexOf("T00:00:00"));
    }

    if (client?.venciPoa && client?.venciPoa.indexOf("T00:00:00") !== -1) {
      client.venciPoa = client.venciPoa.substring(0, client.venciPoa.indexOf("T00:00:00"));
    }

    if (client?.expIdent && client?.expIdent.indexOf("T00:00:00") !== -1) {
      client.expIdent = client.expIdent.substring(0, client.expIdent.indexOf("T00:00:00"));
    }

    if (client?.fechaCreacion && client?.fechaCreacion.indexOf("T00:00:00") !== -1) {
      client.fechaCreacion = client.fechaCreacion.substring(0, client.fechaCreacion.indexOf("T00:00:00"));
    }
    return client;

  } catch (ex) {
    throw new Error(ex);
  }
}

export const editClient = async (id, client) => {
  const response = await ApiService.put(`${URL}/${id}`, client);
  return response.json();
}

export const deleteClient = async (id) => {
  const response = await ApiService.delete(`${URL}/${id}`);
  return response.json();
}

export const clientsActivity = async (meses, countryId = 0) => {
  const response = await ApiService.get(`${URL}/clientsActivity/${meses}?countryId=${countryId}`);
  return response.json();
}

export const clienteSetPassword = async (id, password) => {
  const response = await ApiService.post(`${URL}/${id}/setPassword`, { password });
  return response.json();
}

export const clienteSetDeudor = async (id, deudor) => {
  const response = await ApiService.post(`${URL}/${id}/setDeudor`, { deudor });
  return response.json();
}
