import React, {useEffect, useState} from "react";
import { Row, Col } from "antd";
import {getExportationPdf} from "../../Servicios/ExportationServices";
import {errorMessage} from "../../utils/toast-message";
import Spinner from "../../shared/spinner";

const ExportationPdfViewer = ({exportation}) => {

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleImprimir(exportation).then();
  }, [exportation]);


  const handleImprimir = async (exportation) => {
    setLoading(true);
    const data = await getExportationPdf(exportation.exportationId)
      .finally(() => setLoading(false));

    if (data.status) {
      setData(data.data);
    } else {
      errorMessage(data.message);
    }
  }

  return (
    <Spinner loading={loading}>
      <Row style={{ marginTop: 10 }}>
        <Col md={24} sm={24} xs={24}>
          <iframe
            src={`data:application/pdf;base64,${data}`}
            width="100%"
            height={500}
            title="pdf"
          />
        </Col>
      </Row>
    </Spinner>
  );
}

export default ExportationPdfViewer;
