export const WarehouseReceiptEmail = (infowere) => `
<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>Solicitud de recoleccion</title>
    <!--[if mso]>
    <noscript>
        <xml>
            <o:OfficeDocumentSettings>
                <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
    </noscript>
    <![endif]-->
    <style>
      table, td, div, h1, p {
        font-family: Arial, sans-serif;
      }
    </style>
</head>
<body style="margin:0;padding:0;">
<table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
        <td align="center" style="padding:0;">

            <table role="presentation"
                   style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
                <tr>
                    <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                        <img src="https://portalmatus.com/web/static/media/logoblanco.827dc793290383d30762.png" alt=""
                             width="300" style="height:auto;display:block;"/>
                    </td>
                </tr>
                <tr>
                    <td style="padding:10px 30px 10px 30px;">
                        <table role="presentation"
                               style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="padding:0;">
                                    <h1 align="center">Vehículo recibido en bodega<br/>Matus International<br/>${infowere?.origenName}</h1>
                                    <p>Hola Estimado cliente,<br/><br/> Le escribo para informarle que ya tenemos su
                                        <strong> ${infowere?.vehiculoName} </strong> en nuestra bodega y se va embarcar
                                        lo mas pronto posible.<br/><br/>
                                        Por favor tome nota que su vehículo tiene:<br/></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <table role="presentation"
                                           style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Titulo:</strong> ${infowere?.titulo}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Llave:</strong> ${infowere?.llave}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Factura:</strong> ${infowere?.tieneFactura ? "SI" : "NO"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Poa:</strong> ${infowere?.poaWR}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <p>Si desea conocer el estado en el que hemos recibido su vehículo favor darle click
                                        en el siguiente enlace:<br/><br/>${infowere?.linkFotos}
                                        <br/>
                                        <br/>
                                        Gracias por escoger a Matus International como su embarcador preferido.<br/>
                                        <br/>
                                        Saludos cordiales,</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="padding:30px;background:#1C2E51;">
                        <table role="presentation"
                               style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="padding:0;width:50%;" align="left">
                                    <p style="color:white;">&reg; Matus International, 2023</p>
                                </td>
                                <td style="padding:0;width:50%;" align="right">
                                    <table role="presentation"
                                           style="border-collapse:collapse;border:0;border-spacing:0;">
                                        <tr>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                                <a href="https://www.facebook.com/matusintl/"><img
                                                        src="https://portalmatus.com/facebook-white.png" alt="Instagram"
                                                        width="38" style="height:auto;display:block;border:0;"/></a>
                                            </td>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                                <a href="https://www.instagram.com/matusintl/?hl=en"><img
                                                        src="https://portalmatus.com/instagram-white.png" alt="Facebook"
                                                        width="38" style="height:auto;display:block;border:0;"/></a>
                                            </td>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                                <a href="https://www.matusintl.com/"><img
                                                        src="https://portalmatus.com/web-white.png" alt="Web" width="28"
                                                        style="height:auto;display:block;border:0;"/></a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
</body>
</html>
`;