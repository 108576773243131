import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import WalletCountCard from "../../../Componentes/widgets/wallletCountCard";
import { useTheme } from "@mui/material/styles";
import { getClientsCountByCountry } from "../../../Servicios/DashboardService";

import salvador from "../../../image/pais/salvador.png";
import costarica from "../../../image/pais/costarica.png";
import guatemala from "../../../image/pais/guatemala.png";
import nicaragua from "../../../image/pais/nicaragua.png";
import honduras from "../../../image/pais/honduras.png";

const Wallets = ({ user }) => {
  const theme = useTheme();

  const [isloading, setIsloading] = useState(false);

  const [clientS, setClientS] = useState(0);
  const [clientG, setClientG] = useState(0);
  const [clientCR, setClientCR] = useState(0);
  const [clientN, setClientN] = useState([0]);
  const [clientHN, setClientHN] = useState(0);

  const totalS = clientS;
  const totalG = clientG;
  const totalCR = clientCR;
  const totalN = clientN;
  const totalHN = clientHN;

  const clientsCountByCountry = async () => {
    const data = await getClientsCountByCountry();

    setClientS(data[0].count);
    setClientG(data[1].count);
    setClientCR(data[2].count);
    setClientN(data[3].count);
    setClientHN(data[4].count);
  };

  useEffect(() => {
    setIsloading(true);
    Promise.all([clientsCountByCountry()]).finally(() => {
      setIsloading(false);
    });
  }, [user]);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}  style={{
      justifyContent: "center",
    }}>
      <Col
        className="gutter-row"
        style={{
          marginBottom: "20px",
        }}
      >
        <WalletCountCard
          primary="Cartera de clientes El Salvador"
          secondary={totalS}
          iconPrimary={salvador}
          color={theme.palette.info.light}
          loading={isloading}
        />
      </Col>
      <Col
        className="gutter-row"
        style={{
          marginBottom: "20px",
        }}
      >
        <WalletCountCard
          primary="Cartera de Clientes Guatemala"
          secondary={totalG}
          iconPrimary={guatemala}
          color={theme.palette.primary.main}
          loading={isloading}
        />
      </Col>
      <Col
        className="gutter-row"
        style={{
          marginBottom: "20px",
        }}
      >
        <WalletCountCard
          primary="Cartera de Clientes Costa Rica"
          secondary={totalCR}
          iconPrimary={costarica}
          color={theme.palette.error.light}
          loading={isloading}
        />
      </Col>
      <Col
        className="gutter-row"
        style={{
          marginBottom: "20px",
        }}
      >
        <WalletCountCard
          primary="Cartera de Clientes Nicaragua"
          secondary={totalN}
          iconPrimary={nicaragua}
          color={theme.palette.info.dark}
          loading={isloading}
        />
      </Col>
      <Col
        className="gutter-row"
        style={{
          marginBottom: "20px",
        }}
      >
        <WalletCountCard
          primary="Cartera de Clientes Honduras"
          secondary={totalHN}
          iconPrimary={honduras}
          color={theme.palette.info.dark}
          loading={isloading}
        />
      </Col>
    </Row>
  );
};

export default Wallets;
