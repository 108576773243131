/** @format */

import { combineReducers } from "redux";
import { exportationSlice } from "./exportation";
import { wareHouseSlice } from "./whareHouse";
import { solicitudSlice } from "./solicitud";

const rootReducer = combineReducers({
    exportation: exportationSlice.reducer,
    wareHouse: wareHouseSlice.reducer,
    solicitud: solicitudSlice.reducer
})

export default rootReducer;