import React, {useEffect, useState} from "react";
import './styleformaes.css';
import {useParams} from "react-router-dom";
import Spinner from '../../shared/spinner';
import {
  getItn, getDetalleExportById
} from '../../Servicios/ExportationServices';
import moment from "moment";
import Form from "react-bootstrap/Form";
import {Switch, Tag, Button, Modal, Card} from "antd";
import {sendGsf} from '../../Servicios/ExportationServices';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getFetchAllExportation} from "../../store/exportation";
import useCurrentUser from "../../Hooks/UserHook";
import {errorMessage, successMessage} from "../../utils/toast-message";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {DetalleExportacionStateEnum} from "../../shared/enums/detalle-exportacion-state.enum";
import SubastaForm from "../../Componentes/SubastaForm/SubastaForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faRefresh} from "@fortawesome/free-solid-svg-icons";
import ImportadoraForm from "../../Componentes/ImportadoraForm/ImportadoraForm";
import InputSearchImportadora from "../../Componentes/InputSearchImportadora/InputSearchImportadora";
import {editWerehouseImportadora, editWerehouseSubasta} from "../../Servicios/WerehouseService";
import InputSearchSubasta from "../../Componentes/InputSearchSubasta/InputSearchSubasta";

const SendToAES = () => {
  const {id} = useParams();
  
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [subasta, setSubasta] = useState(undefined);
  const [importadora, setImportadora] = useState(undefined);
  const [detalleExport, setDetalleExport] = useState(undefined);
  const [subastaChange, setSubastaChange] = useState(undefined);
  const [newSubastaChange, setNewSubastaChange] = useState(undefined);
  const [importadoraChange, setImportadoraChange] = useState(undefined);
  const [newImportadoraChange, setNewImportadoraChange] = useState(undefined);

  const [valueRCC, setValueRCC] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valueHazmatIndicator, setValueHazmatIndicator] = useState(false);
  const [valueRoutedTransactionIndicator, setValueRoutedTransactionIndicator] = useState(true);
  
  const onChangeValueRCC = (e) => {
    setValueRCC(e);
  };

  const onChangeValueHazmatIndicator = (e) => {
    setValueHazmatIndicator(e);
  };

  const onChangeValueRoutedTransactionIndicator = (e) => {
    setValueRoutedTransactionIndicator(e);
  };

  const getDetalleExport = async () => {
    setIsLoading(true);
    const data = await getDetalleExportById(id);
    setIsLoading(false);

    setDetalleExport(data.list[0]);
  };

  useEffect(() => {
    if (id) {
      getDetalleExport().then();
    }
  }, [id]);

  const handleSubmit = async () => {
    if (!detalleExport.subasta.statesV) {
      errorMessage('El State de la subasta no ha sido especificado');
      return;
    }

    if (!detalleExport.subasta.identf) {
      errorMessage('El campo Identificador de la subasta es requerido');
      return;
    }

    if (!detalleExport.subasta.identfType) {
      errorMessage('El campo Tipo de Identificador de la subasta es requerido');
      return;
    }

    const newdata = {
      relatedCompany: valueRCC,
      hazmatIndicator: valueHazmatIndicator,
      routedTransactionIndicator: valueRoutedTransactionIndicator,
    };

    setIsLoading(true);
    const data1 = await sendGsf(id, newdata);
    setIsLoading(false);

    if (data1 && data1.status) {
      successMessage('Datos enviados correctamente, espere el correo de confirmación para validar que el ITN se encuentre generado');
      await dispatch(getFetchAllExportation(currentUser?.idubc));
      navigate('/exportation/list')
    } else {
      errorMessage(data1.message);
    }
  }

  const handleValidarAES = async () => {
    const response = await getItn(detalleExport?.codeExpSecuential);

    if (response && response.status) {
      successMessage('ITN generado con exito');
    } else {
      errorMessage('No existe ITN');
    }
  };

  const handleChangeSubasta = async () => {
    try {
      const input = {
        subastaId: newSubastaChange.subId,
      };

      setIsLoading(true);
      await editWerehouseSubasta(detalleExport?.werehouse?.werehouseId, input);
      setIsLoading(false);

      setSubastaChange(undefined);
      setNewSubastaChange(undefined);

      getDetalleExport().then();
    } catch (ex) {
      setIsLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleChangeImportadora = async () => {
    try {
      const input = {
        importadoraId: newImportadoraChange.id,
      };

      setIsLoading(true);
      await editWerehouseImportadora(detalleExport?.werehouse?.werehouseId, input);
      setIsLoading(false);

      setImportadoraChange(undefined);
      setNewImportadoraChange(undefined);

      getDetalleExport().then();
    } catch (ex) {
      setIsLoading(false);
      errorMessage(ex.toString());
    }
  }

  return (
    <>
      <Spinner loading={isLoading}>
        <div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Button type="primary"
                    icon={<ArrowLeftOutlined/>}
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => navigate('/exportation/list')}>Regresar al listado</Button>
            <h5>
              EEI Information Summary - Web EEI - {id}
            </h5>

            <Tag color={
              detalleExport?.stateSend === "ENVIADO" ? "skyblue" : detalleExport?.stateSend === "ACEPTADO" ? "gree" : "red"

            }>{
              detalleExport?.stateSend === "ENVIADO" ? "ENVIADO" : detalleExport?.stateSend === "ACEPTADO" ? "ACEPTADO" : "NO ENVIADO"
            }</Tag>

          </div>
          <Form className='formAES'>
            <table className="table table-sm table-bordered mb-0">
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="2">General Information - Required By AES</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>ITN Number</td>
                <td></td>
              </tr>
              <tr>
                <td>Shipment Number</td>
                <td>{detalleExport?.codeExpSecuential}</td>
              </tr>
              <tr>
                <td>Filing Option</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Booking Number</td>
                <td>{detalleExport?.exportation?.bookingNum}</td>
              </tr>
              <tr>
                <td>State of Origin</td>
                <td>{detalleExport?.werehouse?.subasta?.statesV?.smallState}</td>
              </tr>
              <tr>
                <td>Port of Export</td>
                <td>{detalleExport?.portCodeLoading?.name} ({detalleExport?.portCodeLoading?.port})</td>
              </tr>
              <tr>
                <td>Country of Destination</td>
                <td>{detalleExport?.exportation?.country?.code}, {detalleExport?.exportation?.country?.nameCountry}</td>
              </tr>
              <tr>
                <td>Departure Date</td>
                <td>{moment(detalleExport?.exportation?.departureDate).format('ll')}</td>
              </tr>
              <tr>
                <td>Port of Unloading</td>
                <td>{detalleExport?.portCodeUnloading?.name} ({detalleExport?.portCodeUnloading?.port})</td>
              </tr>
              <tr>
                <td>Mode of Transport</td>
                <td>{detalleExport?.modeExport?.name}</td>
              </tr>
              <tr>
                <td>Carrier</td>
                <td>{detalleExport?.carrier?.oceanCarrierName}</td>
              </tr>
              <tr>
                <td>Conveyance Name</td>
                <td>{detalleExport?.exportation?.vesselNameFlag}</td>
              </tr>
              <tr>
                <td>Related Companies</td>
                <td>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false}
                          onChange={onChangeValueRCC}/>
                </td>
              </tr>
              <tr>
                <td>Hazardous cargo</td>
                <td>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false}
                          onChange={onChangeValueHazmatIndicator}/>
                </td>
              </tr>
              <tr>
                <td>Routed Transaction Flag</td>
                <td>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={true}
                          onChange={onChangeValueRoutedTransactionIndicator}/>
                </td>
              </tr>
              <tr>
                <td>Inbond Type</td>
                <td>70</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="2">
                  <div className="d-flex align-items-center justify-content-between">
                    U.S Principal Party in Interest - Required By AES
                    <div>
                      <Button type="link" onClick={() => setSubastaChange(detalleExport?.werehouse?.subasta)}>
                        <FontAwesomeIcon className="me-2" icon={faRefresh}/> Cambiar
                      </Button>
                      <Button type="link" onClick={() => setSubasta(detalleExport?.werehouse?.subasta)}>
                        <FontAwesomeIcon className="me-2" icon={faPencil}/> Editar
                      </Button>
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Name</td>
                <td>{detalleExport?.werehouse?.subasta?.subName}</td>
              </tr>
              <tr>
                <td>ID Number</td>
                <td>462329112</td>
              </tr>
              <tr>
                <td>ID Type</td>
                <td>EIN</td>
              </tr>
              <tr>
                <td>Address Line 1</td>
                <td>{detalleExport?.werehouse?.subasta?.addressLine1Sub}</td>
              </tr>
              <tr>
                <td>Address Line 2</td>
                <td>{detalleExport?.werehouse?.subasta?.addressLine2Sub}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{detalleExport?.werehouse?.subasta?.city}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{detalleExport?.werehouse?.subasta?.countryCode}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{detalleExport?.werehouse?.subasta?.statesV?.smallState}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{detalleExport?.werehouse?.subasta?.zipCode}</td>
              </tr>
              <tr>
                <td>Contact First Name</td>
                <td>{detalleExport?.werehouse?.subasta?.contactFirstName}</td>
              </tr>
              <tr>
                <td>Contact Last Name</td>
                <td>{detalleExport?.werehouse?.subasta?.contactLastName}</td>
              </tr>
              <tr>
                <td>Contact Phone</td>
                <td>{detalleExport?.werehouse?.subasta?.phoneNumber}</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="2">
                  <div className="d-flex align-items-center justify-content-between">
                    Ultimated Consignee - Required By AES
                    <div>
                      <Button type="link" onClick={() => setImportadoraChange(detalleExport?.werehouse?.importadora)}>
                        <FontAwesomeIcon className="me-2" icon={faRefresh}/> Cambiar
                      </Button>
                      <Button type="link" onClick={() => setImportadora(detalleExport?.werehouse?.importadora)}>
                        <FontAwesomeIcon className="me-2" icon={faPencil}/> Editar
                      </Button>
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Name</td>
                <td>{detalleExport?.importadora?.impName}</td>
              </tr>
              <tr>
                <td>Contact Name</td>
                <td>{detalleExport?.importadora?.impName}</td>
              </tr>
              <tr>
                <td>Contact Phone</td>
                <td>{detalleExport?.importadora?.phoneNumber}</td>
              </tr>
              <tr>
                <td>Address Line 1</td>
                <td>{detalleExport?.importadora?.addressLine1}</td>
              </tr>
              <tr>
                <td>Address Line 2</td>
                <td>{detalleExport?.importadora?.addressLine2}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{detalleExport?.importadora?.city}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{detalleExport?.importadora?.country}</td>
              </tr>
              <tr>
                <td>Postal Code</td>
                <td>{detalleExport?.importadora?.postalCode}</td>
              </tr>
              <tr>
                <td>Ultimate Consignee Type</td>
                <td>O</td>
              </tr>
              <tr>
                <td>SoId En Route</td>
                <td>N No</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="2">Freight Forwarder</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Name</td>
                <td>MATUS INTERNATIONAL INC</td>
              </tr>
              <tr>
                <td>ID Number</td>
                <td>462329112</td>
              </tr>
              <tr>
                <td>ID Type</td>
                <td>FIN</td>
              </tr>
              <tr>
                <td>Contact Name</td>
                <td>ALLAN MATUS</td>
              </tr>
              <tr>
                <td>Contact Phone</td>
                <td>5624355200</td>
              </tr>
              <tr>
                <td>Address Line 1</td>
                <td>{detalleExport?.origen?.addressLine1}</td>
              </tr>
              <tr>
                <td>Address Line 2</td>
                <td>{detalleExport?.origen?.addressLine2}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{detalleExport?.origen?.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{detalleExport?.origen?.nameOrigen}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{detalleExport?.origen?.countries?.nameCountry}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{detalleExport?.origen?.zipCode}</td>
              </tr>
              </tbody>
            </table>
            <table className="table table-sm table-bordered mb-0">
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="7">Description of Goods</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <p className="m-0">{detalleExport?.exportation?.descriptionGoods}</p>
                  <p className="m-0">{detalleExport?.werehouse?.vehiculoName}</p>
                  <p className="m-0">{detalleExport?.werehouse?.vin}</p>

                  <ShowWhen show={detalleExport?.extraItems?.length > 0}>
                    {
                      detalleExport?.extraItems?.map((item, key) => {
                        return <p className="m-0" key={key}>{item?.quantity} {item?.extraItemName}</p>
                      })
                    }
                  </ShowWhen>
                </td>
              </tr>
              </tbody>
            </table>
            <table className="table table-sm table-bordered mb-0">
              <thead>
              <tr>
                <th className="bg-dark bg-opacity-10 fw-bold" colSpan="7">List Items</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className="fw-bold">Number</td>
                <td className="fw-bold">Export Code</td>
                <td className="fw-bold">US Dollar</td>
                <td className="fw-bold">First Unit</td>
                <td className="fw-bold">Quantity</td>
                <td className="fw-bold">Gross Weight Kg</td>
              </tr>
              <tr>
                <td className='itemsinfo'>{detalleExport?.werehouse?.quantity}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.codExp}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.valorV}</td>
                <td className='itemsinfo'>NO</td>
                <td className='itemsinfo'>1</td>
                <td className='itemsinfo'>
                  {
                    detalleExport?.werehouse?.hasVehicle
                      ? detalleExport?.werehouse?.weightKg?.toFixed(2)
                      : detalleExport?.werehouse?.extraItems?.[0]?.weight?.toFixed(2)
                  }
                </td>
              </tr>
              <tr>
                <td className="fw-bold">License Type</td>
                <td className="fw-bold">License Number</td>
                <td className="fw-bold">License Value</td>
                <td className="fw-bold">Description</td>
                <td className="fw-bold">Commodity Code</td>
                <td className="fw-bold">Marks and Number</td>
              </tr>
              <tr>
                <td className='itemsinfo'>{detalleExport?.werehouse?.tipoLic}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.licenceNum}</td>
                <td className='itemsinfo'>0</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.vehiculoName}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.scheduleBname}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.codigo}</td>
              </tr>
              <tr>
                <td className="fw-bold">Vehicle</td>
                <td className="fw-bold">Vehicle ID Type</td>
                <td className="fw-bold">Vehicle ID</td>
                <td className="fw-bold">Vehicle Title</td>
                <td className="fw-bold">Vehicle Title State</td>
                <td className="fw-bold">Origin</td>
              </tr>
              <tr>
                <td className='itemsinfo'>{detalleExport?.werehouse?.hasVehicle ? "Yes" : "No"}</td>
                <td className='itemsinfo'>VIN</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.vin}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.numTitle}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.smallState}</td>
                <td className='itemsinfo'>{detalleExport?.werehouse?.originName}</td>
              </tr>
              </tbody>
            </table>
            <div className="mt-3">
              <ShowWhen show={detalleExport?.stateSend === DetalleExportacionStateEnum.NoEnviado}>
                <Button type={'primary'} block onClick={handleSubmit}>Send to AES</Button>
              </ShowWhen>
              <ShowWhen show={detalleExport?.stateSend === DetalleExportacionStateEnum.Enviado}>
                <Button type={'primary'} block onClick={handleValidarAES}>Validar AES</Button>
              </ShowWhen>
            </div>
          </Form>
        </div>
      </Spinner>

      <Modal open={!!subasta}
             title={'Modificar'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <SubastaForm subasta={subasta}
                     titulo={'Principal Party'}
                     afterSave={() => {
                       setSubasta(undefined);
                       getDetalleExport().then();
                     }}
                     onCancel={() => setSubasta(undefined)} />
      </Modal>

      <Modal open={!!importadora}
             title={'Modificar'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm titulo={'Ultimate Consignee'}
                         importadora={importadora}
                         afterSubmit={() => {
                           getDetalleExport().then();
                           setImportadora(undefined);
                         }}
                         onCancel={() => setImportadora(undefined)} />
      </Modal>

      <Modal open={!!subastaChange}
             title={'Cambiar Principal Party'}
             onCancel={() => {
               setSubastaChange(undefined);
               setNewSubastaChange(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Spinner loading={isLoading}>
          <Card className="mb-3">
            <p className="m-0 fw-bold">Principal Party Actual</p>
            <p className="m-0">Nombre: {subastaChange?.subName}</p>
            <p className="m-0">Dirección: {subastaChange?.direccion}, {subastaChange?.codeState} {subastaChange?.zipCode}</p>
            <p className="m-0">Ciudad: {subastaChange?.city}</p>
            <p className="m-0">País: {subastaChange?.countryName}</p>
            <p className="m-0">Telefono: {subastaChange?.phoneNumber}</p>
            <p className="m-0">Contacto: {subastaChange?.contactFirstName} {subastaChange?.contactLastName}</p>
          </Card>

          <InputSearchSubasta onSelectSubasta={subasta => setNewSubastaChange(subasta)} />
          <div className="text-end">
            <Button className="mt-3" type={'primary'} onClick={handleChangeSubasta}>Modificar</Button>
          </div>
        </Spinner>
      </Modal>

      <Modal open={!!importadoraChange}
             title={'Cambiar Ultimated Consignee'}
             onCancel={() => {
               setImportadoraChange(undefined);
               setNewImportadoraChange(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Spinner loading={isLoading}>
          <Card className="mb-3">
            <p className="m-0 fw-bold">Principal Party Actual</p>
            <p className="m-0">Nombre: {importadoraChange?.impName}</p>
            <p className="m-0">Dirección: {importadoraChange?.direccion} {subastaChange?.countryCode} {subastaChange?.postalCode}</p>
            <p className="m-0">Ciudad: {importadoraChange?.city}</p>
            <p className="m-0">País: {importadoraChange?.country}</p>
            <p className="m-0">Telefono: {importadoraChange?.phoneNumber}</p>
          </Card>

          <InputSearchImportadora onSelectImportadora={importadora => setNewImportadoraChange(importadora)} />
          <div className="text-end">
            <Button className="mt-3" type={'primary'} onClick={handleChangeImportadora}>Modificar</Button>
          </div>
        </Spinner>
      </Modal>
    </>
  )
}

export default SendToAES;
