import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import { showTransport, addTransporte } from '../Servicios/ComponedoresService';
import { faFileCirclePlus, faLeftLong, faPenToSquare, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Container, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { viewTransp, editTransp } from '../Servicios/ComponedoresService';
import SpinnerTable from '../Componentes/SpinnerTable';

const FilterComponent = ({ filterText, onFilter }) => (
  <>
      <Form.Control
          className="input-buscar"
          id="search"
          type="text"
          placeholder="Buscar Transportista"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
      
  />
  
  </>
);

const Transporte = () => {

const [showT, setShowT] =useState(false);
const [showE, setShowE] = useState(false);

const [isLoading, setIsLoading] = useState(false);

const handleCloseT = () => setShowT(false);
const handleShowT = () => setShowT(true);

const handleCloseE = () => setShowE(false);
const handleShowE = () => setShowE(true);

const [ transport, setTransport ] = useState([]);

const [ idtrans, setIdtrans ] = useState([]);
  
const mostraTransports = async () => {
  setIsLoading(true);
  const data = await showTransport().finally(() => {setIsLoading(false)});
  setTransport(data);
}

const [addTransports, setAddTransports] = useState({
  id:"",
  nomComp:"",
  contacto: "",
  telefono:"",
})

useEffect ( () => {
  mostraTransports();  
}, [])  

useEffect ( () => {
  mostTrans(idtrans)
  }, [idtrans]) 
  
  const mostTrans = (id) => {
    setIdtrans(id)
    if (id != undefined && id != 0)
    viewTransp(id).then((trnasp) => {
      setAddTransports(trnasp)
        handleShowE();
}) 
  }

const handleChange=e=>{
  const {name, value}=e.target;
  setAddTransports({
      ...addTransports,
      [name]:value
  });
}

const insertarTransport = async () => {
  try {
      const data = await addTransporte({
          ...addTransports
      });
      handleCloseT();
  } catch (ex) {
      console.log(ex);
  }
}

const editarTras = async () => {
  try {
      const data = await editTransp(idtrans, {
          ...addTransports,
      });
      if (data) {
        handleCloseE();
        mostraTransports();
      }
  } catch (ex) {
      console.log(ex);
  }
} 

const columns =[
  {
    name: 'Editar',
    width: "100px",
    cell: (row)=><Button onClick={() => mostTrans(row?.transporteId)}  variant="primary">
    <FontAwesomeIcon icon={faPenToSquare} />
    </Button>
    },
    {
        name: 'Compañia de Transporte',
        selector: row => row.nomComp,
        width: "200px"
    },
    {
        name: 'Conductor',
        selector: row => row.contacto,
        width: "200px"
    },
    {
        name: 'Numero de contacto',
        selector: row => row.telefono,
        width: "200px"
    },
  ]

const [filterText, setFilterText] = useState('');
const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
const filteredItems = transport.filter(
		item => item.nomComp && item.nomComp.toString().toLowerCase().includes(filterText.toLowerCase()),
        );

const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    return (

        
        <Container>
            <Row>
                <Col xs={12} md={5}>
                    <Form.Label className='input-buscar'>Seleccione Transportita para Editar </Form.Label>
                    <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                </Col>
            </Row>
            <br/>
        </Container>
          
    
    );
}, [filterText, resetPaginationToggle]);

  return (
    <Container >
        <Col md={{ span: 7, offset: 3 }}>
        <Button as={Link} to='/home' variant="warning">
                            <FontAwesomeIcon icon={faLeftLong} />
                            &nbsp;Regresar
                            </Button>
                            {' '}&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={handleShowT} variant="primary">
              <FontAwesomeIcon icon={faFileCirclePlus} />
              &nbsp;Agregar Nuevo Transportita
      </Button>
      {' '}&nbsp;
      <br/>
      <br/>
      <h2>Visualizando Transportistas</h2>
      <DataTable  
        columns={columns}
        data={filteredItems}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={isLoading}
        progressComponent={<SpinnerTable />}
        persistTableHead
        pagination
        /> 
      
      <>
      <Modal
        show={showT}
        onHide={handleCloseT}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Transportista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form.Label>Nombre de la Compañia</Form.Label>
        <Form.Control onChange={handleChange} name='nomComp' type="text"  />
        <Form.Label>Nombre del Conductor</Form.Label>
        <Form.Control onChange={handleChange} name='contacto' type="text"  />
        <Form.Label>Telefono de Contacto</Form.Label>
        <Form.Control onChange={handleChange} name='telefono' type="text"  />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseT}>
            Cancelar
          </Button>
          <Button onClick={() => insertarTransport()} variant="primary">Guardar</Button>
        </Modal.Footer>
      </Modal>
        </>

        <>
      <Modal
        show={showE}
        onHide={handleCloseE}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editando Transportista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form.Label>Nombre de la Compañia</Form.Label>
        <Form.Control onChange={handleChange} value={addTransports?.nomComp} name='nomComp' type="text"  />
        <Form.Label>Nombre del Conductor</Form.Label>
        <Form.Control onChange={handleChange} value={addTransports?.contacto} name='contacto' type="text"  />
        <Form.Label>Telefono de Contacto</Form.Label>
        <Form.Control onChange={handleChange} value={addTransports?.telefono} name='telefono' type="text"  />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseE}>
            Cancelar
          </Button>
          <Button onClick={() => editarTras()} variant="primary"> <FontAwesomeIcon icon={faFloppyDisk} /> Actualizar</Button>
        </Modal.Footer>
      </Modal>
        </>
        </Col>
  </Container>
  )
}

export default Transporte;
