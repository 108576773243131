import {Alert, Card, Image, Space, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {getMediaListFolderId} from "../../Servicios/GoogleService";

const VehicleEvidenceList = ({ lote }) => {
  const [media, setMedia] = useState([]);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    if (lote && lote !== "") {
      getMedia().then();
    } else {
      setMedia([]);
    }
  }, [lote]);

  const getMedia = async () => {
    setIsLoadingImage(true);
    const response = await getMediaListFolderId(lote)
      .finally(() => {
        setIsLoadingImage(false);
      });

    console.log("response", response);
    setMedia(response.data || []);
  }

  return(
    <>
      <Spin tip="Cargando Imagenes..." spinning={isLoadingImage}>
        {
          media.length === 0 ? (
            <Alert message="No hay evidencia para este lote"
                   description="Porfavor suba evidencia para este lote."
                   type="warning"
                   showIcon/>
          ) : (
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`)
          }}
          >
            {media?.map((item) =>
              item.mimeType.includes("image/") ? (
                <Space
                  // add espacio entre imagenes
                  direction="vertical"
                  size="middle"
                  style={{
                    padding: 10,
                  }}
                >
                  {/* add icon image and nombre how title align horizontal and image botton title*/}
                  <Card
                    hoverable
                    style={{ width: "100%", maxWidth: 240 }} // Make the card responsive by setting the max-width
                    cover={
                      <iframe src={`https://drive.google.com/file/d/${item.id}/preview`} width="500" height="400" allow="autoplay"></iframe>
                    }
                  >
                    <Card.Meta
                      title={item.name}
                      description={item.mimeType}
                    />
                  </Card>
                </Space>
              ) : (
                <Space
                  // add espacio entre imagenes
                  direction="vertical"
                  size="middle"
                  style={{
                    padding: 10,
                  }}
                >
                  {/* add icon image and nombre how title align horizontal and image botton title*/}
                  <Card
                    hoverable
                    style={{ width: "100%", maxWidth: 240 }} // Make the card responsive by setting the max-width
                    cover={
                      <iframe src={`https://drive.google.com/file/d/${item.id}/preview`} width="500" height="400" allow="autoplay"></iframe>
                    }
                  >
                    <Card.Meta
                      title={item.name}
                      description={item.mimeType}
                    />
                  </Card>
                </Space>
              )
            )}
          </Image.PreviewGroup>
        )}
      </Spin>
    </>
  );
}

export default VehicleEvidenceList;
