export const NotificarSolicitudDatosCompletados = (solicitud, cliente) => `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        
        <title>Solicitud en Revisión</title>
           
        <style>
            table, td, div, h1, p {font-family: Arial, sans-serif;}
        </style>
    </head>
    <body style="margin:0;padding:0;">
        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
          <tr>
            <td align="center" style="padding:0;">
                <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
                    <tr>
                        <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                            <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top:0;padding-left:16px;padding-right:16px;">
                            <h3>Solicitud Lote #${solicitud.lote}</h3>
                            <h4>Cliente: ${cliente}</h4>
                            <h4>Asesor: ${solicitud.registradoBy}</h4>
                            
                            <p>Los datos de la solicitud con lote #${solicitud.lote} han sido completados por el usuario ${solicitud.registradoBy}</p>
                            <p>Solicitud perteneciente a la coordinación #${solicitud.coorAsign}</p>
                        </td>
                    </tr>
                </table>
            </td>
          </tr>
        </table>
    </body>
</html>`;
