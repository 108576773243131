import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getExportationIdData, shippingInstruction} from "../../Servicios/ExportationServices";
import Spinner from "../../shared/spinner";
import moment from "moment/moment";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import PortCodeForm from "../PortCode/PortCodeForm";
import ImportadoraForm from "../../Componentes/ImportadoraForm/ImportadoraForm";
import {Button, Modal} from "antd";
import SubastaForm from "../../Componentes/SubastaForm/SubastaForm";
import {errorMessage, successMessage} from "../../utils/toast-message";
import useCurrentUser from "../../Hooks/UserHook";

const ExportationShippingInstruction = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [version, setVersion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [subasta, setSubasta] = useState(undefined);
  const [portCode, setPortCode] = useState(undefined);
  const [importadora, setImportadora] = useState(undefined);
  const [exportation, setExportation] = useState(undefined);

  useEffect(() => {
    init().then();
  }, [id]);

  const init = async () => {
    setLoading(true);
    const data = await getExportationIdData(id);
    setLoading(false);

    setExportation(data);
    setVersion((data.shippingInstructionVersion ?? 0) + 1);

    setDisabled((data.bookingNum == null || data.bookingNum === "") ||
      (data.vesselNameFlag == null || data.vesselNameFlag === "") ||
      (data.voyageIdentification == null || data.voyageIdentification === "") ||
      (data.portCodeLoading == null) ||
      (data.portCodeLoading.name == null || data.portCodeLoading.name === "") ||
      (data.portCodeLoading.portUnlocode == null || data.portCodeLoading.portUnlocode === "") ||
      (data.portCodeUnloading == null) ||
      (data.portCodeUnloading.name == null || data.portCodeUnloading.name === "") ||
      (data.portCodeUnloading.portUnlocode == null || data.portCodeUnloading.portUnlocode === "") ||
      (data.exportation.servic == null || data.exportation.servic === "") ||
      (data.exportation.servic.code == null || data.exportation.servic.code === "") ||
      (data.exportation.carrier == null) ||
      (data.exportation.carrier.oceanCarrierName == null || data.exportation.carrier.oceanCarrierName === "") ||
      (data.exportation.carrier.oceanCarrierCode == null || data.exportation.carrier.oceanCarrierCode === "") ||
      (data.exportation.shipper == null) ||
      (data.exportation.shipper.subName == null || data.exportation.shipper.subName === "") ||
      (data.exportation.shipper.city == null || data.exportation.shipper.city === "") ||
      (data.exportation.shipper.zipCode == null || data.exportation.shipper.zipCode === "") ||
      (data.exportation.shipper.phoneNumber == null || data.exportation.shipper.phoneNumber === "") ||
      (data.exportation.shipper.country == null) ||
      (data.exportation.shipper.country.code == null || data.exportation.shipper.country.code === "") ||
      (data.exportation.ultimateConsignee == null) ||
      (data.exportation.ultimateConsignee.impName == null || data.exportation.ultimateConsignee.impName === "") ||
      (data.exportation.ultimateConsignee.city == null || data.exportation.ultimateConsignee.city === "") ||
      (data.exportation.ultimateConsignee.phoneNumber == null || data.exportation.ultimateConsignee.phoneNumber === "") ||
      (data.exportation.ultimateConsignee.countryCode == null || data.exportation.ultimateConsignee.countryCode === "") ||
      (data.exportation.forwardingAgent == null) ||
      (data.exportation.forwardingAgent.subName == null || data.exportation.forwardingAgent.subName === "") ||
      (data.exportation.forwardingAgent.city == null || data.exportation.forwardingAgent.city === "") ||
      (data.exportation.forwardingAgent.zipCode == null || data.exportation.forwardingAgent.zipCode === "") ||
      (data.exportation.forwardingAgent.phoneNumber == null || data.exportation.forwardingAgent.phoneNumber === "") ||
      (data.exportation.forwardingAgent.country == null) ||
      (data.exportation.forwardingAgent.country.code == null || data.exportation.forwardingAgent.country.code === "") ||
      (data.exportation.notifyParty == null) ||
      (data.exportation.notifyParty.impName == null || data.exportation.notifyParty.impName === "") ||
      (data.exportation.notifyParty.city == null || data.exportation.notifyParty.city === "") ||
      (data.exportation.notifyParty.phoneNumber == null || data.exportation.notifyParty.phoneNumber === "") ||
      (data.exportation.notifyParty.countryCode == null || data.exportation.notifyParty.countryCode === "")
    );
  }

  const handleSelectSubasta = (subasta) => setSubasta(subasta);
  const handleSelectPortCode = (portCode) => setPortCode(portCode);
  const handleSelectImportadora = (importadora) => setImportadora(importadora);

  const handleUpdateSubasta = () => {
    setSubasta(undefined);
    init().then();
  }

  const handleUpdatePortCode = () => {
    setPortCode(undefined);
    init().then();
  }

  const handleUpdateImportadora = () => {
    setImportadora(undefined);
    init().then();
  }

  const missingInformation = () => <p className="mb-0 fw-bold text-danger">Missing Information</p>;

  const parties = () => {
    const importadoraParty = (importadora) => {
      const address = ((importadora?.addressLine1 ?? "") + " " + (importadora?.addressLine1 ?? "")).trim();
      const street = (importadora?.direccion ?? "").trim();

      if (!importadora) return <tr>
        <td colSpan={2}>
          <div className="d-flex align-items-center justify-content-start">
            {missingInformation()}
            <FontAwesomeIcon icon={faPencil}
                             className="ms-3 cursor-pointer text-danger"
                             onClick={() => navigate(`/exportation/edit/${exportation?.shipmentName}`)} />
          </div>
        </td>
      </tr>

      return(
        <>
          <tr>
            <td className="w-50">Name</td>
            <td className="w-50">{importadora?.impName ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Contact Name</td>
            <td className="w-50">{importadora?.impName ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Phone Number</td>
            <td className="w-50">
              <ShowWhen show={importadora?.phoneNumber}>{importadora?.phoneNumber}</ShowWhen>
              <ShowWhen show={!importadora?.phoneNumber}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {importadora ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectImportadora(importadora)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">Address</td>
            <td className="w-50">
              <ShowWhen show={address !== ""}>
                {address}
              </ShowWhen>
              <ShowWhen show={address === ""}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {importadora ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectImportadora(importadora)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">City</td>
            <td className="w-50">{importadora?.city ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Street</td>
            <td className="w-50">
              <ShowWhen show={street !== ""}>
                {street}
              </ShowWhen>
              <ShowWhen show={street === ""}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {importadora ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectImportadora(importadora)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">Country Code</td>
            <td className="w-50">{importadora?.countryCode ?? missingInformation()}</td>
          </tr>
        </>
      );
    }

    const subastaParty = (subasta) => {
      const address = ((subasta?.addressLine1Sub ?? "") + " " + (subasta?.addressLine2Sub ?? "")).trim();
      const street = (subasta?.direccion ?? "").trim();

      if (!subasta) return <tr>
        <td colSpan={2}>
          <div className="d-flex align-items-center justify-content-start">
            {missingInformation()}
            <FontAwesomeIcon icon={faPencil}
                             className="ms-3 cursor-pointer text-danger"
                             onClick={() => navigate(`/exportation/edit/${exportation?.shipmentName}`)} />
          </div>
        </td>
      </tr>

      return(
        <>
          <tr>
            <td className="w-50">Name</td>
            <td className="w-50">{subasta?.subName ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Contact Name</td>
            <td className="w-50">{subasta?.subName ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Phone Number</td>
            <td className="w-50">
              <ShowWhen show={subasta?.phoneNumber}>
                {subasta?.phoneNumber}
              </ShowWhen>
              <ShowWhen show={!subasta?.phoneNumber}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {subasta ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectSubasta(subasta)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">Address</td>
            <td className="w-50">
              <ShowWhen show={address !== ""}>
                {address}
              </ShowWhen>
              <ShowWhen show={address === ""}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {subasta ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectSubasta(subasta)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">City</td>
            <td className="w-50">{subasta?.city ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Postal Code</td>
            <td className="w-50">{subasta?.zipCode ?? missingInformation()}</td>
          </tr>
          <tr>
            <td className="w-50">Street</td>
            <td className="w-50">
              <ShowWhen show={street !== ""}>
                {street}
              </ShowWhen>
              <ShowWhen show={street === ""}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  {subasta ? <FontAwesomeIcon icon={faPencil}
                                    className="ms-3 cursor-pointer text-danger"
                                    onClick={() => handleSelectSubasta(subasta)}/> : ''}
                </div>
              </ShowWhen>
            </td>
          </tr>
          <tr>
            <td className="w-50">Country Code</td>
            <td className="w-50">{subasta?.country?.code ?? missingInformation()}</td>
          </tr>
        </>
      );
    }

    return (
      <table className="table table-sm table-bordered mb-0">
        <tbody>
        <tr>
          <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">PARTIES</td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold">Carrier</td>
        </tr>
        <tr>
          <td className="w-50">Name</td>
          <td className="w-50">{exportation?.carrier?.oceanCarrierName ?? missingInformation()}</td>
        </tr>
        <tr>
          <td className="w-50">Code</td>
          <td className="w-50">{exportation?.carrier?.oceanCarrierCode ?? missingInformation()}</td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold">Requestor</td>
        </tr>
        <tr>
          <td className="w-50">Name</td>
          <td className="w-50">Matus International, Inc.</td>
        </tr>
        <tr>
          <td className="w-50">Code</td>
          <td className="w-50">MATUS</td>
        </tr>
        <tr>
          <td className="w-50">Email</td>
          <td className="w-50">{currentUser?.email}</td>
        </tr>
        <tr>
          <td className="w-50">Contact Name</td>
          <td className="w-50">ALLAN MATUS</td>
        </tr>
        <tr>
          <td className="w-50">Address</td>
          <td className="w-50">
            <p className="mb-0">13399 NW 113TH AVE. ROAD</p>
            <p className="mb-0">MEDLEY, FL. 33178</p>
          </td>
        </tr>
        <tr>
          <td className="w-50">City</td>
          <td className="w-50">MEDLEY</td>
        </tr>
        <tr>
          <td className="w-50">Postal Code</td>
          <td className="w-50">33178</td>
        </tr>
        <tr>
          <td className="w-50">Country Code</td>
          <td className="w-50">US</td>
        </tr>
        <tr>
          <td className="w-50">Documentation</td>
          <td className="w-50">
            <p className="mb-0">Bill Of Lading Original, Freighted (1)</p>
            <p className="mb-0">Bill Of Lading Original, Unfreighted (1)</p>
            <p className="mb-0">Bill Of Lading Copy, Freighted (1)</p>
            <p className="mb-0">Bill Of Lading Copy, Unfreighted (1)</p>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold">Shipper</td>
        </tr>
        {subastaParty(exportation?.shipper)}
        <tr>
          <td colSpan={2} className="fw-bold">Consignee</td>
        </tr>
        {importadoraParty(exportation?.ultimateConsignee)}
        <tr>
          <td colSpan={2} className="fw-bold">Freight Forwarder</td>
        </tr>
        {subastaParty(exportation?.forwardingAgent)}
        <tr>
          <td colSpan={2} className="fw-bold">Notify Party</td>
        </tr>
        {importadoraParty(exportation?.notifyParty)}
        </tbody>
      </table>
    );
  }

  const routingInformation = () => {
    const ports = (port) => {
      if (!port) {
        return(
          <>
            <tr>
              <td colSpan={2}>
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  <FontAwesomeIcon icon={faPencil}
                                   className="ms-3 cursor-pointer text-danger"
                                   onClick={() => navigate(`/exportation/edit/${exportation?.shipmentName}`)}/>
                </div>
              </td>
            </tr>
          </>
        );
      }

      return (
        <>
          <tr>
            <td className="w-50">Location Code</td>
            <ShowWhen show={port?.portUnlocode !== ""}>
              <td className="w-50">{port?.portUnlocode}</td>
            </ShowWhen>
            <ShowWhen show={port?.portUnlocode === ""}>
              <td className="w-50">
                <div className="d-flex align-items-center justify-content-start">
                  {missingInformation()}
                  <FontAwesomeIcon className="ms-3 text-danger cursor-pointer"
                                   icon={faPencil}
                                   onClick={() => handleSelectPortCode(port)}/>
                </div>
              </td>
            </ShowWhen>
          </tr>
          <tr>
            <td className="w-50">Location Name</td>
            <td className="w-50">{port?.name}</td>
          </tr>
          <tr>
            <td className="w-50">Country</td>
            <td className="w-50">{port?.country}</td>
          </tr>
        </>
      );
    }

    return (
      <table className="table table-sm table-bordered mb-0">
        <tbody>
        <tr>
          <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">ROUTING INFORMATION</td>
        </tr>
        <tr>
          <td className="w-50">Vessel Name</td>
          <td className="w-50">{exportation?.vesselNameFlag ?? missingInformation()}</td>
        </tr>
        <tr>
          <td className="w-50">Trip Number</td>
          <td className="w-50">{exportation?.voyageIdentification ?? missingInformation()}</td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold">Port of Loading</td>
        </tr>
        { ports(exportation?.portCodeLoading) }
        <tr>
          <td colSpan={2} className="fw-bold">Port of Unloading</td>
        </tr>
        { ports(exportation?.portCodeUnloading) }
        </tbody>
      </table>
    );
  }

  const equipmentDetails = () => {
    return (
      <table className="table table-sm table-bordered mb-0">
        <tbody>
        <tr key={'-1'}>
          <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">EQUIPMENT DETAILS</td>
        </tr>
        {
          exportation?.exportationContainers.map((exportationContainer, index) => {
            return (
              <>
                <tr>
                  <td colSpan={2} className="fw-bold">Line # {index + 1}</td>
                </tr>
                <tr>
                  <td className="w-50">Container Number</td>
                  <td className="w-50">{exportationContainer?.containerNumber ?? missingInformation()}</td>
                </tr>
                <tr>
                  <td className="w-50">Seal Number</td>
                  <td className="w-50">{exportationContainer?.sealNumber ?? missingInformation()}</td>
                </tr>
                <tr>
                  <td className="w-50">Equipment Type</td>
                  <td className="w-50">{exportationContainer?.container.nameContainer ?? missingInformation()}</td>
                </tr>
              </>
            )
          })
        }
        </tbody>
      </table>
    );
  }

  const goodsDetails = () => {
    return (
      <table className="table table-sm table-bordered">
        <tbody>
        <tr key={'-1'}>
          <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">GOODS DETAILS</td>
        </tr>
        {
          exportation?.exportDetails?.map((detail, index) => {
            return (
              <>
                <tr>
                  <td colSpan={2} className="fw-bold">Line {index + 1}</td>
                </tr>
                <tr>
                  <td className="w-50">Item Code</td>
                  <td className="w-50">{detail?.werehouse?.scheduleB?.codigo ?? missingInformation()}</td>
                </tr>
                <tr>
                  <td className="w-50">Number of Packages</td>
                  <td className="w-50">1</td>
                </tr>
                <tr>
                  <td className="w-50">Package Type Code</td>
                  <td className="w-50">VN</td>
                </tr>
                <tr>
                  <td className="w-50">Package Type Description</td>
                  <td className="w-50">Vehicle (Small)</td>
                </tr>
                <tr>
                  <td className="w-50">Package Detail</td>
                  <td className="w-50">
                    <p className="mb-0">{detail?.werehouse?.vehiculoName}</p>
                    <p className="mb-0">{detail?.werehouse?.vin}</p>
                  </td>
                </tr>
                <tr>
                  <td className="w-50">Gross Weight</td>
                  <td className="w-50">{detail?.werehouse?.weight} LBS</td>
                </tr>
                <tr>
                  <td className="w-50">Gross Volume</td>
                  <td className="w-50">{detail?.werehouse?.volume} FTQ</td>
                </tr>
                <tr>
                  <td className="w-50">Transaction Reference Number (ITN)</td>
                  <td className="w-50">
                    {detail.itn ?? missingInformation()}
                  </td>
                </tr>
              </>
            )
          })
        }
        </tbody>
      </table>
    );
  }

  const handleShippingInstruction = async () => {
    setLoading(true);
    const data = await shippingInstruction(exportation.exportationId);
    setLoading(false);

    if (data && data.status) {
      successMessage('Shipping Instruction generado correctamente');
      navigate(-1);
    } else {
      errorMessage(data.message);
    }
  }

  return (
    <>
      <Spinner loading={loading}>
        <h5>Shipping Instruction</h5>
        <p className="">Porfavor verificar la información antes de enviar el shipping instruction</p>

        <div className="table">
          <table className="table table-sm table-bordered mb-0">
            <tbody>
            <tr>
              <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">HEADER</td>
            </tr>
            <tr>
              <td className="w-50">Document Identifier</td>
              <td className="w-50">{exportation?.exportationId.toString().padStart(10, '0')}</td>
            </tr>
            <tr>
              <td className="w-50">Document Version</td>
              <td className="w-50">{version.toString().padStart(6, '0')}</td>
            </tr>
            <tr>
              <td className="w-50">Date</td>
              <td className="w-50">{moment().format("yyyy-MM-DD HH:mm")}</td>
            </tr>
            <tr>
              <td className="w-50">Partner Role</td>
              <td className="w-50">Sender</td>
            </tr>
            <tr>
              <td className="w-50">Partner Name</td>
              <td className="w-50">Matus International, Inc.</td>
            </tr>
            <tr>
              <td className="w-50">Partner Identifier</td>
              <td className="w-50">MATUS</td>
            </tr>
            <tr>
              <td colSpan={2} className="fw-bold">Contact Information</td>
            </tr>
            <tr>
              <td className="w-50">Contact Name</td>
              <td className="w-50">{currentUser?.fullName}</td>
            </tr>
            <tr>
              <td className="w-50">Email</td>
              <td className="w-50">{currentUser?.email}</td>
            </tr>
            <tr>
              <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">CHARGES</td>
            </tr>
            <tr>
              <td className="w-50">Indicator</td>
              <td className="w-50">Prepaid</td>
            </tr>
            <tr>
              <td className="w-50">Charge Type</td>
              <td className="w-50">All Charges</td>
            </tr>
            <tr>
              <td colSpan={2} className="bg-dark bg-opacity-10 fw-bold">GENERAL</td>
            </tr>
            <tr>
              <td className="w-50">Booking Number</td>
              <td className="w-50">{exportation?.bookingNum ?? missingInformation()}</td>
            </tr>
            <tr>
              <td className="w-50">Bill of Lading Number</td>
              <td className="w-50">{exportation?.bookingNum ?? missingInformation()}</td>
            </tr>
            <tr>
              <td className="w-50">Freight Forwarder Reference</td>
              <td className="w-50">462329112</td>
            </tr>
            <tr>
              <td className="w-50">Exporters Reference Number</td>
              <td className="w-50">462329112</td>
            </tr>
            <tr>
              <td className="w-50">Movement Type</td>
              <td className="w-50">{exportation?.servic?.code ?? missingInformation()}</td>
            </tr>
            <tr>
              <td className="w-50">Service Type</td>
              <td className="w-50">FullLoad</td>
            </tr>
            </tbody>
          </table>
          {parties()}
          {routingInformation()}
          {equipmentDetails()}
          {goodsDetails()}
        </div>

        <Button type={'primary'}
                block={true}
                disabled={disabled}
                onClick={handleShippingInstruction}>Generar Shipping Instruction</Button>
      </Spinner>

      <PortCodeForm open={!!portCode}
                    portCode={portCode}
                    onSave={handleUpdatePortCode}
                    onCancel={() => setPortCode(undefined)} />

      <Modal open={!!subasta}
             title={'Modificar'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <SubastaForm subasta={subasta}
                     afterSave={handleUpdateSubasta}
                     onCancel={() => setSubasta(undefined)} />
      </Modal>

      <Modal open={!!importadora}
             title={'Modificar'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm importadora={importadora}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => setImportadora(undefined)} />
      </Modal>
    </>
  );
}

export default ExportationShippingInstruction;
