import React, {useEffect, useState} from "react";
import {showSubasta} from "../../Servicios/CombosService";
import Select from "react-select";

const InputSearchSubasta = ({ selectedSubasta, onSelectSubasta }) => {
  const pageCount = 100;

  const [searchName, setSearchName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [subastaList, setSubastaList] = useState([]);
  const [searchNameDebounce, setSearchNameDebounce] = useState("");

  useEffect(() => {
    loadSubastaList(currentPage, searchNameDebounce).then();
  }, [currentPage, searchNameDebounce, selectedSubasta]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchName !== null) {
        setSearchNameDebounce(searchName);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchName, 500]);

  const loadSubastaList = async (currentPage, searchName = "") => {
    setLoading(true);
    const data = await showSubasta(searchName, searchName !== "" ? 1 : currentPage, pageCount);
    setLoading(false);

    if (selectedSubasta) {
      const list = data.list;
      if (!subastaList.some(e => e.subId === selectedSubasta.subId)) {
        list.push(selectedSubasta);
      }

      setSubastaList(currentPage === 1 ? [...list] : [...subastaList, ...list]);
    } else {
      setSubastaList(currentPage === 1 ? [...data.list] : [...subastaList, ...data.list]);
    }
  };

  const handleSearchable = (event) => {
    event.preventDefault();
    return true;
  }

  const handleChange = (event) => {
    if (onSelectSubasta) {
      onSelectSubasta(event.subasta);
    }
  }

  return(
    <>
      <Select name="searchSubasta"
              isSearchable={handleSearchable}
              onChange={handleChange}
              isLoading={isLoading}
              defaultValue={{ label: "Escriba y Seleccione una opción", value: "empty" }}
              onInputChange={(inputValue) => {
                setCurrentPage(1);
                setSearchName(inputValue);
              }}
              value={!selectedSubasta ? undefined : {
                value: selectedSubasta.subId,
                label: `${selectedSubasta.subName}`,
                subasta: selectedSubasta
              }}
              options={subastaList && subastaList?.length
                ? subastaList?.map(subasta => ({
                  label: `${subasta.subName}`,
                  value: subasta.subId,
                  subasta: subasta
                }))
                : []
              }
      />
    </>
  );
}

export default InputSearchSubasta;
