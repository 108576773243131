
import axios from 'axios';

// export  const mediaListFolderId = async (folderId) => {
//     const response = await fetch(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/list/${'1uWUK-HJc5Ug5V09yKShfx7pX6ReYAcfY'}`,{
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
//     const data = await response.json()
//     return data;
// }

export const getMediaListFolderId = async (folderId) => {
    const response = await axios.get(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/list/folder/${folderId}`);
    return response;
}

export const getListFolderId = async (folderId) => {
    const response = await axios.get(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/list/folderName/${folderId}`);
    return response;
}