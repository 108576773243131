import React, { useEffect, useState } from "react";
import { Card } from "antd";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  ListSubheader,
} from "@mui/material";
import { topClientes } from "../../../../Servicios/Home";

import salvador from "../../../../image/pais/salvador.png";
import costarica from "../../../../image/pais/costarica.png";
import guatemala from "../../../../image/pais/guatemala.png";
import nicaragua from "../../../../image/pais/nicaragua.png";
import honduras from "../../../../image/pais/honduras.png";

const TopClientsByMonth = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [dataTopCLiente, setDataTopCLiente] = useState([]);

  const topClientesData = async () => {
    const data = await topClientes();
    setDataTopCLiente(data);
  };

  useEffect(() => {
    setLoading(true);
    if (user) topClientesData().catch((error) => {console.log("error", error);}).finally(() => {setLoading(false);});
  }, [user]);

  const returnPais = (idpais) => {
    if (idpais === "EL SALVADOR") {
      return salvador;
    } else if (idpais === "GUATEMALA") {
      return guatemala;
    } else if (idpais === "COSTA RICA") {
      return costarica;
    } else if (idpais === "NICARAGUA") {
      return nicaragua;
    } else if (idpais === "HONDURAS") {
      return honduras;
    }
  };

  return (
    <Card
      title={`Top de Clientes por Mes`}
      style={{ 
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        height: "100%",
      }}
      loading={loading}
    >
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          maxHeight: 515,
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {dataTopCLiente.map((sectionId) => (
          <li key={`section-${sectionId}`}>
            <ul>
              <ListSubheader>{<strong>{sectionId.name}</strong>}</ListSubheader>
              {sectionId?.data?.map((item, index) => (
                <ListItem
                  alignItems="flex-start"
                  key={`item-${sectionId}-${item}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="Cindy Baker"
                      src={returnPais(sectionId.name)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <strong>
                        {index + 1}. - {item.cliente}
                      </strong>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <strong>Aduana Frencuente:</strong>{" "}
                          {item?.detalle?.mostFrequentAduana}
                        </Typography>
                        {` - ${item?.detalle?.count} Solicitudes`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </ul>
          </li>
        ))}
      </List>
    </Card>
  );
};

export default TopClientsByMonth;
