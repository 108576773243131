import Button from "react-bootstrap/Button";
import {Space, Table} from "antd";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import React, {useEffect, useState} from "react";
import {getUsers} from "../../Servicios/AdminService";
import UsuarioForm from "./UsuarioForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const UsuarioPage = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [addNewUser, setAddNewUser] = useState(false);
  const [editUser, setEditUser] = useState(undefined);

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    const data = await getUsers();
    const users = data.map(e => { return { ...e, city: e.usr ? e.usr.city : '' } });

    setUsers([...users]);
    setFilteredUsers([...users]);
  }

  const columns = [
    { title: 'Username',    dataIndex: "username",  key: "username" },
    { title: 'First Name',  dataIndex: "firstName", key: "firstName" },
    { title: 'Last Name',   dataIndex: "lastName",  key: "lastName" },
    { title: 'Email',       dataIndex: "email",     key: "email" },
    { title: 'City',        dataIndex: "city",      key: "city" },
    {
      title: 'Roles',
      dataIndex: '',
      key: '',
      render: (_, element) => (<>
        {
          element.roles.map(rol => {
            return <p key={rol.id} className="m-0">{rol.rol.name}</p>
          })
        }
      </>)
    },
    {
      title: 'Access Countries',
      dataIndex: '',
      key: '',
      render: (_, element) => (<>
        {
          element.roles.map(rol => {
            return <p key={rol.id} className="m-0">{rol.countryName}</p>
          })
        }
      </>)
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
      render: (_, element) => (<>
        <Space key={element.idUser}>
          <Button variant={"info"}
                  size={'sm'}
                  onClick={() => setEditUser(element)}><FontAwesomeIcon icon={faPencil}/></Button>
          <Button variant={"primary"}
                  size={'sm'}
                  onClick={() => navigate(`/admin/users/${element.idUser}`)}><FontAwesomeIcon icon={faEye}/></Button>
        </Space>
      </>)
    }
  ];

  const handleSearch = (event) => {
    const data = users
      .filter(e => event === "" || `${e.username}-${e.firstName}-${e.lastName}`.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilteredUsers(data);
  }

  return(
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="m-0">Usuarios</h4>
        <Button variant={"primary"} onClick={() => setAddNewUser(true)}>
          <FontAwesomeIcon icon={faPlus} /> Nuevo Usuario
        </Button>
      </div>

      <div className="mb-3">
        <InputSearch placeholder={'buscar usuario'} doChange={handleSearch} />
      </div>

      <Table size={"middle"}
             columns={columns}
             dataSource={filteredUsers}
             pagination={{ pageSize: 10 }} />

      <UsuarioForm open={addNewUser || editUser}
                   user={editUser}
                   doCancel={() => {
                     setAddNewUser(false);
                     setEditUser(undefined);
                   }}
                   doSubmit={() => {
                     setAddNewUser(false);
                     setEditUser(undefined);
                     init().then();
                   }} />
    </>
  );
}

export default UsuarioPage;
