import {Button, Col, DatePicker, Modal, Row, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import moment from "moment";
import Spinner from "../../../shared/spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ExportationContainerForm from "../PlanifContenedores/ExportationContainerForm";
import dayjs from "dayjs";
import {getExportationContainer} from "../../../Servicios/ExportationContainerService";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import {ExportationCbpStatusEnum} from "../../../shared/enums/exportation-cbp-status.enum";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";

const AsignacionContenedor = ({ editable, origen, exportation, onUpdate, onSelectContainer, onDeleteContainer }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddExportationContainer, setShowModalAddExportationContainer] = useState(false);

  const [loadingDate, setLoadingDate] = useState(undefined);
  const [detalleExport, setDetalleExport] = useState(undefined);
  const [exportationContainerList, setExportationContainerList] = useState([]);
  const [selectedExportationContainerList, setSelectedExportationContainerList] = useState([]);

  useEffect(() => {
    if (exportation) {
      init(loadingDate).then();
    }
  }, [exportation, loadingDate, origen]);

  const init = async (loadingDate = undefined) => {
    setLoading(true);
    const data = await getExportationContainer(
      0,
      loadingDate ? loadingDate.getTime() : 0,
      0,
      0,
      exportationContainerStateEnum.creado,
      exportation.idAduana,
      exportation.idCarrier,
      origen,
    );
    setLoading(false);
    setExportationContainerList(data.list);
    setSelectedExportationContainerList([...exportation.exportationContainers]);
  }

  const template = item => {
    return(
      <>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fw-bold">Fecha de Carga: </p>
              </div>
              <div className="col-9">
                <p className="mb-0">{moment(item?.loadingDate).format('DD MMM yyyy')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fw-bold">Secuencia: </p>
              </div>
              <div className="col-9">
                <p className="mb-0">{item?.sequence}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fw-bold">Contenedor: </p>
              </div>
              <div className="col-9">
                <p className="mb-0">{item?.container?.nameContainer}</p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <p className="mb-0 fw-bold">Warehouses: </p>
            {
              item?.detalleExport?.map((e, index) => {
                if (e?.werehouse?.hasVehicle)
                  return <p className="mb-0" key={index}>{e?.werehouse?.codigo} - {e?.werehouse?.vehiculoName}</p>
                return <p className="mb-0" key={index}>{e?.werehouse?.codigo} - {e?.werehouse?.extraItems?.[0]?.extraItemName}(x{e?.werehouse?.extraItems?.[0]?.quantity})</p>
              })
            }
          </div>
        </div>
      </>
    );
  }

  const handleSelectContainer = async (event) => {
    const exportationContainer = exportationContainerList.find(e => e.idExportationContainer === event);
    if (onSelectContainer) onSelectContainer(exportationContainer);
  }

  const handleDeleteContainer = async (exportationContainer) => {
    if (onDeleteContainer) onDeleteContainer(exportationContainer);
  }

  const handleEditContainer = exportationContainer => {
    setShowModal(true);
    setDetalleExport(exportationContainer);
  }

  const columns = [
    {
      render: (_, element) => {
        return(
          <ShowWhen show={editable}>
            <div className="d-flex gap-2">
              <Button danger={true} onClick={() => handleDeleteContainer(element)}>
                <FontAwesomeIcon icon={faTrash}/>
              </Button>
              <Button type={'primary'} onClick={() => handleEditContainer(element)}>
                <FontAwesomeIcon icon={faPencil}/>
              </Button>
            </div>
          </ShowWhen>
        );
      }
    },
    {
      key: 'sequence',
      dataIndex: 'sequence',
      title: 'Secuencia',
      render: (_, element) => {
        return <>
          <p className="mb-0">{element?.sequence}</p>
        </>
      }
    },
    {
      key: 'container',
      title: 'Contenedor',
      render: (_, element) => {
        return <>
        <p className="mb-0">{element?.container?.nameContainer}</p>
        </>
      }
    },
    {
      key: 'werehouses',
      dataIndex: 'werehouses',
      title: 'Warehouses',
      render: (_, element) => {
        return <>
          {
            element?.detalleExport.map((e, index) => {
              return(
                <div className="border-bottom mb-2 pb-2" key={'table-' + index}>
                  <p className="mb-0 font-12">LOTE: {e?.werehouse?.lote}</p>
                  <ShowWhen show={e?.werehouse?.hasVehicle}>
                    <p className="mb-0 font-12">{e?.werehouse?.codigo} - {e?.werehouse?.vehiculoName}</p>
                  </ShowWhen>
                  <ShowWhen show={!e?.werehouse?.hasVehicle}>
                    <p className="mb-0 font-12">{e?.werehouse?.codigo} - {e?.werehouse?.extraItems?.[0]?.extraItemName}(x{e?.werehouse?.extraItems?.[0]?.quantity})</p>
                  </ShowWhen>
                </div>
              )
            })
          }
        </>
      }
    },
    {
      key: 'letter',
      dataIndex: 'letter',
      title: 'Letra',
    },
    {
      key: 'containerNumber',
      dataIndex: 'containerNumber',
      title: '# de Contenedor',
    },
    {
      key: 'sealNumber',
      dataIndex: 'sealNumber',
      title: '# de Sello',
    },
  ];

  const handleAddExportationContainer = () => {
    setShowModalAddExportationContainer(true);
  }

  return (
    <>
      <Spinner loading={loading}>
        <div className="card mb-3">
          <div className="card-body">
            <Row gutter={16} className="mb-3">
              <Col sm={5}>
                <p className="mb-0 fw-bold">Shipment Name</p>
                <p className="mb-0 ">{exportation?.shipmentName}</p>
              </Col>
              <Col sm={5}>
                <p className="mb-0 fw-bold">Booking #</p>
                <p className="mb-0 ">{exportation?.bookingNum}</p>
              </Col>
              <Col>
                <p className="mb-0 fw-bold">Bill of Lading #</p>
                <p className="mb-0 ">{exportation?.billLadingNum}</p>
              </Col>
            </Row>
            <Row gutter={16} className="mb-3">
              <Col sm={5}>
                <p className="mb-0 fw-bold">Destino</p>
                <p className="mb-0 ">{exportation?.aduanaName} {exportation?.aduana?.countryName}</p>
              </Col>
              <Col sm={5}>
                <p className="mb-0 fw-bold">Carrier</p>
                <p className="mb-0 ">{exportation?.carrier?.oceanCarrierName}</p>
              </Col>
            </Row>
            <Row gutter={16} className="mb-3">
              <Col sm={5}>
                <p className="mb-0 fw-bold">Departure Date</p>
                <p className="mb-0 ">{
                  exportation?.departureDate
                    ? moment(exportation?.departureDate).format('MM-DD-yyyy')
                    : '---'
                }</p>
              </Col>
              <Col sm={5}>
                <p className="mb-0 fw-bold">Arrival Date</p>
                <p className="mb-0 ">{
                  exportation?.arrivalDate
                    ? moment(exportation?.arrivalDate).format('MM-DD-yyyy')
                    : '---'
                }</p>
              </Col>
            </Row>
          </div>
        </div>
        <ShowWhen show={exportation?.state === ExportationStateEnum.Creado && exportation?.cbpStatus === ExportationCbpStatusEnum.Pendiente && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)}>
          <Button type={'primary'} className="mb-3" onClick={handleAddExportationContainer}>
            <FontAwesomeIcon className="me-2" size={'sm'} icon={faPlus}/> Agregar Planificación
          </Button>
        </ShowWhen>
        <div className="mb-3">
          <p className="mb-2">Filtrar por Fecha</p>
          <DatePicker className="w-100"
                      value={loadingDate ? dayjs(loadingDate) : ''}
                      onChange={event => {
                        setLoadingDate(event ? event.$d : undefined)
                      }} />
        </div>
        <div className="mb-3">
          <p className="mb-2">Seleccionar un contenedor</p>
          <Select className="w-100"
                  showSearch
                  disabled={!editable}
                  value={'Seleccionar un contenedor'}
                  placeholder={'Seleccionar un contenedor'}
                  onChange={handleSelectContainer}
                  filterOption={(input, option) =>
                    (option?.searchLabel ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={exportationContainerList.map(e => ({
                    label: template(e),
                    value: e.idExportationContainer,
                    searchLabel: `${e?.werehouse?.codigo}-${e?.werehouse?.lote}-${e.vehiculoName}-${e?.werehouse?.vin}`,
                  }))}/>
        </div>
        <Table dataSource={selectedExportationContainerList}
               columns={columns} />
      </Spinner>

      <Modal width={'90%'}
             open={showModal}
             title={'Modificar contenedor'}
             onCancel={() => setShowModal(false)}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ExportationContainerForm exportationContainer={detalleExport}
                                  onCancel={() => {
                                    setShowModal(false);
                                    setDetalleExport(undefined);
                                  }}
                                  onSubmit={() => {
                                    setShowModal(false);
                                    setDetalleExport(undefined);
                                    if (onUpdate) onUpdate();
                                  }}/>
      </Modal>

      <Modal width={'80%'}
             open={showModalAddExportationContainer}
             title={'Asignar contenedor'}
             closable={false}
             okButtonProps={{style: {display: 'none'}}}
             cancelButtonProps={{style: {display: 'none'}}}>
        <ExportationContainerForm idOrigen={exportation?.placeExId}
                                  idCarrier={exportation?.idCarrier}
                                  idAduana={exportation?.idAduana}
                                  editable={true}
                                  onCancel={() => {
                                    setShowModalAddExportationContainer(false);
                                  }}
                                  onSubmit={(exportationContainer) => {
                                    setShowModalAddExportationContainer(false);
                                    if (onSelectContainer) onSelectContainer(exportationContainer);
                                  }} />
      </Modal>
    </>
  );
}

export default AsignacionContenedor;
