import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {Button, Modal} from "antd";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";

const EliminarClienteBtn = ({ smSize, onCancelar }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCancelarSolicitud = () => {
    setOpenConfirmationModal(false);
    if (onCancelar) onCancelar();
  }

  return(
    <>
      <ShowWhen show={!smSize}>
        <Button onClick={() => setOpenConfirmationModal(true)} danger>
          <FontAwesomeIcon className="me-2" icon={faTimes} /> Eliminar Cliente
        </Button>
      </ShowWhen>
      <ShowWhen show={smSize}>
        <Button onClick={() => setOpenConfirmationModal(true)} danger>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ShowWhen>

      <Modal open={openConfirmationModal}
             title={'Confirmar'}
             onOk={handleCancelarSolicitud}
             onCancel={() => {
               setOpenConfirmationModal(false);
             }}>
        <p>Esta seguro que desea eliminar este cliente?</p>
      </Modal>
    </>
  );
}

export default EliminarClienteBtn;
