import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {useMainContext} from "../../contexts/MainContext";
import Spinner from "../../shared/spinner";

const OrigenTab = ({ selectedOrigen, onChange }) => {
  const [items, setItems] = useState([]);
  const { origenes, loadingMain } = useMainContext();

  useEffect(() => {
    setItems(origenes.map(e => ({ key: e.id, label: e.nameOrigen })));
  }, [origenes]);

  return(
    <Spinner loading={loadingMain}>
      <Tabs activeKey={selectedOrigen} items={items} onChange={onChange}></Tabs>
    </Spinner>
  );
}

export default OrigenTab;
