import React, { useEffect, useState } from 'react';
import '../hojas-de-estilo/style.css';
import CoordinacionTable from './DataTableCo/CoordinacionTable';
import { useParams } from "react-router-dom";
import { viewOrigen } from '../Servicios/ComponedoresService';
import { Row, Col } from 'antd';


const Coordinaciones = () => {

  const [origen, setOrigen] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    viewOrigen(id).then((origen) => {
      setOrigen(origen);
    })
  }, [id]);

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={24} className="d-flex align-items-center justify-content-between flex-wrap">
          <h5 className="mb-0">Coordinaciones - {origen ? origen.nameOrigen : ""}</h5>
        </Col>
      </Row>
      <hr className={"mb-0"} />
      <CoordinacionTable />
    </>
  );
}

export default Coordinaciones; 