const localStorageFilter = (item, key = undefined, isDate = false) => {
  if (!localStorage.getItem(item)) return undefined;
  if (!key) return JSON.parse(localStorage.getItem(item));
  if (!JSON.parse(localStorage.getItem(item))[key]) return undefined;

  if (isDate) {
    return new Date(JSON.parse(localStorage.getItem(item))[key]);
  }

  return JSON.parse(localStorage.getItem(item))[key];
}

export default localStorageFilter;
