import React, { useState, useEffect } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import { topSellers } from "../../../../Servicios/Home";
const TopSellersByMonth = ({ user }) => {
  const [options, setOptions] = useState({
    series: [
      {
        data: [],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: [],
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 350,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const topSellersData = async () => {
    const data = await topSellers();
    setOptions({
      series: [{ data: data?.series }],
      xaxis: { categories: data?.option },
    });
  };

  useEffect(() => {
    if (user) topSellersData();
  }, [user]);

  return (
    <Card
      title="Top de vendedores por mes"
      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
    >
      <div
        id="chart"
        style={{
          width: "100%",
        }}
      >
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          width="100%"
          height={700}
        />
      </div>
    </Card>
  );
};

export default TopSellersByMonth;
