import React, {useEffect, useState} from "react";
import {Col, DatePicker, Row, Select} from "antd";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import {showTransp} from "../../Servicios/CombosService";
import dayjs from "dayjs";

const PayTransportFilter = ({ coordinacionFilters, onFilter }) => {
  const [transporteList, setTransporteList] = useState([]);

  useEffect(() => {
    getTranspote().then();
  }, []);

  const getTranspote = async () => {
    const data = await showTransp();
    setTransporteList(data);
  };

  const handleChange = (key, value) => {
    const filter = {
      ...coordinacionFilters,
      [key]: value,
    };

    switch (key) {
      case 'day':
        if (value) {
          filter.month = value.getMonth() + 1;
          filter.year = value.getFullYear();
        } else {
          filter.month = 0;
          filter.year = 0;
        }
        break;
    }

    onFilter(filter);
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return(
    <>
      <Row gutter={12} className="mb-3 d-flex align-items-center w-100">
        <Col className="mt-3" xs={24} sm={24} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Fecha</Form.Label>
            {
              coordinacionFilters.day &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('day', undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={coordinacionFilters.day ? dayjs(coordinacionFilters.day) : ''}
                      onChange={(value) => handleChange('day', value.$d)} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={12} xl={6}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Transportista</Form.Label>
            {
              coordinacionFilters.transporteId !== 0 &&
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChange('transporteId', 0)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <Select className="w-100"
                  showSearch={true}
                  filterOption={filterOption}
                  value={coordinacionFilters.transporteId !== 0
                    ? transporteList
                      .map(e => ({value: e.transporteId, label: e.nomComp}))
                      .find(e => e.value === coordinacionFilters.transporteId)
                    : ''}
                  placeholder={'Seleccionar transportista'}
                  options={transporteList.map(transporte => {
                    return {value: transporte.transporteId, label: transporte.nomComp};
                  })}
                  onChange={(value) => handleChange('transporteId', value)}/>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={24}>
          <Form.Label className="input-buscar">Buscar Coordinación</Form.Label>
          <InputSearch
            initialValue={coordinacionFilters.search ?? ""}
            placeholder={"Buscar por lote, vehículo, nombre de importadora, buyer, subasta o aduana"}
            doChange={(evt) => {
              const filters = {...coordinacionFilters, search: evt};
              onFilter(filters);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default PayTransportFilter;
