import React, {useEffect, useState} from "react";
import {totalSolicitudesByWeek, totalSolicitudesByWeekBySellers} from "../Servicios/Home";
import Spinner from "../shared/spinner";
import moment from "moment/moment";
import {Avatar, Col, Modal, Row, Select, Table} from "antd";
import {Option} from "antd/es/mentions";
import salvador from "../image/pais/salvador.png";
import costarica from "../image/pais/costarica.png";
import guatemala from "../image/pais/guatemala.png";
import nicaragua from "../image/pais/nicaragua.png";
import honduras from "../image/pais/honduras.png";
import {SolicitudEstadoLabelEnum} from "../shared/enums/solicitud-estado.enum";
import {useParams} from "react-router-dom";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import OrigenTab from "../Componentes/OrigenTab/OrigenTab";

const returnPais = (idpais) => {
  if (idpais === "EL SALVADOR") {
    return salvador;
  } else if (idpais === "GUATEMALA") {
    return guatemala;
  } else if (idpais === "COSTA RICA") {
    return costarica;
  } else if (idpais === "NICARAGUA") {
    return nicaragua;
  } else if (idpais === "HONDURAS") {
    return honduras;
  } else if (idpais === "FLORIDA" || idpais === "CALIFORNIA") {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png";
  }
};

interface DataPerWeek {
  origen: string;
  total: number;
  paises: {
    pais: string;
    total: number;
    semana: {
      dia: string;
      fecha: string;
      total: number;
      estados: {
        estado: string;
        total: number;
      }[];
    }[]
  }[]
}

const SolicitudEstadoPorSemanaPage = () => {
  const { id } = useParams();
  const [tab, setTab] = useState(1);
  const [week, setWeek] = useState(0);
  const [rows, setRows] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sellerRows, setSellerRows] = useState([]);
  const [sellerColumns, setSellerColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSellersModal, setShowSellersModal] = useState(false);

  useEffect(() => {
    const currentWeek = id ?? moment().week();
    init(currentWeek, tab).then();

    const weeks = [];
    for (let i = 1; i <= moment().week(); i++) {
      weeks.push(i);
    }

    setWeek(currentWeek);
    setWeeks(weeks);
  }, [id]);

  const init = async (week, tab) => {
    setLoading(true);
    const data = await totalSolicitudesByWeek(week);
    setLoading(false);

    initColumns(data[tab - 1]);
  }

  const handleShowSellers = async (origen, countryId, week) => {
    setLoading(true);
    const data = await totalSolicitudesByWeekBySellers(origen, countryId, week);
    setLoading(false);

    setShowSellersModal(true);
    initSellersColumns(data);
  }

  const initColumns = (data: DataPerWeek) => {
    const columns = [{
      key: 'pais',
      dataIndex: 'pais',
      fixed: 'left',
      title: 'País',
      width: '200px',
      render: (value, element) => (
        <div className="d-flex align-items-center justify-content-start">
          <Avatar alt={value} src={returnPais(value)}/>
          <div>
            <p className="mb-0 ms-2">{value}</p>
            <ShowWhen show={value !== "FLORIDA" && value !== "CALIFORNIA"}>
              <p className="mb-0 ms-2 text-primary cursor-pointer"
                 onClick={() => handleShowSellers(element.origen, element.paisId, element.semanaId)}>Ver Empleados</p>
            </ShowWhen>
          </div>
        </div>
      )
    }];

    data.paises[0].semana.forEach(semana => {
      columns.push({
        key: semana.dia,
        dataIndex: semana.dia,
        width: '230px',
        title: (
          <div>
            <p className="mb-0">{semana.dia}</p>
            <p className="mb-0">{moment(semana.fecha).format('MM/DD/yyyy')}</p>
          </div>
        ),
        render: (_, item) => {
          if (item.paises)
            return origenCell(item, semana.dia);
          return paisCell(item, semana.dia);
        }
      })
    });

    setColumns(columns);

    // set rows
    const rows = [];
    rows.push({
      ...data,
      pais: data.origen
    });

    // set origenes
    data.paises.forEach((pais) => {
      rows.push(pais);
    });

    setRows(rows);
  }

  const initSellersColumns = (data: { vendedor: string, total: number, semana: { dia: string, fecha: string, total: number }[] }[]) => {
    const columns = [{
      key: 'vendedor',
      dataIndex: 'vendedor',
      fixed: 'left',
      title: 'Vendedor',
      width: '200px',
      render: (value) => (
        <div className="d-flex align-items-center justify-content-start">
          {value}
        </div>
      )
    }];

    data[0].semana.forEach(semana => {
      columns.push({
        key: semana.dia,
        dataIndex: semana.dia,
        title: (
          <div>
            <p className="mb-0">{semana.dia}</p>
            <p className="mb-0">{moment(semana.fecha).format('MM/DD/yyyy')}</p>
          </div>
        ),
        sorter: (a, b) => a[semana.dia] - b[semana.dia],
        width: '160px',
      })
    });

    setSellerColumns(columns);

    // set rows
    const rows = [];

    // set origenes
    data.forEach((item) => {
      const days = {};
      item.semana.forEach(semana => {
        days[semana.dia] = semana.total;
      });

      rows.push({
        ...item,
        ...days,
      });
    });

    setSellerRows(rows);
  }

  const origenCell = (item, dia) => {
    const total = item.paises.reduce((total, pais) => {
      const semana = pais.semana.find(e => e.dia === dia);
      total += semana.total;

      return total;
    }, 0);

    return (
      <div className="d-flex align-items-center justify-content-between">
        <p className="mb-0 fw-bold">Total:</p>
        <p className="mb-0 fw-bold">{total}</p>
      </div>
    );
  }

  const paisCell = (item, dia) => {
    const data = item.semana.find(e => e.dia === dia);

    return (
      <div>
        {
          data.estados.map((estado, index) => {
            return (
              <div key={`${dia}-${estado}-${index}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0">{SolicitudEstadoLabelEnum[estado.estado]}:</p>
                  <p className="mb-0">{estado.total}</p>
                </div>
                <hr className="my-1"/>
              </div>
            );
          })
        }
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">Total:</p>
          <p className="mb-0 fw-bold">{data.total}</p>
        </div>
      </div>
    );
  }

  const handleWeekChange = async (week) => {
    setWeek(week);
    await init(week, tab);
  };

  const handleChangeTab = async (tab) => {
    setTab(tab);
    await init(week, tab);
  }

  return (
    <Spinner loading={loading}>
      <h5>Detalle de Solicitudes</h5>
      <hr/>
      <Row>
        <Col sm={24}>
          <Select
            value={week}
            className="mb-3"
            onChange={handleWeekChange}
            style={{ width: "100%" }}
          >
            {weeks.map((week) => (
              <Option key={week} value={week}>
                Semana {week}
              </Option>
            ))}
          </Select>
        </Col>
        <Col sm={24}>
          <OrigenTab selectedOrigen={tab} onChange={handleChangeTab}/>
          <Table dataSource={rows}
                 columns={columns}
                 scroll={{ x: 1300 }} />
        </Col>
      </Row>
      <Modal open={showSellersModal}
             title={'Vendedores'}
             width={'100%'}
             onCancel={() => setShowSellersModal(false)}
             okButtonProps={{style: {display: 'none'}}}
             cancelButtonProps={{style: {display: 'none'}}}>
        <Table dataSource={sellerRows}
               columns={sellerColumns}
               size={'small'}
               scroll={{ x: 1300, y: 800 }}
               summary={(pageData) => {
                 return (
                   <>
                     <Table.Summary.Row>
                       <Table.Summary.Cell index={0}><span className="fw-bold">Total</span></Table.Summary.Cell>
                       <Table.Summary.Cell index={1}>{pageData.reduce((a, b) => {a += b['lunes'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={2}>{pageData.reduce((a, b) => {a += b['martes'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={3}>{pageData.reduce((a, b) => {a += b['miércoles'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={4}>{pageData.reduce((a, b) => {a += b['jueves'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={5}>{pageData.reduce((a, b) => {a += b['viernes'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={6}>{pageData.reduce((a, b) => {a += b['sábado'];return a;}, 0)}</Table.Summary.Cell>
                       <Table.Summary.Cell index={7}>{pageData.reduce((a, b) => {a += b['domingo'];return a;}, 0)}</Table.Summary.Cell>
                     </Table.Summary.Row>
                   </>
                 );
               }}/>
      </Modal>
    </Spinner>
  );
}

export default SolicitudEstadoPorSemanaPage;
