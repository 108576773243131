import React, {useEffect, useState} from "react";
import {totalSolicitudesByWeek} from "../../../../Servicios/Home";
import Spinner from "../../../../shared/spinner";
import moment from "moment/moment";
import {Avatar, Button, Card, Col, Row, Select, Table} from "antd";
import {Option} from "antd/es/mentions";
import salvador from "../../../../image/pais/salvador.png";
import costarica from "../../../../image/pais/costarica.png";
import guatemala from "../../../../image/pais/guatemala.png";
import nicaragua from "../../../../image/pais/nicaragua.png";
import honduras from "../../../../image/pais/honduras.png";
import {SolicitudEstadoLabelEnum} from "../../../../shared/enums/solicitud-estado.enum";
import {useNavigate} from "react-router-dom";

const returnPais = (idpais) => {
  if (idpais === "EL SALVADOR") {
    return salvador;
  } else if (idpais === "GUATEMALA") {
    return guatemala;
  } else if (idpais === "COSTA RICA") {
    return costarica;
  } else if (idpais === "NICARAGUA") {
    return nicaragua;
  } else if (idpais === "HONDURAS") {
    return honduras;
  } else if (idpais === "FLORIDA" || idpais === "CALIFORNIA" || idpais === "PHILADELPHIA") {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png";
  }
};

interface DataPerWeek {
  origen: string;
  total: number;
  paises: {
    pais: string;
    total: number;
    semana: {
      dia: string;
      fecha: string;
      total: number;
      estados: {
        estado: string;
        total: number;
      }[];
    }[]
  }[]
}

const SolicitudPerWeek = () => {
  const navigate = useNavigate();

  const [week, setWeek] = useState(0);
  const [rows, setRows] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentWeek = moment().week();
    init(currentWeek).then();

    const weeks = [];
    for (let i = 1; i <= currentWeek; i++) {
      weeks.push(i);
    }

    setWeek(currentWeek);
    setWeeks(weeks);
  }, []);

  const init = async (week) => {
    setLoading(true);
    const data = await totalSolicitudesByWeek(week);
    setLoading(false);

    initColumns(data);
  }

  const initColumns = (data: DataPerWeek[]) => {
    const columns = [{
      key: 'pais',
      dataIndex: 'pais',
      fixed: 'left',
      title: 'País',
      width: '200px',
      render: (value) => (
        <div>
          <Avatar alt={value} src={returnPais(value)} />
          <span style={{ marginLeft: "10px" }}>{value}</span>
        </div>
      )
    }];

    data[0].paises[0].semana.forEach(semana => {
      columns.push({
        key: semana.dia,
        dataIndex: semana.dia,
        width: '230px',
        title: (
          <div>
            <p className="mb-0">{semana.dia}</p>
            <p className="mb-0">{moment(semana.fecha).format('MM/DD/yyyy')}</p>
          </div>
        ),
        render: (_, item) => {
          return origenCell(item, semana.dia);
        }
      });
    });

    setColumns(columns);

    // set rows
    const rows = [];
    data.forEach(item => {
      rows.push({
        ...item,
        pais: item.origen
      });
    });

    setRows(rows);
  }

  const origenCell = (item, dia) => {
    const total = item.paises.reduce((total, pais) => {
      const semana = pais.semana.find(e => e.dia === dia);
      total += semana.total;

      return total;
    }, 0);

    return(
      <div>
        {
          Object.keys(SolicitudEstadoLabelEnum).map(key => {
            const total = item.paises.reduce((total, pais) => {
              const semana = pais.semana.find(e => e.dia === dia);
              total += semana.estados.find(e => e.estado === key).total;

              return total;
            }, 0);

            return (
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0">{SolicitudEstadoLabelEnum[key]}:</p>
                <p className="mb-0">{total}</p>
              </div>
            );
          })
        }
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">Total:</p>
          <p className="mb-0 fw-bold">{total}</p>
        </div>
      </div>
    );
  }

  const handleWeekChange = async (week) => {
    setWeek(week);
    await init(week);
  };

  return (
    <Card style={{
            height: '100%',
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between">
          <p className="fw-bolder mb-0">Detalle de solicitudes</p>
          <Button type={'link'} onClick={() => navigate(`/solicitud/estado-por-semana/${week}`)}>Ver mas</Button>
        </div>
        <hr/>
        <Row>
          <Col sm={24}>
            <Select
              value={week}
              className="mb-3"
              onChange={handleWeekChange}
              style={{ width: "100%" }}
            >
              {weeks.map((week) => (
                <Option key={week} value={week}>
                  Semana {week}
                </Option>
              ))}
            </Select>
          </Col>
          <Col sm={24}>
            <Table sticky
                   dataSource={rows}
                   columns={columns}
                   scroll={{ x: 1300 }} />
          </Col>
        </Row>
      </Spinner>
    </Card>
  );
}

export default SolicitudPerWeek;
