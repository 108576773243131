import React, {useEffect, useState} from "react";
import {Input} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const InputSearch = ({ initialValue, placeholder, doChange }) => {
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterText != null)
        doChange(filterText);
    }, 500);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, 500]);

  const handleChange = (event) => {
    const value = event.target.value;

    setFilterText(value);
  }

  return (
    <div className="input-buscar-container">
      <Input
        className="input-buscar"
        id="search"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        value={filterText ?? initialValue}
        onChange={handleChange}
      />
      <div className="input-buscar-icon" onClick={() => handleChange({target: {value: ""}})}>
        <FontAwesomeIcon icon={faTimes} className="cursor-pointer"/>
      </div>
    </div>
  );
}

export default InputSearch;
