import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {showQuote} from "../Servicios/SolicitudesService";
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../hojas-de-estilo/style.css';
import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Link, useParams} from 'react-router-dom';
import {viewOrigen} from "../Servicios/ComponedoresService";
import moment from 'moment';
import SpinnerTable from "../Componentes/SpinnerTable";
import {Tabs} from "antd";
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import InputSearch from "../Componentes/InputSearch/InputSearch";

const QuoteCoor = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [quote, setQuote] = useState([]);

  const {id} = useParams();
  const [origen, setOrigen] = useState([]);

  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [estado, setEstado] = useState(SolicitudEstadoEnum.SinAsignar);

  const showSolicitudes = async (currentPage, pageCount, search, estado = "") => {
    setIsLoading(true);
    const data = await showQuote(0, 0, 0, currentPage, pageCount, search, estado, "", +id);
    setIsLoading(false);

    if (data.status) {
      setQuote(data.list);
      setTotalElements(data.totalElements);
    }
  }

  useEffect(() => {
    viewOrigen(id).then((origen) => {
      setOrigen(origen);
    });

    showSolicitudes(currentPage, pageCount, filterText, estado).then();
  }, [id]);

  const columns = [
    {
      name: 'Ingresar',
      width: "90px",
      cell: (row) => estado === SolicitudEstadoEnum.SinAsignar
        ? <Button as={Link} to={`/addCoord/${row.quoteId}`} variant="warning"><FontAwesomeIcon icon={faFileCirclePlus}/></Button>
        : <></>
    },
    {
      name: 'Fecha',
      selector: row => row.fechaQ,
      format: (row) => moment(row.fechaQ).format('l'),
      width: "93px",
    },
    {
      name: 'Lote',
      selector: row => row.lote,
      width: "100px",
    },
    {
      name: 'Buyer',
      selector: row => row.buyer,
      width: "85px",
    },
    {
      name: 'Vehiculo',
      selector: row => row.vehiculoName,
      width: "220px",
    },
    {
      name: 'Ubicación',
      selector: row => row.subName,
      width: "260px",
    },
    {
      name: 'Importadora',
      selector: row => row.import,
      width: "200px",
    },
    {
      name: 'Destino',
      selector: row => row.aduanaName,
      width: "150px",
    },
    {
      name: 'Asesor',
      selector: row => row.creador,
      width: "150px",
    },
  ]

  const [filterText, setFilterText] = useState('');

  const handleFilterText = (value) => {
    setFilterText(value);
    showSolicitudes(currentPage, pageCount, value, estado).then();
  }

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    showSolicitudes(newPage, pageCount, filterText, estado).then();
  };

  const handlePerRowsChange = (newPerPage) => {
    setPageCount(newPerPage);
    setCurrentPage(1);

    showSolicitudes(1, newPerPage, filterText, estado).then();
  };

  const handleTabChange = (key) => {
    const newEstado = key === '2' ? SolicitudEstadoEnum.EnRevision : SolicitudEstadoEnum.SinAsignar;
    setCurrentPage(1);
    setEstado(newEstado);

    showSolicitudes(1, pageCount, filterText, newEstado).then();
  }

  const items = [
    {
      key: '1',
      label: 'Solicitudes',
      children: (<>
        <DataTable
          columns={columns}
          data={quote}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable/>}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '2',
      label: 'Solicitudes en Revisión',
      children: (<>
        <DataTable
          columns={columns}
          data={quote}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable/>}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    }
  ];

  return (
    <Container>
      <h4>Seleccione una Solicitud para generar la Nueva Coordinación - {origen.nameOrigen} </h4>
      <Row className="mt-3">
        <Col xs={12} md={12}>
          <InputSearch placeholder={'Buscar Solicitud por Lote / Marca / Modelo / Importadora'}
                       doChange={handleFilterText}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange}/>
        </Col>
      </Row>
    </Container>
  );
}

export default QuoteCoor;
