import React from 'react';
import { Avatar as AntAvatar } from 'antd';

const InitialsAvatar = ({ firstName, lastName }) => {
  const initials = `${ firstName ? firstName?.charAt(0).toUpperCase() : ''}${ lastName ? lastName?.charAt(0).toUpperCase() : ''}`;

  return (
    <AntAvatar
      style={{ backgroundColor: '#001529', verticalAlign: 'middle' }}
      size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
    >
      {initials}
    </AntAvatar>
  );
};

export default InitialsAvatar;
