import React, { useState, useEffect } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import "moment/locale/es";

import { totalCoordinacionPerOrigenByMonthRequest } from "../../../../Servicios/Home";

const monthActuality = moment().format("MMMM");

const TotalOriginCurrentMonthRequest = () => {
  const [totales, setTotales] = useState(null); 
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex];
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });

  useEffect(() => {
    totalCoordinacionPerOrigenByMonthRequest().then((data) => {
      const series = data.map((item) => Number(item.total));
      const allZero = series.every((val) => val === 0);

      setTotales(data);
      setState({
        series: allZero ? [1] : series, 
        options: {
          chart: {
            type: "donut",
          },
          labels: allZero ? ["Sin datos"] : data.map((item) => item.label),

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return allZero
                ? "Sin datos"
                : opts.w.globals.series[opts.seriesIndex];
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return allZero ? "Sin datos" : val;
              },
            },
          },
        },
      });
    });
  }, []);

  return (
    <Card
      title={`Total de Solicitudes por Origen - ${monthActuality.toUpperCase()}`}
      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
    >
      <div>
        <div
          id="chart"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", // Ajustar el contenedor del gráfico para que sea responsivo
            height: 515, // Ajustar el contenedor del gráfico para que sea responsivo
          }}
        >
          {state.series.every((val) => val === 1) ? ( // Si hay solo el valor mínimo ficticio
            <div style={{ textAlign: "center", padding: "20px" }}>
              No hay datos disponibles para este mes.
            </div>
          ) : (
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="donut"
              height={545} // Altura del gráfico
              width={450} // Ancho del gráfico
            />
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {totales
            ? totales.map((item) => (
                <div key={item.label}>
                  Entregas en Bodega {item.label}: {item.totalEntregasEnBodega}
                </div>
              ))
            : null}
        </div>
        <div id="html-dist"></div>
      </div>
    </Card>
  );
};

export default TotalOriginCurrentMonthRequest;
