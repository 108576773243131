import {Button} from "antd";

const SwitchTabButton = ({ tabs, selected, onSelect }) => {
  return(
    <div className="w-100 d-flex gap-2 mb-3">
      {
        tabs.map((tab, index) => {
          return <Button key={index}
                         type={tab.key === selected ? 'primary' : 'default'}
                         block={true}
                         style={{borderRadius: '25px'}}
                         onClick={() => onSelect(tab.key)}>
            { tab.label }
          </Button>
        })
      }
    </div>
  );
}

export default SwitchTabButton;
