import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../shared/spinner";
import {Button, Form, Input} from "antd";
import TextArea from "antd/lib/input/TextArea";

const ConsignatarioFinalForm = ({ consigneeName, consigneeAddress, consigneeIdentification, onCancel, afterSubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      consigneeName: consigneeName ?? "",
      consigneeAddress: consigneeAddress ?? "",
      consigneeIdentification: consigneeIdentification ?? "",
    });
  }, [consigneeName, consigneeAddress, consigneeIdentification]);

  const handleSubmit = (values) => {
    afterSubmit(values);
  }

  return(
    <Spinner loading={false}>
      <Form form={form}
            layout={'vertical'}
            onFinish={handleSubmit}>
        <Form.Item name="consigneeName"
                   rules={[
                       { required: true, message: 'El nombre es un campo requerido'}
                   ]}
                   label={'Nombre del Consignatario final'}>
          <Input placeholder={`Nombre del Consignatario final`} />
        </Form.Item>

        <Form.Item name="consigneeIdentification"
                   label={'# NIT'}
                   rules={[
                     { required: true, message: 'El # NIT es un campo requerido'}
                   ]}>
          <Input placeholder="# NIT"/>
        </Form.Item>

        <Form.Item name={'consigneeAddress'}
                   rules={[
                     { required: true, message: 'La Dirección es un campo requerido'},
                   ]}
                   label={'Dirección'}>
          <TextArea rows={2} placeholder="Dirección" />
        </Form.Item>

        <div className="text-end">
          <Button type="default" className="me-3" htmlType={'button'} onClick={onCancel}>Cancelar</Button>
          <Button type="primary" htmlType={'submit'}><FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>
            Guardar
          </Button>
        </div>
      </Form>
    </Spinner>
  );
}

export default ConsignatarioFinalForm;
