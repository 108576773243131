const SERVER_DEV = 'http://localhost/webapisimi/api/'
const SERVER_PROD = 'https://portalmatus.com/webapisimi/api/'

const environment = {
  production: true,
  api: SERVER_PROD,
  emails: {
    grua: 'gruas@matusintl.com',
    //grua: 'manuelpc2495@gmail.com',
    seabordMarine: 'loi@seaboardmarine.com',
    //seabordMarine: 'carlos@matusintl.com',
    honduras: 'honduras@matusintl.com',
    //honduras: 'manuelpc2495@gmail.com',
    correosEmbarqueHonduras: ['vehiculos@riverafiallos.com', 'opsrfc@riverafiallos.com', 'ops.sap@riverafiallos.com', 'ops.sap2@riverafiallos.com', 'ops6@riverafiallos.com'],
    //correosEmbarqueHonduras: ['manuelpc2495@gmail.com'],
  }
}

export default environment;

export const server = environment.api;
// export const server = 'http://localhost:5047/api/';

/**
 * example of production environment
 */

// const environment = {
//   production: true,
//   server: SERVER_PROD,
//   emails: {
//     grua: 'gruas@matusintl.com'
//   }
// }
