export const SolicitudEstadoEnum = {
  SinAsignar: "SinAsignar",
  Asignado: "Asignado",
  EnRevision: "EnRevision",
  PendienteCompletar: "PendienteCompletar",
  Cancelado: "Cancelado",
  EntregasEnBodega: "EntregasEnBodega"
}

export const SolicitudEstadoLabelEnum = {
  [SolicitudEstadoEnum.SinAsignar]: 'No Coordinadas',
  [SolicitudEstadoEnum.Asignado]: 'Coordinadas',
  [SolicitudEstadoEnum.EnRevision]: 'En Revisión',
  [SolicitudEstadoEnum.PendienteCompletar]: 'Pendiente de Completar',
  [SolicitudEstadoEnum.Cancelado]: 'Cancelado',
  [SolicitudEstadoEnum.EntregasEnBodega]: 'Entregado En Bodega',
}
